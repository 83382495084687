import { createSlice } from '@reduxjs/toolkit';

export enum RIGHT_SIDEBAR_TYPE {
  CLOSE = 'CLOSE',
  ADD_REGION_GROUP = 'ADD_REGION_GROUP',
  EDIT_REGION_GROUP = 'EDIT_REGION_GROUP',
  ADD_REGION = 'ADD_REGION',
  EDIT_REGION = 'EDIT_REGION',
  ADD_PROPERTY_TAG = 'ADD_PROPERTY_TAG',
  EDIT_PROPERTY_TAG = 'EDIT_PROPERTY_TAG',
  ADD_PROPERTY = 'ADD_PROPERTY',
  EDIT_PROPERTY = 'EDIT_PROPERTY',
  FILTER = 'FILTER',
  ADD_DEPARTMENT = 'ADD_DEPARTMENT',
  EDIT_DEPARTMENT = 'EDIT_DEPARTMENT',
  ADD_STAFF_TAG = 'ADD_STAFF_TAG',
  EDIT_STAFF_TAG = 'EDIT_STAFF_TAG',
  PROPERTY_ITEM_TYPE = 'PROPERTY_ITEM_TYPE',
  ADD_PROPERTY_ITEM_TYPE = 'ADD_PROPERTY_ITEM_TYPE',
  EDIT_PROPERTY_ITEM_TYPE = 'EDIT_PROPERTY_ITEM_TYPE',
  ADD_PROPERTY_STATUS = 'ADD_PROPERTY_STATUS',
  EDIT_PROPERTY_STATUS = 'EDIT_PROPERTY_STATUS',
  PROPERTY_UTILITY_TYPE = 'PROPERTY_UTILITY_TYPE',
  ADD_PROPERTY_UTILITY_TYPE = 'ADD_PROPERTY_UTILITY_TYPE',
  EDIT_PROPERTY_UTILITY_TYPE = 'EDIT_PROPERTY_UTILITY_TYPE',
  SHOW_PROPERTIES_MAP = 'SHOW_PROPERTIES_MAP',
  SHOW_PROPERTIES_REQUEST_FORM = 'SHOW_PROPERTIES_REQUEST_FORM',
  ARRIVAL_FILTER = 'ARRIVAL_FILTER',
  ON_PROPERTY_STATUS = 'ON_PROPERTY_STATUS',
  DEPARTURE_FILTER = 'DEPARTURE_FILTER',
}

export interface RightSidebarState {
  open: boolean;
  type: RIGHT_SIDEBAR_TYPE;
  options: Record<string, unknown>;
}

const initialState: RightSidebarState = {
  open: false,
  type: RIGHT_SIDEBAR_TYPE.CLOSE,
  options: {},
};

const rightSidebarSlice = createSlice({
  name: 'rightsidebar/toggle',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.open = action.payload.open;
      state.type = action.payload.type;
      state.options = action.payload.options;
    },
    updateSideBarOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

const { reducer } = rightSidebarSlice;
export const { toggleSidebar, updateSideBarOptions } = rightSidebarSlice.actions;
export default reducer;
