/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck
type apiState = {
  memberPermission: object;
};

export default function memberPermissionAPIReducer(state: apiState = {}, action) {
  const { type, payload } = action;
  if (
    type.startsWith('SUCCESS_READ_MEMBER_PERMISSIONS') ||
    type === 'SUCCESS_CREATE_SYNC_PERMISSION'
  ) {
    state.memberPermission = payload;
    return {
      ...state,
    };
  }
  return state;
}
