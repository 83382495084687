import { useSelector } from 'react-redux';
import { getTimeZones, TimeZoneState } from '../redux/slices/TimeZoneSlice';
import { AppState, useAppDispatch } from '../redux/Store';

export const useTimeZones = () => {
  const dispatch = useAppDispatch();
  const timeZoneState: TimeZoneState = useSelector((state: AppState) => state.timeZoneReducer);

  const getAllTimeZones = () => {
    dispatch(getTimeZones());
  };

  return {
    getAllTimeZones,
    timeZoneState,
  };
};
