import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { translateError } from 'utils/ErrorManagement';

import {
  MasterCalendarApiActivateBookingRequest,
  MasterCalendarApiDeactivateBookingRequest,
  MasterCalendarApiGetBookingsRequest,
  MasterCalendarBookingDto,
  MasterCalendarBookingExtendedPropsDto,
} from '@/teams-openapi';
import { BookingService } from 'services/BookingService';

export interface BookingState {
  fetchAllStatus: API_STATUS;
  activateStatus: API_STATUS;
  deactivateStatus: API_STATUS;
  result: MasterCalendarBookingDto[];
}

const initialState: BookingState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
  activateStatus: API_STATUS.IDLE,
  deactivateStatus: API_STATUS.IDLE,
};

export const getBookings = createAsyncThunk(
  'master-calendar-booking',
  async (filters: MasterCalendarApiGetBookingsRequest, { rejectWithValue }) => {
    try {
      const dataResult = await BookingService.Instance.getBookings(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const activateBookingById = createAsyncThunk(
  'master-calendar-booking/activate',
  async (filters: MasterCalendarApiActivateBookingRequest, { rejectWithValue }) => {
    try {
      const dataResult = await BookingService.Instance.activateBooking(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deactivateBookingById = createAsyncThunk(
  'master-calendar-booking/deactivate',
  async (filters: MasterCalendarApiDeactivateBookingRequest, { rejectWithValue }) => {
    try {
      const dataResult = await BookingService.Instance.deactivateBooking(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<BookingState>) => {
  // --------------- GET BOOKINGS ---------------
  builder.addCase(getBookings.pending, (state: BookingState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getBookings.fulfilled, (state: BookingState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(getBookings.rejected, (state: BookingState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });

  // --------------- ACTIVATE A BOOKING -----------------

  builder.addCase(activateBookingById.pending, (state: BookingState) => {
    state.activateStatus = API_STATUS.PENDING;
  });
  builder.addCase(activateBookingById.fulfilled, (state: BookingState, action) => {
    state.activateStatus = API_STATUS.SUCCESS;
    state.result.map(booking => {
      if (booking.id === action.payload.result.id) {
        const bookingExtendedProps: MasterCalendarBookingExtendedPropsDto | undefined =
          booking.extendedProps;
        if (bookingExtendedProps != undefined) bookingExtendedProps.active = true;
      }
    });
  });
  builder.addCase(activateBookingById.rejected, (state: BookingState) => {
    state.activateStatus = API_STATUS.FAILURE;
  });

  // --------------- DEACTIVATE A BOOKING ---------------
  builder.addCase(deactivateBookingById.pending, (state: BookingState) => {
    state.deactivateStatus = API_STATUS.PENDING;
  });
  builder.addCase(deactivateBookingById.fulfilled, (state: BookingState, action) => {
    state.deactivateStatus = API_STATUS.SUCCESS;
    state.result = state.result.map(booking => {
      if (booking.id === action.payload.result.id) {
        const bookingExtendedProps: MasterCalendarBookingExtendedPropsDto | undefined =
          booking.extendedProps;
        if (bookingExtendedProps != undefined) bookingExtendedProps.active = false;
        booking.extendedProps = bookingExtendedProps;
      }
      return booking;
    });
  });
  builder.addCase(deactivateBookingById.rejected, (state: BookingState) => {
    state.deactivateStatus = API_STATUS.FAILURE;
  });
};

const bookingSlice = createSlice({
  name: 'bookingSlice',
  initialState,
  reducers: {
    clearBookings: state => {
      state.result = [];
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = bookingSlice;
export const { clearBookings } = bookingSlice.actions;
export default reducer;
