/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck

type apiState = {
  testimonials?: object;
};

export default function testimonialAPIReducer(state: apiState = {}, action) {
  const { type, payload } = action;
  if (type === 'SUCCESS_READ_TESTIMONIAL') {
    if (payload.skip > 0) {
      payload.rows = state.testimonials.testimonial.rows.concat(payload.rows);
    }
    const testimonial = payload;
    state.testimonials = {
      testimonial: testimonial,
    };
    return {
      ...state,
    };
  }
  if (type === 'SUCCESS_CREATE_NEW_TESTIMONIAL') {
    return {
      ...state,
      testimonials: {
        ...state.testimonials,
        testimonial: {
          ...state.testimonials.testimonial,
          totalRecords: parseInt(state.testimonials.testimonial.totalRecords, 10) + 1,
        },
      },
    };
  }
  if (type === 'SUCCESS_UPDATE_EDIT_TESTIMONIAL') {
    const test = { ...state.testimonials };
    const oldTestimonials = { ...test.testimonial };
    const localTestimonials = [...oldTestimonials.rows];
    const updatedRowValue = localTestimonials.map(singleTestimonals => {
      if (singleTestimonals.testimonialId === payload.testimonialId) {
        return payload;
      }
      return singleTestimonals;
    });
    return {
      ...state,
      testimonials: {
        testimonial: {
          ...oldTestimonials,
          rows: [...updatedRowValue],
        },
      },
    };
  }

  if (type === 'SUCCESS_CREATE_EXPORT_TESTIMONIAL') {
    const parts = payload.split('/');
    fetch(payload, { method: 'get' })
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = parts[parts.length - 1];
        link.click();
      })
      .catch();
  }
  return state;
}
