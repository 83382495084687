import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { MasterCalendarService } from 'services/MasterCalendarService';
import { MasterCalendarApiGetSettingsRequest, MasterCalendarSettingsDto } from 'teams-openapi';
import { translateError } from 'utils/ErrorManagement';

export interface MasterCalendarSettingsState {
  fetchAllStatus: API_STATUS;
  result: MasterCalendarSettingsDto;
}

const initialState: MasterCalendarSettingsState = {
  result: {},
  fetchAllStatus: API_STATUS.IDLE,
};

export const getSettings = createAsyncThunk(
  'master-calendar-settings',
  async (args: MasterCalendarApiGetSettingsRequest, { rejectWithValue }) => {
    try {
      const dataResult = await MasterCalendarService.Instance.getSettings(args);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<MasterCalendarSettingsState>) => {
  builder.addCase(getSettings.pending, (state: MasterCalendarSettingsState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getSettings.fulfilled, (state: MasterCalendarSettingsState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result || {};
  });
  builder.addCase(getSettings.rejected, (state: MasterCalendarSettingsState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = {};
  });
};

const masterCalendarSettingsSlice = createSlice({
  name: 'masterCalendarSettingsSlice',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = masterCalendarSettingsSlice;
export default reducer;
