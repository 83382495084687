import { ArrivalDto } from '@/teams-openapi';
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ArrivalReportService } from 'services/reports/ArrivalReportService';
import { API_STATUS } from '../../constants/Constants';
import { translateError } from '../../utils/ErrorManagement';
import { ArrivalFilters } from './ArrivalFilterSlice';

export interface ArrivalReportState {
  result: ArrivalDto[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
}

const initialState: ArrivalReportState = {
  result: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
};

export const runReport = createAsyncThunk(
  'reports/arrival',
  async (filters: ArrivalFilters, { rejectWithValue }) => {
    try {
      const dataResult = await ArrivalReportService.Instance.runArrivalReport(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<ArrivalReportState>) => {
  // --------------- GET REGIONS ---------------
  builder.addCase(runReport.pending, (state: ArrivalReportState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(runReport.fulfilled, (state: ArrivalReportState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(runReport.rejected, (state: ArrivalReportState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });
};

const arrivalReportSlice = createSlice({
  name: 'arrivalReportSlice',
  initialState,
  reducers: {
    setDone: () => {
      // TODO: implement me
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = arrivalReportSlice;
// export const { setDone } = arrivalReportSlice.actions;
export default reducer;
