/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { QueryResultArrivalDto } from '../models';
/**
 * ArrivalApi - axios parameter creator
 * @export
 */
export const ArrivalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Arrival Report
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrival: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/arrival`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Arrival Report Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportCsv: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/arrival/exportCSV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Arrival Report Html Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportHtml: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/arrival/exportHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Arrival Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportPdf: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/arrival/exportPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArrivalApi - functional programming interface
 * @export
 */
export const ArrivalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArrivalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Arrival Report
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arrival(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultArrivalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arrival(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArrivalApi.arrival']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Arrival Report Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arrivalExportCsv(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arrivalExportCsv(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArrivalApi.arrivalExportCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Arrival Report Html Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arrivalExportHtml(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arrivalExportHtml(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArrivalApi.arrivalExportHtml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Arrival Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async arrivalExportPdf(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.arrivalExportPdf(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArrivalApi.arrivalExportPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ArrivalApi - factory interface
 * @export
 */
export const ArrivalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArrivalApiFp(configuration)
    return {
        /**
         * 
         * @summary Arrival Report
         * @param {ArrivalApiArrivalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrival(requestParameters: ArrivalApiArrivalRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultArrivalDto> {
            return localVarFp.arrival(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Arrival Report Export
         * @param {ArrivalApiArrivalExportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportCsv(requestParameters: ArrivalApiArrivalExportCsvRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.arrivalExportCsv(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Arrival Report Html Export
         * @param {ArrivalApiArrivalExportHtmlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportHtml(requestParameters: ArrivalApiArrivalExportHtmlRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.arrivalExportHtml(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Arrival Report PDF Export
         * @param {ArrivalApiArrivalExportPdfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        arrivalExportPdf(requestParameters: ArrivalApiArrivalExportPdfRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.arrivalExportPdf(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for arrival operation in ArrivalApi.
 * @export
 * @interface ArrivalApiArrivalRequest
 */
export interface ArrivalApiArrivalRequest {
    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrival
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrival
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrival
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrival
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrival
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrival
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ArrivalApiArrival
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrival
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrival
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrival
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrivalApiArrival
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for arrivalExportCsv operation in ArrivalApi.
 * @export
 * @interface ArrivalApiArrivalExportCsvRequest
 */
export interface ArrivalApiArrivalExportCsvRequest {
    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrivalApiArrivalExportCsv
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for arrivalExportHtml operation in ArrivalApi.
 * @export
 * @interface ArrivalApiArrivalExportHtmlRequest
 */
export interface ArrivalApiArrivalExportHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrivalApiArrivalExportHtml
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for arrivalExportPdf operation in ArrivalApi.
 * @export
 * @interface ArrivalApiArrivalExportPdfRequest
 */
export interface ArrivalApiArrivalExportPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrivalApiArrivalExportPdf
     */
    readonly sort?: Array<string>
}

/**
 * ArrivalApi - object-oriented interface
 * @export
 * @class ArrivalApi
 * @extends {BaseAPI}
 */
export class ArrivalApi extends BaseAPI {
    /**
     * 
     * @summary Arrival Report
     * @param {ArrivalApiArrivalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrivalApi
     */
    public arrival(requestParameters: ArrivalApiArrivalRequest = {}, options?: AxiosRequestConfig) {
        return ArrivalApiFp(this.configuration).arrival(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Arrival Report Export
     * @param {ArrivalApiArrivalExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrivalApi
     */
    public arrivalExportCsv(requestParameters: ArrivalApiArrivalExportCsvRequest = {}, options?: AxiosRequestConfig) {
        return ArrivalApiFp(this.configuration).arrivalExportCsv(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Arrival Report Html Export
     * @param {ArrivalApiArrivalExportHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrivalApi
     */
    public arrivalExportHtml(requestParameters: ArrivalApiArrivalExportHtmlRequest = {}, options?: AxiosRequestConfig) {
        return ArrivalApiFp(this.configuration).arrivalExportHtml(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Arrival Report PDF Export
     * @param {ArrivalApiArrivalExportPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrivalApi
     */
    public arrivalExportPdf(requestParameters: ArrivalApiArrivalExportPdfRequest = {}, options?: AxiosRequestConfig) {
        return ArrivalApiFp(this.configuration).arrivalExportPdf(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

