/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AssignRegionGroupToRegionCommandDto } from '../models';
// @ts-ignore
import { AssignTimeZoneToRegionCommandDto } from '../models';
// @ts-ignore
import { CommandResult } from '../models';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateRegionCommandDto } from '../models';
// @ts-ignore
import { MoveRegionCommandDto } from '../models';
// @ts-ignore
import { RegionDto } from '../models';
// @ts-ignore
import { RegionQueryResult } from '../models';
// @ts-ignore
import { SetRegionColorCommandDto } from '../models';
// @ts-ignore
import { UpdateRegionCommandDto } from '../models';
// @ts-ignore
import { UpdateRegionLabelCommandDto } from '../models';
/**
 * RegionApi - axios parameter creator
 * @export
 */
export const RegionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send an Assign Region Group to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AssignRegionGroupToRegionCommandDto} assignRegionGroupToRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRegionGroupToRegion: async (id: number, ifMatch: number, assignRegionGroupToRegionCommandDto: AssignRegionGroupToRegionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignRegionGroupToRegion', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('assignRegionGroupToRegion', 'ifMatch', ifMatch)
            // verify required parameter 'assignRegionGroupToRegionCommandDto' is not null or undefined
            assertParamExists('assignRegionGroupToRegion', 'assignRegionGroupToRegionCommandDto', assignRegionGroupToRegionCommandDto)
            const localVarPath = `/v1/region/{id}/assign-region-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignRegionGroupToRegionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AssignTimeZoneToRegionCommandDto} assignTimeZoneToRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTimeZoneToRegion: async (id: number, ifMatch: number, assignTimeZoneToRegionCommandDto: AssignTimeZoneToRegionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignTimeZoneToRegion', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('assignTimeZoneToRegion', 'ifMatch', ifMatch)
            // verify required parameter 'assignTimeZoneToRegionCommandDto' is not null or undefined
            assertParamExists('assignTimeZoneToRegion', 'assignTimeZoneToRegionCommandDto', assignTimeZoneToRegionCommandDto)
            const localVarPath = `/v1/region/{id}/assign-time-zone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignTimeZoneToRegionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Create Region Command
         * @param {CreateRegionCommandDto} createRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegion: async (createRegionCommandDto: CreateRegionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRegionCommandDto' is not null or undefined
            assertParamExists('createRegion', 'createRegionCommandDto', createRegionCommandDto)
            const localVarPath = `/v1/region`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionGroup1: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRegionGroup1', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteRegionGroup1', 'ifMatch', ifMatch)
            const localVarPath = `/v1/region/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Regions
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [regionGroupId] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRegions: async (customerId?: number, labelStartsWith?: string, regionGroupId?: number, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/region`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (regionGroupId !== undefined) {
                localVarQueryParameter['regionGroupId'] = regionGroupId;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Region by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegion', 'id', id)
            const localVarPath = `/v1/region/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Move Region Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MoveRegionCommandDto} moveRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveRegion: async (id: number, ifMatch: number, moveRegionCommandDto: MoveRegionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveRegion', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('moveRegion', 'ifMatch', ifMatch)
            // verify required parameter 'moveRegionCommandDto' is not null or undefined
            assertParamExists('moveRegion', 'moveRegionCommandDto', moveRegionCommandDto)
            const localVarPath = `/v1/region/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveRegionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {SetRegionColorCommandDto} setRegionColorCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRegionColor: async (id: number, ifMatch: number, setRegionColorCommandDto: SetRegionColorCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setRegionColor', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('setRegionColor', 'ifMatch', ifMatch)
            // verify required parameter 'setRegionColorCommandDto' is not null or undefined
            assertParamExists('setRegionColor', 'setRegionColorCommandDto', setRegionColorCommandDto)
            const localVarPath = `/v1/region/{id}/set-color`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setRegionColorCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionCommandDto} updateRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegion: async (id: number, ifMatch: number, updateRegionCommandDto: UpdateRegionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegion', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateRegion', 'ifMatch', ifMatch)
            // verify required parameter 'updateRegionCommandDto' is not null or undefined
            assertParamExists('updateRegion', 'updateRegionCommandDto', updateRegionCommandDto)
            const localVarPath = `/v1/region/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Region Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionLabelCommandDto} updateRegionLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionLabel: async (id: number, ifMatch: number, updateRegionLabelCommandDto: UpdateRegionLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegionLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateRegionLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updateRegionLabelCommandDto' is not null or undefined
            assertParamExists('updateRegionLabel', 'updateRegionLabelCommandDto', updateRegionLabelCommandDto)
            const localVarPath = `/v1/region/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegionLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionApi - functional programming interface
 * @export
 */
export const RegionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send an Assign Region Group to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AssignRegionGroupToRegionCommandDto} assignRegionGroupToRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRegionGroupToRegion(id: number, ifMatch: number, assignRegionGroupToRegionCommandDto: AssignRegionGroupToRegionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRegionGroupToRegion(id, ifMatch, assignRegionGroupToRegionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.assignRegionGroupToRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AssignTimeZoneToRegionCommandDto} assignTimeZoneToRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignTimeZoneToRegion(id: number, ifMatch: number, assignTimeZoneToRegionCommandDto: AssignTimeZoneToRegionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignTimeZoneToRegion(id, ifMatch, assignTimeZoneToRegionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.assignTimeZoneToRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Create Region Command
         * @param {CreateRegionCommandDto} createRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegion(createRegionCommandDto: CreateRegionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegion(createRegionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.createRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegionGroup1(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegionGroup1(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.deleteRegionGroup1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Regions
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [regionGroupId] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRegions(customerId?: number, labelStartsWith?: string, regionGroupId?: number, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRegions(customerId, labelStartsWith, regionGroupId, regionGroupIds, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.findRegions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Region by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegion(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegion(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.getRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Move Region Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MoveRegionCommandDto} moveRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveRegion(id: number, ifMatch: number, moveRegionCommandDto: MoveRegionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveRegion(id, ifMatch, moveRegionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.moveRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {SetRegionColorCommandDto} setRegionColorCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRegionColor(id: number, ifMatch: number, setRegionColorCommandDto: SetRegionColorCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRegionColor(id, ifMatch, setRegionColorCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.setRegionColor']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Region Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionCommandDto} updateRegionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegion(id: number, ifMatch: number, updateRegionCommandDto: UpdateRegionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegion(id, ifMatch, updateRegionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.updateRegion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Region Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionLabelCommandDto} updateRegionLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegionLabel(id: number, ifMatch: number, updateRegionLabelCommandDto: UpdateRegionLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegionLabel(id, ifMatch, updateRegionLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionApi.updateRegionLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RegionApi - factory interface
 * @export
 */
export const RegionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionApiFp(configuration)
    return {
        /**
         * 
         * @summary Send an Assign Region Group to Region Command
         * @param {RegionApiAssignRegionGroupToRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRegionGroupToRegion(requestParameters: RegionApiAssignRegionGroupToRegionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.assignRegionGroupToRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.assignRegionGroupToRegionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {RegionApiAssignTimeZoneToRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTimeZoneToRegion(requestParameters: RegionApiAssignTimeZoneToRegionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.assignTimeZoneToRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.assignTimeZoneToRegionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Create Region Command
         * @param {RegionApiCreateRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegion(requestParameters: RegionApiCreateRegionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResult> {
            return localVarFp.createRegion(requestParameters.createRegionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Region Command
         * @param {RegionApiDeleteRegionGroup1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionGroup1(requestParameters: RegionApiDeleteRegionGroup1Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRegionGroup1(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Regions
         * @param {RegionApiFindRegionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRegions(requestParameters: RegionApiFindRegionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RegionQueryResult> {
            return localVarFp.findRegions(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.regionGroupId, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Region by ID
         * @param {RegionApiGetRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion(requestParameters: RegionApiGetRegionRequest, options?: AxiosRequestConfig): AxiosPromise<RegionDto> {
            return localVarFp.getRegion(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Move Region Sort Order Command
         * @param {RegionApiMoveRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveRegion(requestParameters: RegionApiMoveRegionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.moveRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.moveRegionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Assign Time Zone to Region Command
         * @param {RegionApiSetRegionColorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRegionColor(requestParameters: RegionApiSetRegionColorRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.setRegionColor(requestParameters.id, requestParameters.ifMatch, requestParameters.setRegionColorCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Region Command
         * @param {RegionApiUpdateRegionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegion(requestParameters: RegionApiUpdateRegionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResult> {
            return localVarFp.updateRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Region Label Command
         * @param {RegionApiUpdateRegionLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionLabel(requestParameters: RegionApiUpdateRegionLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateRegionLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignRegionGroupToRegion operation in RegionApi.
 * @export
 * @interface RegionApiAssignRegionGroupToRegionRequest
 */
export interface RegionApiAssignRegionGroupToRegionRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiAssignRegionGroupToRegion
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiAssignRegionGroupToRegion
     */
    readonly ifMatch: number

    /**
     * 
     * @type {AssignRegionGroupToRegionCommandDto}
     * @memberof RegionApiAssignRegionGroupToRegion
     */
    readonly assignRegionGroupToRegionCommandDto: AssignRegionGroupToRegionCommandDto
}

/**
 * Request parameters for assignTimeZoneToRegion operation in RegionApi.
 * @export
 * @interface RegionApiAssignTimeZoneToRegionRequest
 */
export interface RegionApiAssignTimeZoneToRegionRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiAssignTimeZoneToRegion
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiAssignTimeZoneToRegion
     */
    readonly ifMatch: number

    /**
     * 
     * @type {AssignTimeZoneToRegionCommandDto}
     * @memberof RegionApiAssignTimeZoneToRegion
     */
    readonly assignTimeZoneToRegionCommandDto: AssignTimeZoneToRegionCommandDto
}

/**
 * Request parameters for createRegion operation in RegionApi.
 * @export
 * @interface RegionApiCreateRegionRequest
 */
export interface RegionApiCreateRegionRequest {
    /**
     * 
     * @type {CreateRegionCommandDto}
     * @memberof RegionApiCreateRegion
     */
    readonly createRegionCommandDto: CreateRegionCommandDto
}

/**
 * Request parameters for deleteRegionGroup1 operation in RegionApi.
 * @export
 * @interface RegionApiDeleteRegionGroup1Request
 */
export interface RegionApiDeleteRegionGroup1Request {
    /**
     * 
     * @type {number}
     * @memberof RegionApiDeleteRegionGroup1
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiDeleteRegionGroup1
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findRegions operation in RegionApi.
 * @export
 * @interface RegionApiFindRegionsRequest
 */
export interface RegionApiFindRegionsRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiFindRegions
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof RegionApiFindRegions
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof RegionApiFindRegions
     */
    readonly regionGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof RegionApiFindRegions
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof RegionApiFindRegions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiFindRegions
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof RegionApiFindRegions
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getRegion operation in RegionApi.
 * @export
 * @interface RegionApiGetRegionRequest
 */
export interface RegionApiGetRegionRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiGetRegion
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiGetRegion
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for moveRegion operation in RegionApi.
 * @export
 * @interface RegionApiMoveRegionRequest
 */
export interface RegionApiMoveRegionRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiMoveRegion
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiMoveRegion
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MoveRegionCommandDto}
     * @memberof RegionApiMoveRegion
     */
    readonly moveRegionCommandDto: MoveRegionCommandDto
}

/**
 * Request parameters for setRegionColor operation in RegionApi.
 * @export
 * @interface RegionApiSetRegionColorRequest
 */
export interface RegionApiSetRegionColorRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiSetRegionColor
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiSetRegionColor
     */
    readonly ifMatch: number

    /**
     * 
     * @type {SetRegionColorCommandDto}
     * @memberof RegionApiSetRegionColor
     */
    readonly setRegionColorCommandDto: SetRegionColorCommandDto
}

/**
 * Request parameters for updateRegion operation in RegionApi.
 * @export
 * @interface RegionApiUpdateRegionRequest
 */
export interface RegionApiUpdateRegionRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiUpdateRegion
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiUpdateRegion
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateRegionCommandDto}
     * @memberof RegionApiUpdateRegion
     */
    readonly updateRegionCommandDto: UpdateRegionCommandDto
}

/**
 * Request parameters for updateRegionLabel operation in RegionApi.
 * @export
 * @interface RegionApiUpdateRegionLabelRequest
 */
export interface RegionApiUpdateRegionLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiUpdateRegionLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionApiUpdateRegionLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateRegionLabelCommandDto}
     * @memberof RegionApiUpdateRegionLabel
     */
    readonly updateRegionLabelCommandDto: UpdateRegionLabelCommandDto
}

/**
 * RegionApi - object-oriented interface
 * @export
 * @class RegionApi
 * @extends {BaseAPI}
 */
export class RegionApi extends BaseAPI {
    /**
     * 
     * @summary Send an Assign Region Group to Region Command
     * @param {RegionApiAssignRegionGroupToRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public assignRegionGroupToRegion(requestParameters: RegionApiAssignRegionGroupToRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).assignRegionGroupToRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.assignRegionGroupToRegionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Assign Time Zone to Region Command
     * @param {RegionApiAssignTimeZoneToRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public assignTimeZoneToRegion(requestParameters: RegionApiAssignTimeZoneToRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).assignTimeZoneToRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.assignTimeZoneToRegionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Create Region Command
     * @param {RegionApiCreateRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public createRegion(requestParameters: RegionApiCreateRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).createRegion(requestParameters.createRegionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Region Command
     * @param {RegionApiDeleteRegionGroup1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public deleteRegionGroup1(requestParameters: RegionApiDeleteRegionGroup1Request, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).deleteRegionGroup1(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Regions
     * @param {RegionApiFindRegionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public findRegions(requestParameters: RegionApiFindRegionsRequest = {}, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).findRegions(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.regionGroupId, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Region by ID
     * @param {RegionApiGetRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public getRegion(requestParameters: RegionApiGetRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).getRegion(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Move Region Sort Order Command
     * @param {RegionApiMoveRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public moveRegion(requestParameters: RegionApiMoveRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).moveRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.moveRegionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Assign Time Zone to Region Command
     * @param {RegionApiSetRegionColorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public setRegionColor(requestParameters: RegionApiSetRegionColorRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).setRegionColor(requestParameters.id, requestParameters.ifMatch, requestParameters.setRegionColorCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Region Command
     * @param {RegionApiUpdateRegionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public updateRegion(requestParameters: RegionApiUpdateRegionRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).updateRegion(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Region Label Command
     * @param {RegionApiUpdateRegionLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public updateRegionLabel(requestParameters: RegionApiUpdateRegionLabelRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).updateRegionLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

