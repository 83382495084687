import axios from 'axios';
import { addMonths, format } from 'date-fns';
import { TeamsConst } from '../../constants/Base';
import { ArrivalFilters } from '../../redux/slices/ArrivalFilterSlice';
import {
  ArrivalApi,
  ArrivalApiArrivalRequest,
  ArrivalApiAxiosParamCreator,
} from '../../teams-openapi/apis/arrival-api';
import { FORMAT_YYYY_MM__DD } from '../../utils/DateUtils';
import { BaseService } from '../BaseService';

export class ArrivalReportService {
  private _baseInstance: BaseService;
  private static _instance: ArrivalReportService;
  private api: ArrivalApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new ArrivalApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public runArrivalReport(filters: ArrivalFilters) {
    const req = this.getArrivalRequest(filters);
    return this.api.arrival(req);
  }

  public async runArrivalReportCsv(filters: ArrivalFilters) {
    const req = this.getArrivalRequest(filters);
    const promise = this.api.arrivalExportCsv(req);
    return promise;
  }

  public async runArrivalReportPdf(filters: ArrivalFilters) {
    const requestParameters = this.getArrivalRequest(filters);
    const requestArgs = await ArrivalApiAxiosParamCreator(
      this._baseInstance.Configuration,
    ).arrivalExportPdf(
      requestParameters.customerId,
      requestParameters.fromDate,
      requestParameters.toDate,
      requestParameters.regionGroupIds,
      requestParameters.regionIds,
      requestParameters.unitIds,
      requestParameters.taskDuringStay,
      requestParameters.keyword,
      requestParameters.skip,
      requestParameters.take,
      requestParameters.sort,
      { responseType: 'blob', headers: { 'Content-Type': 'application/pdf' } },
    );
    const promise = axios.get(TeamsConst.apiBaseUrl + requestArgs.url, {
      responseType: 'blob',
      headers: {
        authorization: `Bearer ${this._baseInstance.JwtToken}`,
        'Content-Type': 'application/pdf',
      },
    });
    return promise;
  }

  private getArrivalRequest(filters: ArrivalFilters) {
    const fromDateAsString =
      filters.dateRange && filters.dateRange[0] !== null
        ? format(new Date(filters.dateRange[0]), FORMAT_YYYY_MM__DD)
        : format(new Date(), FORMAT_YYYY_MM__DD);

    const toDateAsString =
      filters.dateRange && filters.dateRange[1] !== null
        ? format(new Date(filters.dateRange[1]), FORMAT_YYYY_MM__DD)
        : format(addMonths(new Date(), 1), FORMAT_YYYY_MM__DD);

    const regionGroupIds = filters.regionGroupRefs.map(rgr => rgr.id);
    const regionIds = filters.regionRefs.map(rg => rg.id);
    const unitIds = filters.propertyRefs.map(prop => prop.id);
    const taskDuringStay = filters.taskDuringStay;
    const keyword = filters.keyword;

    const req: ArrivalApiArrivalRequest = {
      customerId: this._baseInstance.CustomerId,
      fromDate: fromDateAsString,
      toDate: toDateAsString,
      regionGroupIds: regionGroupIds,
      regionIds: regionIds,
      unitIds: unitIds,
      taskDuringStay: taskDuringStay,
      keyword: keyword,
    };
    return req;
  }
}
