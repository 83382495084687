// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { RegionService } from '../../services/RegionService';
import { translateError } from '../../utils/ErrorManagement';

export interface RegionState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState: RegionState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getRegions = createAsyncThunk('regions/get', async (_: void, { rejectWithValue }) => {
  try {
    const res = await RegionService.Instance.getAll();
    return res.data;
  } catch (err) {
    return rejectWithValue(translateError(err));
  }
});

export const getRegionRefs = createAsyncThunk(
  'regions/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await RegionService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getRegionRefsFor = createAsyncThunk(
  'regions/getRefs',
  async (regionGroupIds: number[], { rejectWithValue }) => {
    try {
      const res = await RegionService.Instance.getAllForRegionGroups(regionGroupIds);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegionColor = createAsyncThunk(
  'regions/updateColor',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version, color } = region;
      const res = await RegionService.Instance.updateColor(id, version, color);
      const latest = await RegionService.Instance.getById(id, res.data.version);
      return { ...res.data, color: latest.data.color };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegionLabel = createAsyncThunk(
  'regions/updateLabel',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version, label } = region;
      const res = await RegionService.Instance.updateLabel(id, version, label);
      const latest = await RegionService.Instance.getById(id, res.data['version']);
      return { ...res.data, label: latest.data.label, version: latest.data.version };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const assignRegionRegionGroup = createAsyncThunk(
  'regions/assignRegionGroup',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version, regionGroupId } = region;
      const res = await RegionService.Instance.assignRegionGroup(id, version, regionGroupId);
      const latest = await RegionService.Instance.getById(id, res.data.version);
      return { ...res.data, regionGroup: latest.data.regionGroup, version: latest.data.version };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const assignRegionTimeZone = createAsyncThunk(
  'regions/assignTimeZone',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version, timeZoneId } = region;
      const res = await RegionService.Instance.assignTimezone(id, version, timeZoneId);
      const latest = await RegionService.Instance.getById(id, res.data.version);
      return { ...res.data, timeZone: latest.data.timeZone, version: latest.data.version };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createRegion = createAsyncThunk(
  'regions/create',
  async (region: any, { rejectWithValue }) => {
    try {
      const { color, label, regionGroupId, timeZoneId } = region;
      const res = await RegionService.Instance.create(color, label, regionGroupId, timeZoneId);
      const latest = await RegionService.Instance.getById(res.data['id'], res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegion = createAsyncThunk(
  'regions/update',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, color, label, regionGroup, timeZone, version } = region;
      const res = await RegionService.Instance.update(
        id,
        version,
        color,
        label,
        regionGroup,
        timeZone,
      );
      const latest = await RegionService.Instance.getById(id, res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteRegion = createAsyncThunk(
  'regions/delete',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version } = region;
      const res = await RegionService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegionSortOrder = createAsyncThunk(
  'regions/updateSortOrder',
  async (region: any, { rejectWithValue }) => {
    try {
      const { id, version, sortOrder } = region;
      const updateRes = await RegionService.Instance.updateSortOrder(id, version, sortOrder);
      const latestRes = await RegionService.Instance.getById(id, updateRes.data.version);
      const getAllRes = await RegionService.Instance.getAll();
      return {
        updateData: updateRes.data,
        sortOrder: latestRes.data.sortOrder,
        newVersion: latestRes.data.version,
        getAllData: getAllRes.data,
      };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<RegionState>) => {
  // --------------- GET REGIONS ---------------
  builder.addCase(getRegions.pending, (state: RegionState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getRegions.fulfilled, (state: RegionState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = data;
  });
  builder.addCase(getRegions.rejected, (state: RegionState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- GET REGION REFS ---------------
  builder.addCase(getRegionRefs.pending, (state: RegionState, action) => {
    state.fetchRefsStatus = API_STATUS.PENDING;
  });
  builder.addCase(getRegionRefs.fulfilled, (state: RegionState, action) => {
    state.fetchRefsStatus = API_STATUS.SUCCESS;
    const data = action.payload.data
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(ref => ({ id: ref.id, label: ref.label, color: ref.color }));
    state.refs = data;
  });
  builder.addCase(getRegionRefs.rejected, (state: RegionState, action) => {
    state.fetchRefsStatus = API_STATUS.FAILURE;
    state.refs = [];
  });

  // --------------- UPDATE ---------------
  builder.addCase(updateRegion.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegion.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { id, label, color, regionGroup, timeZone, version: newVersion } = action.payload.data;
    state.all = state.all
      .map(item => {
        const newItem = {
          ...item,
          label: label,
          color: color,
          regionGroup: regionGroup,
          timeZone: timeZone,
          version: newVersion,
        };
        return item.id === id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);

    state.currentItem = {
      ...state.currentItem,
      color: color,
      label: label,
      regionGroup: regionGroup,
      timeZone: timeZone,
      version: newVersion,
    };
  });
  builder.addCase(updateRegion.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- UPDATE COLOR ---------------
  builder.addCase(updateRegionColor.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionColor.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { id, color, version: newVersion } = action.payload;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, color: color, version: newVersion };
        return item.id === id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, color: color, version: newVersion };
  });
  builder.addCase(updateRegionColor.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updateRegionLabel.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionLabel.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label, version: newVersion } = action.payload;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.meta.arg.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updateRegionLabel.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- MOVE (SORT ORDER UPDATE) ---------------
  builder.addCase(updateRegionSortOrder.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionSortOrder.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { updateData, sortOrder, newVersion, getAllData } = action.payload;
    const sortedAll = getAllData.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = sortedAll;
    state.currentItem = { ...state.currentItem, sortOrder: sortOrder, version: newVersion };
  });
  builder.addCase(updateRegionSortOrder.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- ASSIGN REGION ---------------
  builder.addCase(assignRegionRegionGroup.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(assignRegionRegionGroup.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { regionGroup, version: newVersion } = action.payload;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, regionGroup: regionGroup, version: newVersion };
        return item.id === action.meta.arg.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, regionGroup: regionGroup, version: newVersion };
  });
  builder.addCase(assignRegionRegionGroup.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- ASSIGN TIMEZONE ---------------
  builder.addCase(assignRegionTimeZone.pending, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(assignRegionTimeZone.fulfilled, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { timeZone, version: newVersion } = action.payload;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, timeZone: timeZone, version: newVersion };
        return item.id === action.meta.arg.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, timeZone: timeZone, version: newVersion };
  });
  builder.addCase(assignRegionTimeZone.rejected, (state: RegionState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- CREATE ---------------
  builder.addCase(createRegion.pending, (state: RegionState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createRegion.fulfilled, (state: RegionState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(createRegion.rejected, (state: RegionState, action) => {
    state.createStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- DELETE ---------------
  builder.addCase(deleteRegion.pending, (state: RegionState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deleteRegion.fulfilled, (state: RegionState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    state.all = state.all
      .filter(item => {
        return item.id !== action.meta.arg.id;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(deleteRegion.rejected, (state: RegionState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
    state.all = [];
  });
};

const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setRegionToEdit: (state, action) => {
      state.currentItem = action.payload;
    },
    updateLocalRegionSort: (state, action) => {
      state.all = action.payload;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = regionSlice;
export const { resetCreateStatus, setRegionToEdit, updateLocalRegionSort } = regionSlice.actions;
export default reducer;
