import { Box, Skeleton, SxProps } from '@mui/material';
import { paletteStyles } from '@operto/ui';

const BASE_HEIGHT = 30;
const CIRCULAR_WIDTH = 30;
const ROUNDED_WIDTH = 30;
const SMALL_LOGO_SIZE = 40;

export const HeadingSkeleton = () => {
  return (
    <Box sx={headingSx}>
      <Box sx={contentSx}>
        <Box>
          <Skeleton
            sx={headingElementSx}
            variant='rounded'
            width={SMALL_LOGO_SIZE}
            height={SMALL_LOGO_SIZE}
          />
        </Box>
        <Box>
          <Skeleton sx={headingElementSx} variant='text' width={200} />
        </Box>
      </Box>
      <Box sx={contentSx}>
        <Skeleton
          sx={headingElementSx}
          variant='rounded'
          width={ROUNDED_WIDTH}
          height={BASE_HEIGHT}
        />
        <Skeleton
          sx={headingElementSx}
          variant='rounded'
          width={ROUNDED_WIDTH}
          height={BASE_HEIGHT}
        />
        <Skeleton
          sx={headingElementSx}
          variant='circular'
          width={CIRCULAR_WIDTH}
          height={BASE_HEIGHT}
        />
        <Skeleton
          sx={headingElementSx}
          variant='circular'
          width={CIRCULAR_WIDTH}
          height={BASE_HEIGHT}
        />
        <Skeleton
          sx={headingElementSx}
          variant='circular'
          width={CIRCULAR_WIDTH}
          height={BASE_HEIGHT}
        />
        <Skeleton
          sx={headingElementSx}
          variant='circular'
          width={CIRCULAR_WIDTH}
          height={BASE_HEIGHT}
        />
      </Box>
    </Box>
  );
};

const contentSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

const headingSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 20px',
  width: '100%',
  borderBottom: `1px solid ${paletteStyles.palette.border}`,
};

const headingElementSx: SxProps = {
  margin: '0px 5px',
};
