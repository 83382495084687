/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck

type apiState = {
  preferenceFilter: object;
  loading: false;
};

export default function preferenceFilterAPIReducer(state: apiState = {}, action) {
  const { type, params } = action;

  if (type.startsWith('SET_FILTER')) {
    state.preferenceFilter = params.body.filter;
    // localStorage.setItem("filter", JSON.stringify(params.body.filter));
    return {
      ...state,
      loading: false,
    };
  }
  return state;
}
