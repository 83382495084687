// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { CheckInOutReportService } from '../../services/reports/CheckInOutReportService';
import { translateError } from '../../utils/ErrorManagement';
import { CheckInOutFilters } from './FilterSlice';

export interface CheckInOutReportState {
  ins: any[];
  outs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
}

const initialState: CheckInOutReportState = {
  ins: [],
  outs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
};

export const runReport = createAsyncThunk(
  'reports/checkinout',
  async (filters: CheckInOutFilters, { rejectWithValue }) => {
    try {
      const ins = await CheckInOutReportService.Instance.runCheckInReport(filters);
      const outs = await CheckInOutReportService.Instance.runCheckOutReport(filters);
      return { ins: ins.data, outs: outs.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<CheckInOutReportState>) => {
  // --------------- GET REGIONS ---------------
  builder.addCase(runReport.pending, (state: CheckInOutReportState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(runReport.fulfilled, (state: CheckInOutReportState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.ins = action.payload.ins.data;
    state.outs = action.payload.outs.data;
  });
  builder.addCase(runReport.rejected, (state: CheckInOutReportState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.ins = [];
    state.outs = [];
  });
};

const checkInOutReportSlice = createSlice({
  name: 'checkInOutReportSlice',
  initialState,
  reducers: {
    setDone: () => {
      // TODO: implement me
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = checkInOutReportSlice;
export const { setDone } = checkInOutReportSlice.actions;
export default reducer;
