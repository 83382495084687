import {
  MasterCalendarApiDeletePropertyBlockRequest,
  MasterCalendarApiGetPropertyBlockRequest,
  MasterCalendarBlockDto,
} from '@/teams-openapi';
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { translateError } from 'utils/ErrorManagement';
import { BlockService } from '../../services/BlocksService';

export interface BlockState {
  fetchAllStatus: API_STATUS;
  result: MasterCalendarBlockDto[];
}

const initialState: BlockState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
};

export const getBlocks = createAsyncThunk(
  'master-calendar-block',
  async (filters: MasterCalendarApiGetPropertyBlockRequest, { rejectWithValue }) => {
    try {
      const dataResult = await BlockService.Instance.getBlocks(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteBlockAction = createAsyncThunk(
  'master-calendar-block-delete',
  async (filters: MasterCalendarApiDeletePropertyBlockRequest, { rejectWithValue }) => {
    try {
      const dataResult = await BlockService.Instance.deleteBlock(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<BlockState>) => {
  builder.addCase(getBlocks.pending, (state: BlockState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getBlocks.fulfilled, (state: BlockState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(getBlocks.rejected, (state: BlockState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });
  builder.addCase(deleteBlockAction.pending, (state: BlockState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
};

const blockSlice = createSlice({
  name: 'blockSlice',
  initialState,
  reducers: {
    clearBlocks: state => {
      state.result = [];
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = blockSlice;
export const { clearBlocks } = blockSlice.actions;
export default reducer;
