interface UserInfo {
  name: string | undefined;
  email: string | undefined;
}

export const initBeacon = (key: string) => {
  window.Beacon('init', key);
};

export const prefillBeacon = (userInfo: UserInfo) => {
  window.Beacon('prefill', userInfo);
};

export const suggestBeacon = (beaconIds: string) => {
  setTimeout(() => {
    window.Beacon('suggest', JSON.parse(beaconIds));
  }, 1000);
};
