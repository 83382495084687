import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
  localStorageComponent: string;
};

const initialState: SliceState = {
  localStorageComponent: '',
};

export const localStorageSlice = createSlice({
  name: 'localStorageComponent',
  initialState,
  reducers: {
    componentIdentity: (state, action: PayloadAction<string>) => {
      state.localStorageComponent = action.payload;
    },
  },
});

export const { componentIdentity } = localStorageSlice.actions;

export const selectComponent = (state: { localStorageComponent: SliceState }) =>
  state.localStorageComponent.localStorageComponent;

export default localStorageSlice.reducer;
