/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateDepartmentCommandDto } from '../models';
// @ts-ignore
import { DepartmentDto } from '../models';
// @ts-ignore
import { DepartmentDtoQueryParameters } from '../models';
// @ts-ignore
import { QueryResultDepartmentDto } from '../models';
// @ts-ignore
import { UpdateDepartmentLabelCommandDto } from '../models';
/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Department Command
         * @param {CreateDepartmentCommandDto} createDepartmentCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment: async (createDepartmentCommandDto: CreateDepartmentCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentCommandDto' is not null or undefined
            assertParamExists('createDepartment', 'createDepartmentCommandDto', createDepartmentCommandDto)
            const localVarPath = `/v1/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Department Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDepartment', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteDepartment', 'ifMatch', ifMatch)
            const localVarPath = `/v1/department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Departments
         * @param {DepartmentDtoQueryParameters} _queryParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDepartments: async (_queryParameters: DepartmentDtoQueryParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter '_queryParameters' is not null or undefined
            assertParamExists('findDepartments', '_queryParameters', _queryParameters)
            const localVarPath = `/v1/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (_queryParameters !== undefined) {
                for (const [key, value] of Object.entries(_queryParameters)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Department by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartment', 'id', id)
            const localVarPath = `/v1/department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Department Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateDepartmentLabelCommandDto} updateDepartmentLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentLabel: async (id: number, ifMatch: number, updateDepartmentLabelCommandDto: UpdateDepartmentLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDepartmentLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateDepartmentLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updateDepartmentLabelCommandDto' is not null or undefined
            assertParamExists('updateDepartmentLabel', 'updateDepartmentLabelCommandDto', updateDepartmentLabelCommandDto)
            const localVarPath = `/v1/department/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Department Command
         * @param {CreateDepartmentCommandDto} createDepartmentCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartment(createDepartmentCommandDto: CreateDepartmentCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartment(createDepartmentCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentApi.createDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Department Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartment(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentApi.deleteDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Departments
         * @param {DepartmentDtoQueryParameters} _queryParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDepartments(_queryParameters: DepartmentDtoQueryParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultDepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDepartments(_queryParameters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentApi.findDepartments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Department by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartment(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentApi.getDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Department Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateDepartmentLabelCommandDto} updateDepartmentLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartmentLabel(id: number, ifMatch: number, updateDepartmentLabelCommandDto: UpdateDepartmentLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentLabel(id, ifMatch, updateDepartmentLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentApi.updateDepartmentLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Department Command
         * @param {DepartmentApiCreateDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment(requestParameters: DepartmentApiCreateDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createDepartment(requestParameters.createDepartmentCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Department Command
         * @param {DepartmentApiDeleteDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment(requestParameters: DepartmentApiDeleteDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDepartment(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Departments
         * @param {DepartmentApiFindDepartmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDepartments(requestParameters: DepartmentApiFindDepartmentsRequest, options?: AxiosRequestConfig): AxiosPromise<QueryResultDepartmentDto> {
            return localVarFp.findDepartments(requestParameters._queryParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Department by ID
         * @param {DepartmentApiGetDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment(requestParameters: DepartmentApiGetDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.getDepartment(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Department Label Command
         * @param {DepartmentApiUpdateDepartmentLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentLabel(requestParameters: DepartmentApiUpdateDepartmentLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateDepartmentLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateDepartmentLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDepartment operation in DepartmentApi.
 * @export
 * @interface DepartmentApiCreateDepartmentRequest
 */
export interface DepartmentApiCreateDepartmentRequest {
    /**
     * 
     * @type {CreateDepartmentCommandDto}
     * @memberof DepartmentApiCreateDepartment
     */
    readonly createDepartmentCommandDto: CreateDepartmentCommandDto
}

/**
 * Request parameters for deleteDepartment operation in DepartmentApi.
 * @export
 * @interface DepartmentApiDeleteDepartmentRequest
 */
export interface DepartmentApiDeleteDepartmentRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartmentApiDeleteDepartment
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof DepartmentApiDeleteDepartment
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findDepartments operation in DepartmentApi.
 * @export
 * @interface DepartmentApiFindDepartmentsRequest
 */
export interface DepartmentApiFindDepartmentsRequest {
    /**
     * 
     * @type {DepartmentDtoQueryParameters}
     * @memberof DepartmentApiFindDepartments
     */
    readonly _queryParameters: DepartmentDtoQueryParameters
}

/**
 * Request parameters for getDepartment operation in DepartmentApi.
 * @export
 * @interface DepartmentApiGetDepartmentRequest
 */
export interface DepartmentApiGetDepartmentRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartmentApiGetDepartment
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof DepartmentApiGetDepartment
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for updateDepartmentLabel operation in DepartmentApi.
 * @export
 * @interface DepartmentApiUpdateDepartmentLabelRequest
 */
export interface DepartmentApiUpdateDepartmentLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartmentApiUpdateDepartmentLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof DepartmentApiUpdateDepartmentLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateDepartmentLabelCommandDto}
     * @memberof DepartmentApiUpdateDepartmentLabel
     */
    readonly updateDepartmentLabelCommandDto: UpdateDepartmentLabelCommandDto
}

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Department Command
     * @param {DepartmentApiCreateDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public createDepartment(requestParameters: DepartmentApiCreateDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).createDepartment(requestParameters.createDepartmentCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Department Command
     * @param {DepartmentApiDeleteDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public deleteDepartment(requestParameters: DepartmentApiDeleteDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).deleteDepartment(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Departments
     * @param {DepartmentApiFindDepartmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public findDepartments(requestParameters: DepartmentApiFindDepartmentsRequest, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).findDepartments(requestParameters._queryParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Department by ID
     * @param {DepartmentApiGetDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public getDepartment(requestParameters: DepartmentApiGetDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).getDepartment(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Department Label Command
     * @param {DepartmentApiUpdateDepartmentLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public updateDepartmentLabel(requestParameters: DepartmentApiUpdateDepartmentLabelRequest, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).updateDepartmentLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateDepartmentLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

