import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BottomSheetState, closeBottomSheet, openBottomSheet } from 'redux/slices/BottomSheetSlice';
import { AppState, useAppDispatch } from '../redux/Store';

export const useBottomSheet = () => {
  const dispatch = useAppDispatch();
  const bottomSheetState: BottomSheetState = useSelector(
    (state: AppState) => state.bottomSheetReducer,
  );

  const openBottomSheetHook = useCallback(
    (newState: BottomSheetState) => {
      return dispatch(openBottomSheet(newState));
    },
    [dispatch],
  );

  const closeBottomSheetHook = useCallback(() => {
    return dispatch(closeBottomSheet());
  }, [dispatch]);

  return {
    bottomSheetState,
    openBottomSheetHook,
    closeBottomSheetHook,
  };
};
