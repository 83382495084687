import {
  MasterCalendarApiActivateBookingRequest,
  MasterCalendarApiActivateTaskRequest,
  MasterCalendarApiDeactivateBookingRequest,
  MasterCalendarApiDeactivateTaskRequest,
  MasterCalendarApiDeleteNoteRequest,
  MasterCalendarApiGetBookingsRequest,
  MasterCalendarApiGetPropertyBlockRequest,
  MasterCalendarApiGetPropertyDetailsRequest,
  MasterCalendarApiGetPropertyListRequest,
  MasterCalendarApiGetSettingsRequest,
  MasterCalendarApiGetTasksRequest,
  MasterCalendarSettingsDto,
} from '@/teams-openapi';
import { API_STATUS } from 'constants/Constants';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MasterCalendarPropertyDetailState,
  getMasterCalendarPropertyDetail,
} from 'redux/slices/MasterCalendarPropertyDetailSlice';
import { getMasterCalendarProperties } from 'redux/slices/MasterCalendarPropertySlice';
import { getSettings } from 'redux/slices/MasterCalendarSettingsSlice';
import { MasterCalendarService } from 'services/MasterCalendarService';
import { AppState, useAppDispatch } from '../redux/Store';
import { clearBlocks, deleteBlockAction, getBlocks } from '../redux/slices/BlocksSlice';
import {
  activateBookingById,
  clearBookings,
  deactivateBookingById,
  getBookings,
} from '../redux/slices/BookingSlice';
import {
  MasterCalendarIssueCountState,
  getIssueCount,
} from '../redux/slices/MasterCalendarIssueCountSlice';
import { FilterState, setKeyword } from '../redux/slices/MasterCalendarSlice';
import { clearNotes, deleteNoteAction } from '../redux/slices/NoteSlice';
import {
  activateTaskById,
  clearTasks,
  deactivateTaskById,
  getTasks,
} from '../redux/slices/TaskSlice';

export const useMasterCalendar = () => {
  const dispatch = useAppDispatch();
  const filterState: FilterState = useSelector((state: AppState) => state.masterCalendarReducer);
  const masterCalendarIssueCountState: MasterCalendarIssueCountState = useSelector(
    (state: AppState) => state.masterCalendarIssueCountReducer,
  );
  const bookingState = useSelector((state: AppState) => state.bookingReducer);
  const taskState = useSelector((state: AppState) => state.taskReducer);
  const blockState = useSelector((state: AppState) => state.blockReducer);
  const masterCalendarPropertyState = useSelector(
    (state: AppState) => state.masterCalendarPropertyReducer,
  );
  const masterCalendarSettingsState = useSelector(
    (state: AppState) => state.masterCalendarSettingsSlice,
  );
  const propertiesDetail = useSelector(
    (state: AppState) => state.masterCalendarPropertyDetailReducer,
  );

  const [masterCalendarPropertyDetail, setMasterCalendarPropertyDetail] =
    useState<MasterCalendarPropertyDetailState>({
      fetchAllStatus: API_STATUS.IDLE,
      result: [],
    });

  useEffect(() => {
    const details: MasterCalendarPropertyDetailState = {
      fetchAllStatus: API_STATUS.IDLE,
      result: [],
    };
    propertiesDetail.result.forEach(property => {
      if (!details.result.find(p => p.id === property.id)) {
        details.result.push(property);
      }
    });
    setMasterCalendarPropertyDetail(details);
  }, [propertiesDetail]);

  const fetchMasterCalendarProperties = useCallback(
    (filters: MasterCalendarApiGetPropertyDetailsRequest) => {
      dispatch(getMasterCalendarPropertyDetail(filters));
    },
    [dispatch],
  );

  const fetchProperties = useCallback(
    (filters: MasterCalendarApiGetPropertyListRequest) => {
      dispatch(getMasterCalendarProperties(filters));
    },
    [dispatch],
  );

  const fetchTasks = useCallback(
    (filters: MasterCalendarApiGetTasksRequest) => {
      dispatch(getTasks(filters));
    },
    [dispatch],
  );

  const fetchBookings = (filters: MasterCalendarApiGetBookingsRequest) => {
    dispatch(getBookings(filters));
  };

  const fetchBlocks = (filters: MasterCalendarApiGetPropertyBlockRequest) => {
    dispatch(getBlocks(filters));
  };

  const deleteBlock = (filters: MasterCalendarApiDeleteNoteRequest) => {
    return dispatch(deleteBlockAction(filters));
  };

  const setFilterKeyword = (keyword: string) => {
    dispatch(setKeyword(keyword));
  };

  const getIssueCountHook = () => {
    dispatch(getIssueCount());
  };

  const getSettingsHook = useCallback(
    (args: MasterCalendarApiGetSettingsRequest) => {
      return dispatch(getSettings(args));
    },
    [dispatch],
  );

  const createSettingsHook = useCallback(async (settings: MasterCalendarSettingsDto) => {
    try {
      const dataResult = await MasterCalendarService.Instance.createSettings(settings);
      return { result: dataResult.data };
    } catch (err) {
      console.log(err);
    }
  }, []);

  const updateTaskActiveStatus = (
    filters: MasterCalendarApiActivateTaskRequest | MasterCalendarApiDeactivateTaskRequest,
    active: boolean,
  ) => {
    if (active) {
      return dispatch(activateTaskById(filters));
    } else {
      return dispatch(deactivateTaskById(filters));
    }
  };

  const updateBookingActiveStatus = (
    filters: MasterCalendarApiActivateBookingRequest | MasterCalendarApiDeactivateBookingRequest,
    active: boolean,
  ) => {
    if (active) {
      return dispatch(activateBookingById(filters));
    } else {
      return dispatch(deactivateBookingById(filters));
    }
  };

  const deleteNote = (filters: MasterCalendarApiDeleteNoteRequest) => {
    return dispatch(deleteNoteAction(filters));
  };

  const clearAllStates = () => {
    dispatch(clearTasks());
    dispatch(clearBookings());
    dispatch(clearBlocks());
    dispatch(clearNotes());
  };

  const updateNote = async (
    property: number,
    id: number,
    startDate: Date,
    endDate: Date,
    version: number,
    shortNote: string,
    longDescription: string,
  ) => {
    const isoStartDate = new Date(startDate).toISOString();
    const isoEndDate = new Date(endDate).toISOString();
    const dataResult = await MasterCalendarService.Instance.updateNote(
      id,
      {
        property,
        startDate: isoStartDate,
        endDate: isoEndDate,
        shortNote,
        longDescription,
      },
      version,
    );
    return { result: dataResult.data };
  };

  const updateMasterCalendarTask = async (id: number, version: number, scheduledDateTime: Date) => {
    const isoScheduledDateTime = new Date(scheduledDateTime).toISOString();
    const dataResult = await MasterCalendarService.Instance.updateTask(id, version, {
      scheduledDateTime: isoScheduledDateTime,
    });
    return { result: dataResult.data };
  };

  return {
    setFilterKeyword,
    filterState,
    getIssueCountHook,
    masterCalendarIssueCountState,
    updateMasterCalendarTask,
    updateNote,
    bookingState,
    fetchBookings,
    fetchBlocks,
    deleteBlock,
    blockState,
    masterCalendarPropertyState,
    taskState,
    fetchTasks,
    fetchProperties,
    masterCalendarSettingsState,
    getSettingsHook,
    createSettingsHook,
    deleteNote,
    updateTaskActiveStatus,
    updateBookingActiveStatus,
    fetchMasterCalendarProperties,
    masterCalendarPropertyDetail,
    clearAllStates,
  };
};
