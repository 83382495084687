import { TeamsConst } from 'constants/Base';
import {
  MasterCalendarApi,
  MasterCalendarApiActivateBookingRequest,
  MasterCalendarApiDeactivateBookingRequest,
  MasterCalendarApiGetBookingsRequest,
} from 'teams-openapi';
import { BaseService } from './BaseService';

export class BookingService {
  private _baseInstance: BaseService;
  private static _instance: BookingService;
  private api: MasterCalendarApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getBookings(filters: MasterCalendarApiGetBookingsRequest) {
    return this.api.getBookings(filters);
  }

  public activateBooking(filters: MasterCalendarApiActivateBookingRequest) {
    return this.api.activateBooking(filters);
  }

  public deactivateBooking(filters: MasterCalendarApiDeactivateBookingRequest) {
    return this.api.deactivateBooking(filters);
  }
}
