import { API_STATUS } from '../constants/Constants';
import { UserInfoDto } from '../teams-openapi';

export enum LOGIN_STATE {
  NOT_LOADED = 'NOT_LOADED',
  LOGGED_OUT = 'LOGGED_OUT',
  PENDING_FROM_LOGIN = 'PENDIN_FROM_LOGIN',
  LOADED_FROM_LOGIN = 'LOADED_FROM_LOGIN',
  FAILED_FROM_LOGIN = 'FAILED_FROM_LOGIN',
  LOADED_FROM_STORAGE = 'LOADED_FROM_STORAGE',
  PENDING_FROM_CFWJWT_LOGIN = 'PENDING_FROM_CFWJWT',
  LOADED_FROM_CFWJWT_LOGIN = 'LOADED_FROM_CFWJWT_LOGIN',
  FAILED_FROM_CFWJWT_LOGIN = 'FAILED_FROM_CFWJWT_LOGIN',
}

export type UserState = {
  Messages: any[];
  Warnings: any[];
  Failures: any[];
  Errors: any[];
  JwtToken: string;
  LoginState: LOGIN_STATE;
  OriginLoginState: LOGIN_STATE;
  UserInfoState: API_STATUS;
  UserInfoDto: UserInfoDto;
  CurrentCid: number;
  Claims: Array<string>;
  locale?: string;
  ServicerId?: number;
};
