import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';

const filterValue = JSON.parse(localStorage.getItem('filter') ?? '{}');
const date = filterValue?.['filterDate'] ? filterValue?.['filterDate'] : new Date();

type SliceState = {
  date: number;
};

const initialState: SliceState = {
  date: getUnixTime(date),
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    updateDate: (state, action: PayloadAction<Date>) => {
      state.date = getUnixTime(action.payload);
    },
  },
});

export const { updateDate } = dateSlice.actions;

export const selectDate = (state: { date: SliceState }) => {
  return fromUnixTime(state.date.date);
};

export default dateSlice.reducer;
