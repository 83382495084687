/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddTagToPropertyCommandDto } from '../models';
// @ts-ignore
import { BulkActivatePropertiesCommandDto } from '../models';
// @ts-ignore
import { BulkArchivePropertiesCommandDto } from '../models';
// @ts-ignore
import { BulkDeactivatePropertiesCommandDto } from '../models';
// @ts-ignore
import { BulkRestorePropertiesCommandDto } from '../models';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreatePropertyCommandDto } from '../models';
// @ts-ignore
import { MovePropertyCommandDto } from '../models';
// @ts-ignore
import { PropertyDto } from '../models';
// @ts-ignore
import { QueryResultPropertyDto } from '../models';
// @ts-ignore
import { RemoveTagFromPropertyCommandDto } from '../models';
// @ts-ignore
import { UpdatePropertyInfoCommandDto } from '../models';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Activate Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateProperty: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('activateProperty', 'ifMatch', ifMatch)
            const localVarPath = `/v1/property/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Add Tag to Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AddTagToPropertyCommandDto} addTagToPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagToProperty: async (id: number, ifMatch: number, addTagToPropertyCommandDto: AddTagToPropertyCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTagToProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('addTagToProperty', 'ifMatch', ifMatch)
            // verify required parameter 'addTagToPropertyCommandDto' is not null or undefined
            assertParamExists('addTagToProperty', 'addTagToPropertyCommandDto', addTagToPropertyCommandDto)
            const localVarPath = `/v1/property/{id}/add-tag`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTagToPropertyCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive a Property
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProperty: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('archiveProperty', 'ifMatch', ifMatch)
            const localVarPath = `/v1/property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Activate Properties Command
         * @param {BulkActivatePropertiesCommandDto} bulkActivatePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkActivateProperties: async (bulkActivatePropertiesCommandDto: BulkActivatePropertiesCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkActivatePropertiesCommandDto' is not null or undefined
            assertParamExists('bulkActivateProperties', 'bulkActivatePropertiesCommandDto', bulkActivatePropertiesCommandDto)
            const localVarPath = `/v1/property/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkActivatePropertiesCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Archive Properties Command
         * @param {BulkArchivePropertiesCommandDto} bulkArchivePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkArchiveProperties: async (bulkArchivePropertiesCommandDto: BulkArchivePropertiesCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkArchivePropertiesCommandDto' is not null or undefined
            assertParamExists('bulkArchiveProperties', 'bulkArchivePropertiesCommandDto', bulkArchivePropertiesCommandDto)
            const localVarPath = `/v1/property/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkArchivePropertiesCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Deactivate Properties Command
         * @param {BulkDeactivatePropertiesCommandDto} bulkDeactivatePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeactivateProperties: async (bulkDeactivatePropertiesCommandDto: BulkDeactivatePropertiesCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkDeactivatePropertiesCommandDto' is not null or undefined
            assertParamExists('bulkDeactivateProperties', 'bulkDeactivatePropertiesCommandDto', bulkDeactivatePropertiesCommandDto)
            const localVarPath = `/v1/property/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkDeactivatePropertiesCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Restore Properties Command
         * @param {BulkRestorePropertiesCommandDto} bulkRestorePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRestoreProperties: async (bulkRestorePropertiesCommandDto: BulkRestorePropertiesCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkRestorePropertiesCommandDto' is not null or undefined
            assertParamExists('bulkRestoreProperties', 'bulkRestorePropertiesCommandDto', bulkRestorePropertiesCommandDto)
            const localVarPath = `/v1/property/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkRestorePropertiesCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Create Property Command
         * @param {CreatePropertyCommandDto} createPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty: async (createPropertyCommandDto: CreatePropertyCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyCommandDto' is not null or undefined
            assertParamExists('createProperty', 'createPropertyCommandDto', createPropertyCommandDto)
            const localVarPath = `/v1/property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Deactivate Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProperty: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deactivateProperty', 'ifMatch', ifMatch)
            const localVarPath = `/v1/property/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Properties
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProperties: async (customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (nameStartsWith !== undefined) {
                localVarQueryParameter['nameStartsWith'] = nameStartsWith;
            }

            if (propertyGroupId !== undefined) {
                localVarQueryParameter['propertyGroupId'] = propertyGroupId;
            }

            if (regionGroupId !== undefined) {
                localVarQueryParameter['regionGroupId'] = regionGroupId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (createdOnOrAfter !== undefined) {
                localVarQueryParameter['createdOnOrAfter'] = (createdOnOrAfter as any instanceof Date) ?
                    (createdOnOrAfter as any).toISOString() :
                    createdOnOrAfter;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }

            if (propertyIds) {
                localVarQueryParameter['propertyIds'] = propertyIds;
            }

            if (statusIds) {
                localVarQueryParameter['statusIds'] = statusIds;
            }

            if (tagsIds) {
                localVarQueryParameter['tagsIds'] = tagsIds;
            }

            if (ownerIds) {
                localVarQueryParameter['ownerIds'] = ownerIds;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProperty', 'id', id)
            const localVarPath = `/v1/property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a MOve Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyCommandDto} movePropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProperty: async (id: number, ifMatch: number, movePropertyCommandDto: MovePropertyCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('moveProperty', 'ifMatch', ifMatch)
            // verify required parameter 'movePropertyCommandDto' is not null or undefined
            assertParamExists('moveProperty', 'movePropertyCommandDto', movePropertyCommandDto)
            const localVarPath = `/v1/property/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(movePropertyCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Remove Tag to Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {RemoveTagFromPropertyCommandDto} removeTagFromPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagFromProperty: async (id: number, ifMatch: number, removeTagFromPropertyCommandDto: RemoveTagFromPropertyCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTagFromProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('removeTagFromProperty', 'ifMatch', ifMatch)
            // verify required parameter 'removeTagFromPropertyCommandDto' is not null or undefined
            assertParamExists('removeTagFromProperty', 'removeTagFromPropertyCommandDto', removeTagFromPropertyCommandDto)
            const localVarPath = `/v1/property/{id}/remove-tag`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeTagFromPropertyCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Restore Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreProperty: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('restoreProperty', 'ifMatch', ifMatch)
            const localVarPath = `/v1/property/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Information Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyInfoCommandDto} updatePropertyInfoCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyInfo: async (id: number, ifMatch: number, updatePropertyInfoCommandDto: UpdatePropertyInfoCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyInfo', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyInfo', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyInfoCommandDto' is not null or undefined
            assertParamExists('updatePropertyInfo', 'updatePropertyInfoCommandDto', updatePropertyInfoCommandDto)
            const localVarPath = `/v1/property/{id}/update-property-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyInfoCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Activate Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateProperty(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateProperty(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.activateProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Add Tag to Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {AddTagToPropertyCommandDto} addTagToPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTagToProperty(id: number, ifMatch: number, addTagToPropertyCommandDto: AddTagToPropertyCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTagToProperty(id, ifMatch, addTagToPropertyCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.addTagToProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Archive a Property
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveProperty(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveProperty(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.archiveProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Bulk Activate Properties Command
         * @param {BulkActivatePropertiesCommandDto} bulkActivatePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkActivateProperties(bulkActivatePropertiesCommandDto: BulkActivatePropertiesCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommandResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkActivateProperties(bulkActivatePropertiesCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.bulkActivateProperties']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Bulk Archive Properties Command
         * @param {BulkArchivePropertiesCommandDto} bulkArchivePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkArchiveProperties(bulkArchivePropertiesCommandDto: BulkArchivePropertiesCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommandResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkArchiveProperties(bulkArchivePropertiesCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.bulkArchiveProperties']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Bulk Deactivate Properties Command
         * @param {BulkDeactivatePropertiesCommandDto} bulkDeactivatePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDeactivateProperties(bulkDeactivatePropertiesCommandDto: BulkDeactivatePropertiesCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommandResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDeactivateProperties(bulkDeactivatePropertiesCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.bulkDeactivateProperties']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Bulk Restore Properties Command
         * @param {BulkRestorePropertiesCommandDto} bulkRestorePropertiesCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRestoreProperties(bulkRestorePropertiesCommandDto: BulkRestorePropertiesCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommandResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRestoreProperties(bulkRestorePropertiesCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.bulkRestoreProperties']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Create Property Command
         * @param {CreatePropertyCommandDto} createPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProperty(createPropertyCommandDto: CreatePropertyCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProperty(createPropertyCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.createProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Deactivate Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateProperty(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateProperty(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.deactivateProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Properties
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProperties(customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProperties(customerId, servicerId, nameStartsWith, propertyGroupId, regionGroupId, regionId, regionIds, ownerId, createdOnOrAfter, isActive, isDeleted, propertyIds, statusIds, tagsIds, ownerIds, regionGroupIds, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.findProperties']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Property by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.getProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a MOve Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyCommandDto} movePropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveProperty(id: number, ifMatch: number, movePropertyCommandDto: MovePropertyCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveProperty(id, ifMatch, movePropertyCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.moveProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Remove Tag to Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {RemoveTagFromPropertyCommandDto} removeTagFromPropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTagFromProperty(id: number, ifMatch: number, removeTagFromPropertyCommandDto: RemoveTagFromPropertyCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTagFromProperty(id, ifMatch, removeTagFromPropertyCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.removeTagFromProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Restore Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreProperty(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreProperty(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.restoreProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Information Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyInfoCommandDto} updatePropertyInfoCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyInfo(id: number, ifMatch: number, updatePropertyInfoCommandDto: UpdatePropertyInfoCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyInfo(id, ifMatch, updatePropertyInfoCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.updatePropertyInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Activate Property Command
         * @param {PropertyApiActivatePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateProperty(requestParameters: PropertyApiActivatePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.activateProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Add Tag to Property Command
         * @param {PropertyApiAddTagToPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagToProperty(requestParameters: PropertyApiAddTagToPropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.addTagToProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.addTagToPropertyCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive a Property
         * @param {PropertyApiArchivePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProperty(requestParameters: PropertyApiArchivePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.archiveProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk Activate Properties Command
         * @param {PropertyApiBulkActivatePropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkActivateProperties(requestParameters: PropertyApiBulkActivatePropertiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CommandResultDto>> {
            return localVarFp.bulkActivateProperties(requestParameters.bulkActivatePropertiesCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk Archive Properties Command
         * @param {PropertyApiBulkArchivePropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkArchiveProperties(requestParameters: PropertyApiBulkArchivePropertiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CommandResultDto>> {
            return localVarFp.bulkArchiveProperties(requestParameters.bulkArchivePropertiesCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk Deactivate Properties Command
         * @param {PropertyApiBulkDeactivatePropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeactivateProperties(requestParameters: PropertyApiBulkDeactivatePropertiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CommandResultDto>> {
            return localVarFp.bulkDeactivateProperties(requestParameters.bulkDeactivatePropertiesCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk Restore Properties Command
         * @param {PropertyApiBulkRestorePropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRestoreProperties(requestParameters: PropertyApiBulkRestorePropertiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CommandResultDto>> {
            return localVarFp.bulkRestoreProperties(requestParameters.bulkRestorePropertiesCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Create Property Command
         * @param {PropertyApiCreatePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty(requestParameters: PropertyApiCreatePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createProperty(requestParameters.createPropertyCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Deactivate Property Command
         * @param {PropertyApiDeactivatePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProperty(requestParameters: PropertyApiDeactivatePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.deactivateProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Properties
         * @param {PropertyApiFindPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProperties(requestParameters: PropertyApiFindPropertiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultPropertyDto> {
            return localVarFp.findProperties(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property by ID
         * @param {PropertyApiGetPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(requestParameters: PropertyApiGetPropertyRequest, options?: AxiosRequestConfig): AxiosPromise<PropertyDto> {
            return localVarFp.getProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a MOve Property Command
         * @param {PropertyApiMovePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProperty(requestParameters: PropertyApiMovePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.moveProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Remove Tag to Property Command
         * @param {PropertyApiRemoveTagFromPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagFromProperty(requestParameters: PropertyApiRemoveTagFromPropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.removeTagFromProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.removeTagFromPropertyCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Restore Property Command
         * @param {PropertyApiRestorePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreProperty(requestParameters: PropertyApiRestorePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.restoreProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Information Command
         * @param {PropertyApiUpdatePropertyInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyInfo(requestParameters: PropertyApiUpdatePropertyInfoRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyInfo(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyInfoCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiActivatePropertyRequest
 */
export interface PropertyApiActivatePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiActivateProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiActivateProperty
     */
    readonly ifMatch: number
}

/**
 * Request parameters for addTagToProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiAddTagToPropertyRequest
 */
export interface PropertyApiAddTagToPropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiAddTagToProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiAddTagToProperty
     */
    readonly ifMatch: number

    /**
     * 
     * @type {AddTagToPropertyCommandDto}
     * @memberof PropertyApiAddTagToProperty
     */
    readonly addTagToPropertyCommandDto: AddTagToPropertyCommandDto
}

/**
 * Request parameters for archiveProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiArchivePropertyRequest
 */
export interface PropertyApiArchivePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiArchiveProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiArchiveProperty
     */
    readonly ifMatch: number
}

/**
 * Request parameters for bulkActivateProperties operation in PropertyApi.
 * @export
 * @interface PropertyApiBulkActivatePropertiesRequest
 */
export interface PropertyApiBulkActivatePropertiesRequest {
    /**
     * 
     * @type {BulkActivatePropertiesCommandDto}
     * @memberof PropertyApiBulkActivateProperties
     */
    readonly bulkActivatePropertiesCommandDto: BulkActivatePropertiesCommandDto
}

/**
 * Request parameters for bulkArchiveProperties operation in PropertyApi.
 * @export
 * @interface PropertyApiBulkArchivePropertiesRequest
 */
export interface PropertyApiBulkArchivePropertiesRequest {
    /**
     * 
     * @type {BulkArchivePropertiesCommandDto}
     * @memberof PropertyApiBulkArchiveProperties
     */
    readonly bulkArchivePropertiesCommandDto: BulkArchivePropertiesCommandDto
}

/**
 * Request parameters for bulkDeactivateProperties operation in PropertyApi.
 * @export
 * @interface PropertyApiBulkDeactivatePropertiesRequest
 */
export interface PropertyApiBulkDeactivatePropertiesRequest {
    /**
     * 
     * @type {BulkDeactivatePropertiesCommandDto}
     * @memberof PropertyApiBulkDeactivateProperties
     */
    readonly bulkDeactivatePropertiesCommandDto: BulkDeactivatePropertiesCommandDto
}

/**
 * Request parameters for bulkRestoreProperties operation in PropertyApi.
 * @export
 * @interface PropertyApiBulkRestorePropertiesRequest
 */
export interface PropertyApiBulkRestorePropertiesRequest {
    /**
     * 
     * @type {BulkRestorePropertiesCommandDto}
     * @memberof PropertyApiBulkRestoreProperties
     */
    readonly bulkRestorePropertiesCommandDto: BulkRestorePropertiesCommandDto
}

/**
 * Request parameters for createProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiCreatePropertyRequest
 */
export interface PropertyApiCreatePropertyRequest {
    /**
     * 
     * @type {CreatePropertyCommandDto}
     * @memberof PropertyApiCreateProperty
     */
    readonly createPropertyCommandDto: CreatePropertyCommandDto
}

/**
 * Request parameters for deactivateProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiDeactivatePropertyRequest
 */
export interface PropertyApiDeactivatePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiDeactivateProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiDeactivateProperty
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findProperties operation in PropertyApi.
 * @export
 * @interface PropertyApiFindPropertiesRequest
 */
export interface PropertyApiFindPropertiesRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly servicerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyApiFindProperties
     */
    readonly nameStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly propertyGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly regionGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly regionId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly ownerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyApiFindProperties
     */
    readonly createdOnOrAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof PropertyApiFindProperties
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PropertyApiFindProperties
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly propertyIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly statusIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly tagsIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly ownerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyApiFindProperties
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiFindProperties
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyApiFindProperties
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiGetPropertyRequest
 */
export interface PropertyApiGetPropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiGetProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiGetProperty
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for moveProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiMovePropertyRequest
 */
export interface PropertyApiMovePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiMoveProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiMoveProperty
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MovePropertyCommandDto}
     * @memberof PropertyApiMoveProperty
     */
    readonly movePropertyCommandDto: MovePropertyCommandDto
}

/**
 * Request parameters for removeTagFromProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiRemoveTagFromPropertyRequest
 */
export interface PropertyApiRemoveTagFromPropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiRemoveTagFromProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiRemoveTagFromProperty
     */
    readonly ifMatch: number

    /**
     * 
     * @type {RemoveTagFromPropertyCommandDto}
     * @memberof PropertyApiRemoveTagFromProperty
     */
    readonly removeTagFromPropertyCommandDto: RemoveTagFromPropertyCommandDto
}

/**
 * Request parameters for restoreProperty operation in PropertyApi.
 * @export
 * @interface PropertyApiRestorePropertyRequest
 */
export interface PropertyApiRestorePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiRestoreProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiRestoreProperty
     */
    readonly ifMatch: number
}

/**
 * Request parameters for updatePropertyInfo operation in PropertyApi.
 * @export
 * @interface PropertyApiUpdatePropertyInfoRequest
 */
export interface PropertyApiUpdatePropertyInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyApiUpdatePropertyInfo
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyApiUpdatePropertyInfo
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyInfoCommandDto}
     * @memberof PropertyApiUpdatePropertyInfo
     */
    readonly updatePropertyInfoCommandDto: UpdatePropertyInfoCommandDto
}

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary Send a Activate Property Command
     * @param {PropertyApiActivatePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public activateProperty(requestParameters: PropertyApiActivatePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).activateProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Add Tag to Property Command
     * @param {PropertyApiAddTagToPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public addTagToProperty(requestParameters: PropertyApiAddTagToPropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).addTagToProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.addTagToPropertyCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive a Property
     * @param {PropertyApiArchivePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public archiveProperty(requestParameters: PropertyApiArchivePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).archiveProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk Activate Properties Command
     * @param {PropertyApiBulkActivatePropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public bulkActivateProperties(requestParameters: PropertyApiBulkActivatePropertiesRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).bulkActivateProperties(requestParameters.bulkActivatePropertiesCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk Archive Properties Command
     * @param {PropertyApiBulkArchivePropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public bulkArchiveProperties(requestParameters: PropertyApiBulkArchivePropertiesRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).bulkArchiveProperties(requestParameters.bulkArchivePropertiesCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk Deactivate Properties Command
     * @param {PropertyApiBulkDeactivatePropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public bulkDeactivateProperties(requestParameters: PropertyApiBulkDeactivatePropertiesRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).bulkDeactivateProperties(requestParameters.bulkDeactivatePropertiesCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk Restore Properties Command
     * @param {PropertyApiBulkRestorePropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public bulkRestoreProperties(requestParameters: PropertyApiBulkRestorePropertiesRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).bulkRestoreProperties(requestParameters.bulkRestorePropertiesCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Create Property Command
     * @param {PropertyApiCreatePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public createProperty(requestParameters: PropertyApiCreatePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).createProperty(requestParameters.createPropertyCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Deactivate Property Command
     * @param {PropertyApiDeactivatePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public deactivateProperty(requestParameters: PropertyApiDeactivatePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).deactivateProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Properties
     * @param {PropertyApiFindPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public findProperties(requestParameters: PropertyApiFindPropertiesRequest = {}, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).findProperties(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property by ID
     * @param {PropertyApiGetPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperty(requestParameters: PropertyApiGetPropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a MOve Property Command
     * @param {PropertyApiMovePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public moveProperty(requestParameters: PropertyApiMovePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).moveProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Remove Tag to Property Command
     * @param {PropertyApiRemoveTagFromPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public removeTagFromProperty(requestParameters: PropertyApiRemoveTagFromPropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).removeTagFromProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.removeTagFromPropertyCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Restore Property Command
     * @param {PropertyApiRestorePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public restoreProperty(requestParameters: PropertyApiRestorePropertyRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).restoreProperty(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Information Command
     * @param {PropertyApiUpdatePropertyInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public updatePropertyInfo(requestParameters: PropertyApiUpdatePropertyInfoRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).updatePropertyInfo(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyInfoCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

