// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { API_STATUS } from '../../constants/Constants';
import { LOGIN_STATE, UserState } from '../../models/UserState';
import { AccountService } from '../../services/AccountService';
import { AuthService } from '../../services/AuthService';
import { ClaimsBuilder } from '../../services/ClaimsBuilder';
import i18n from '../../services/i18n';

const initialState: UserState = {
  Messages: [],
  Warnings: [],
  Failures: [],
  Errors: [],
  LoginState: LOGIN_STATE.NOT_LOADED,
  OriginLoginState: LOGIN_STATE.NOT_LOADED,
  JwtToken: null,
  CurrentCid: null,
  Claims: [],
  UserInfoState: API_STATUS.IDLE,
  UserInfoDto: {},
  ServicerId: null,
};

export const authenticateUser = createAsyncThunk(
  'login',
  async (credentials: any, { rejectWithValue }) => {
    try {
      const { email, password } = credentials;
      const res = await AuthService.Instance.authenticateUser(email, password);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const authenticateUserFromCfwJwt = createAsyncThunk(
  'logincfwjwt',
  async (_: any, { rejectWithValue }) => {
    try {
      const res = await AuthService.Instance.authenticateUserFromCfwJwt();
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserInfo = createAsyncThunk(
  'getuserinfo',
  async (cid: number, { rejectWithValue }) => {
    try {
      const res = await AuthService.Instance.getUserInfo(cid);
      return { data: res.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUseNewGui = createAsyncThunk(
  'account/updateUseNewGui',
  async (useNewGui: boolean, { rejectWithValue }) => {
    console.log('calling');
    try {
      const res = await AccountService.Instance.updateUseNewGui(useNewGui);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<UserState>) => {
  // --------------- LOGIN ---------------
  builder.addCase(authenticateUser.pending, (state: UserState, action) => {
    state.LoginState = LOGIN_STATE.PENDING_FROM_LOGIN;
  });
  builder.addCase(authenticateUser.fulfilled, (state: UserState, action) => {
    console.log('logging in from login ... ');
    const token = action.payload.jwtToken;
    const user: any = jwtDecode(token);
    const newState = {
      ...state,
      LoginState: LOGIN_STATE.LOADED_FROM_LOGIN,
      OriginLoginState: LOGIN_STATE.LOADED_FROM_LOGIN,
      Errors: [],
      JwtToken: token,
      User: user,
      CurrentCid: user.default_cid,
      ServicerId: action.payload.servicerId,
    };
    localStorage.setItem('userState', JSON.stringify(newState));
    i18n.changeLanguage(user.Locale);
    return newState;
  });
  builder.addCase(authenticateUser.rejected, (state: UserState, action) => {
    //state.errors = [];    // todo: add errors
    state.LoginState = LOGIN_STATE.FAILED_FROM_LOGIN;
    localStorage.setItem('userState', JSON.stringify(state));
  });

  // AUTH FROM JWT
  builder.addCase(authenticateUserFromCfwJwt.pending, (state: UserState, action) => {
    state.LoginState = LOGIN_STATE.PENDING_FROM_CFWJWT_LOGIN;
    localStorage.setItem('userState', JSON.stringify(state));
  });
  builder.addCase(authenticateUserFromCfwJwt.fulfilled, (state: UserState, action) => {
    console.log('logging in from cfw ... ');
    const token = action.payload.jwtToken;
    const user: any = jwtDecode(token);
    const newState = {
      ...state,
      LoginState: LOGIN_STATE.LOADED_FROM_CFWJWT_LOGIN,
      OriginLoginState: LOGIN_STATE.LOADED_FROM_CFWJWT_LOGIN,
      Errors: [],
      JwtToken: token,
      CurrentCid: user.default_cid,
      locale: user.locale,
      ServicerId: action.payload.servicerId,
    };
    localStorage.setItem('userState', JSON.stringify(newState));
    i18n.changeLanguage(user.Locale);
    return newState;
  });
  builder.addCase(authenticateUserFromCfwJwt.rejected, (state: UserState, action) => {
    //state.errors = [];    // todo: add errors
    state.LoginState = LOGIN_STATE.FAILED_FROM_CFWJWT_LOGIN;
    localStorage.setItem('userState', JSON.stringify(state));
  });

  // GET USER INFO
  builder.addCase(getUserInfo.pending, (state: UserState, action) => {
    state.UserInfoState = API_STATUS.PENDING;
    localStorage.setItem('userState', JSON.stringify(state));
  });
  builder.addCase(getUserInfo.fulfilled, (state: UserState, action) => {
    const userInfoDto = action.payload.data;
    const newState = {
      ...state,
      UserInfoDto: userInfoDto,
      UserInfoState: API_STATUS.SUCCESS,
      Claims: ClaimsBuilder.BuildClaims(userInfoDto),
    };
    localStorage.setItem('userState', JSON.stringify(newState));
    return newState;
  });
  builder.addCase(getUserInfo.rejected, (state: UserState, action) => {
    //state.errors = [];    // todo: add errors
    state.UserInfoState = API_STATUS.FAILURE;
  });
  //new Gui flag
  builder.addCase(updateUseNewGui.pending, (state: UserState) => {
    state.UserInfoState = API_STATUS.PENDING;
  });
  builder.addCase(updateUseNewGui.fulfilled, (state: UserState, action) => {
    state.UserInfoState = API_STATUS.SUCCESS;
    const payload = action.payload as UpdateGuiPreferenceCommandDto;
    state.UserInfoDto = { ...state.UserInfoDto, useNewGui: payload.useNewGui };
  });
  builder.addCase(updateUseNewGui.rejected, (state: UserState, action) => {
    state.UserInfoState = API_STATUS.FAILURE;
  });
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadUserFromStorage: (state, action) => {
      console.log('logging in from storage ... ');
      state.LoginState = LOGIN_STATE.LOADED_FROM_STORAGE;
      state.OriginLoginState = LOGIN_STATE.LOADED_FROM_STORAGE; // action.payload.OriginLoginState;
      state.UserInfoDto = action.payload.UserInfoDto;
      state.JwtToken = action.payload.JwtToken;
      state.CurrentCid = action.payload.CurrentCid;
      (state.ServicerId = action.payload.servicerId),
        localStorage.setItem('userState', JSON.stringify(state));
      if (action.payload.UserInfoDto) {
        i18n.changeLanguage(action.payload.UserInfoDto.Locale);
      }
    },
    logoutUser: (state, action) => {
      state.LoginState = LOGIN_STATE.LOGGED_OUT;
      state.UserInfoDto = undefined;
      state.JwtToken = undefined;
      localStorage.removeItem('userState');
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = authSlice;
export const { loadUserFromStorage, logoutUser } = authSlice.actions;
export default reducer;
