/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreatePropertyTagCommandDto } from '../models';
// @ts-ignore
import { PropertyTagDto } from '../models';
// @ts-ignore
import { QueryResultPropertyTagDto } from '../models';
// @ts-ignore
import { UpdatePropertyTagLabelCommandDto } from '../models';
/**
 * PropertyTagApi - axios parameter creator
 * @export
 */
export const PropertyTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create a Property Tag Command
         * @param {CreatePropertyTagCommandDto} createPropertyTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyTag: async (createPropertyTagCommandDto: CreatePropertyTagCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyTagCommandDto' is not null or undefined
            assertParamExists('createPropertyTag', 'createPropertyTagCommandDto', createPropertyTagCommandDto)
            const localVarPath = `/v1/propertytag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyTagCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Property Tag Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyTag: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePropertyTag', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deletePropertyTag', 'ifMatch', ifMatch)
            const localVarPath = `/v1/propertytag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Property Tags
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyTags: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/propertytag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Tag by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTag: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyTag', 'id', id)
            const localVarPath = `/v1/propertytag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Tag Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyTagLabelCommandDto} updatePropertyTagLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyTagLabel: async (id: number, ifMatch: number, updatePropertyTagLabelCommandDto: UpdatePropertyTagLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyTagLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyTagLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyTagLabelCommandDto' is not null or undefined
            assertParamExists('updatePropertyTagLabel', 'updatePropertyTagLabelCommandDto', updatePropertyTagLabelCommandDto)
            const localVarPath = `/v1/propertytag/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyTagLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyTagApi - functional programming interface
 * @export
 */
export const PropertyTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create a Property Tag Command
         * @param {CreatePropertyTagCommandDto} createPropertyTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyTag(createPropertyTagCommandDto: CreatePropertyTagCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyTag(createPropertyTagCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyTagApi.createPropertyTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Property Tag Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyTag(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyTag(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyTagApi.deletePropertyTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Property Tags
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPropertyTags(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultPropertyTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPropertyTags(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyTagApi.findPropertyTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Property Tag by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyTag(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyTag(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyTagApi.getPropertyTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Tag Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyTagLabelCommandDto} updatePropertyTagLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyTagLabel(id: number, ifMatch: number, updatePropertyTagLabelCommandDto: UpdatePropertyTagLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyTagLabel(id, ifMatch, updatePropertyTagLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyTagApi.updatePropertyTagLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyTagApi - factory interface
 * @export
 */
export const PropertyTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyTagApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create a Property Tag Command
         * @param {PropertyTagApiCreatePropertyTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyTag(requestParameters: PropertyTagApiCreatePropertyTagRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createPropertyTag(requestParameters.createPropertyTagCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Property Tag Command
         * @param {PropertyTagApiDeletePropertyTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyTag(requestParameters: PropertyTagApiDeletePropertyTagRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePropertyTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Property Tags
         * @param {PropertyTagApiFindPropertyTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyTags(requestParameters: PropertyTagApiFindPropertyTagsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultPropertyTagDto> {
            return localVarFp.findPropertyTags(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Tag by ID
         * @param {PropertyTagApiGetPropertyTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTag(requestParameters: PropertyTagApiGetPropertyTagRequest, options?: AxiosRequestConfig): AxiosPromise<PropertyTagDto> {
            return localVarFp.getPropertyTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Tag Label Command
         * @param {PropertyTagApiUpdatePropertyTagLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyTagLabel(requestParameters: PropertyTagApiUpdatePropertyTagLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyTagLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyTagLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPropertyTag operation in PropertyTagApi.
 * @export
 * @interface PropertyTagApiCreatePropertyTagRequest
 */
export interface PropertyTagApiCreatePropertyTagRequest {
    /**
     * 
     * @type {CreatePropertyTagCommandDto}
     * @memberof PropertyTagApiCreatePropertyTag
     */
    readonly createPropertyTagCommandDto: CreatePropertyTagCommandDto
}

/**
 * Request parameters for deletePropertyTag operation in PropertyTagApi.
 * @export
 * @interface PropertyTagApiDeletePropertyTagRequest
 */
export interface PropertyTagApiDeletePropertyTagRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiDeletePropertyTag
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiDeletePropertyTag
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findPropertyTags operation in PropertyTagApi.
 * @export
 * @interface PropertyTagApiFindPropertyTagsRequest
 */
export interface PropertyTagApiFindPropertyTagsRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiFindPropertyTags
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyTagApiFindPropertyTags
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiFindPropertyTags
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiFindPropertyTags
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyTagApiFindPropertyTags
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyTag operation in PropertyTagApi.
 * @export
 * @interface PropertyTagApiGetPropertyTagRequest
 */
export interface PropertyTagApiGetPropertyTagRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiGetPropertyTag
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiGetPropertyTag
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for updatePropertyTagLabel operation in PropertyTagApi.
 * @export
 * @interface PropertyTagApiUpdatePropertyTagLabelRequest
 */
export interface PropertyTagApiUpdatePropertyTagLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiUpdatePropertyTagLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyTagApiUpdatePropertyTagLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyTagLabelCommandDto}
     * @memberof PropertyTagApiUpdatePropertyTagLabel
     */
    readonly updatePropertyTagLabelCommandDto: UpdatePropertyTagLabelCommandDto
}

/**
 * PropertyTagApi - object-oriented interface
 * @export
 * @class PropertyTagApi
 * @extends {BaseAPI}
 */
export class PropertyTagApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create a Property Tag Command
     * @param {PropertyTagApiCreatePropertyTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTagApi
     */
    public createPropertyTag(requestParameters: PropertyTagApiCreatePropertyTagRequest, options?: AxiosRequestConfig) {
        return PropertyTagApiFp(this.configuration).createPropertyTag(requestParameters.createPropertyTagCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Property Tag Command
     * @param {PropertyTagApiDeletePropertyTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTagApi
     */
    public deletePropertyTag(requestParameters: PropertyTagApiDeletePropertyTagRequest, options?: AxiosRequestConfig) {
        return PropertyTagApiFp(this.configuration).deletePropertyTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Property Tags
     * @param {PropertyTagApiFindPropertyTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTagApi
     */
    public findPropertyTags(requestParameters: PropertyTagApiFindPropertyTagsRequest = {}, options?: AxiosRequestConfig) {
        return PropertyTagApiFp(this.configuration).findPropertyTags(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Tag by ID
     * @param {PropertyTagApiGetPropertyTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTagApi
     */
    public getPropertyTag(requestParameters: PropertyTagApiGetPropertyTagRequest, options?: AxiosRequestConfig) {
        return PropertyTagApiFp(this.configuration).getPropertyTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Tag Label Command
     * @param {PropertyTagApiUpdatePropertyTagLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTagApi
     */
    public updatePropertyTagLabel(requestParameters: PropertyTagApiUpdatePropertyTagLabelRequest, options?: AxiosRequestConfig) {
        return PropertyTagApiFp(this.configuration).updatePropertyTagLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyTagLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

