import { createTheme } from '@mui/material/styles';

const Normal = createTheme({
  palette: {
    primary: {
      main: '#004EFF',
      contrastText: '#ffffff',
      dark: '#004EFF',
    },
    secondary: {
      main: '#e0e0e0',
      light: '#C5C6CA',
      dark: '#03229F',
    },
    info: {
      main: '#f0f2fc',
      dark: '#5E6177',
    },
    error: {
      main: '#b32e2e',
    },
    success: {
      main: '#28a828',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

export default Normal;
