import uniq from 'lodash/uniq';
import roles from '../constants/RolesToClaimsMappings';
import { UserInfoDto } from '../teams-openapi';

export class ClaimsBuilder {
  public static BuildClaims(userInfoDto: UserInfoDto) {
    const claims: string[] = [];

    userInfoDto.roles?.forEach(role => {
      const rolesToClaims = roles[role as keyof typeof roles];
      claims.push(...rolesToClaims);
    });

    return uniq(claims);
  }
}
