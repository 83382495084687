import { TeamsConst } from 'constants/Base';
import {
  MasterCalendarApi,
  MasterCalendarApiActivateTaskRequest,
  MasterCalendarApiDeactivateTaskRequest,
  MasterCalendarApiDragAndDropTaskRequest,
  MasterCalendarApiGetTasksRequest,
} from 'teams-openapi';
import { BaseService } from './BaseService';

export class TaskService {
  private _baseInstance: BaseService;
  private static _instance: TaskService;
  private api: MasterCalendarApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getTasks(filters: MasterCalendarApiGetTasksRequest) {
    return this.api.getTasks(filters);
  }

  public activateTask(filters: MasterCalendarApiActivateTaskRequest) {
    return this.api.activateTask(filters);
  }

  public deactivateTask(filters: MasterCalendarApiDeactivateTaskRequest) {
    return this.api.deactivateTask(filters);
  }

  public updateTask(filters: MasterCalendarApiDragAndDropTaskRequest) {
    return this.api.dragAndDropTask(filters);
  }
}
