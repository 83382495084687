import { addMonths, format } from 'date-fns';
import { TeamsConst } from '../../constants/Base';
import { CheckInOutFilters } from '../../redux/slices/FilterSlice';
import { CheckInOutApi, CheckInOutApiCheckInOutRequest } from '../../teams-openapi';
import { FORMAT_YYYY_MM__DD } from '../../utils/DateUtils';
import { BaseService } from './../BaseService';

export class CheckInOutReportService {
  private _baseInstance: BaseService;
  private static _instance: CheckInOutReportService;
  private api: CheckInOutApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new CheckInOutApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public runCheckInReport(filters: CheckInOutFilters) {
    const req = this.getCheckInOutRequest(filters, 'checkIn');
    return this.api.checkInOut(req);
  }

  public runCheckOutReport(filters: CheckInOutFilters) {
    const req = this.getCheckInOutRequest(filters, 'checkOut');
    return this.api.checkInOut(req);
  }

  public async runCheckInOutReportCsv(filters: CheckInOutFilters) {
    const req = this.getCheckInOutRequest(filters);
    const promise = this.api.checkInOutExportCsv(req);
    return promise;
  }

  public async runCheckInOutReportPdf(filters: CheckInOutFilters) {
    const req = this.getCheckInOutRequest(filters);
    const promise = this.api.checkInOutExportPdf(req, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/pdf' },
    });
    return promise;
  }

  private getCheckInOutRequest(filters: CheckInOutFilters, checkInOrOut?: 'checkIn' | 'checkOut') {
    const fromDateAsString =
      filters.dateRange && filters.dateRange[0] !== null
        ? format(new Date(filters.dateRange[0]), FORMAT_YYYY_MM__DD)
        : format(new Date(), FORMAT_YYYY_MM__DD);

    const toDateAsString =
      filters.dateRange && filters.dateRange[1] !== null
        ? format(new Date(filters.dateRange[1]), FORMAT_YYYY_MM__DD)
        : format(addMonths(new Date(), 1), FORMAT_YYYY_MM__DD);

    const regionGroupIds = filters.regionGroupRefs.map(rgr => rgr.id);
    const regionIds = filters.regionRefs.map(r => r.id);
    const unitIds =
      filters.propertyRefs?.length > 0 ? filters.propertyRefs.map(r => r.id) : undefined;

    const req: CheckInOutApiCheckInOutRequest = {
      customerId: this._baseInstance.CustomerId,
      checkInOrOut: checkInOrOut,
      fromDate: fromDateAsString,
      toDate: toDateAsString,
      regionGroupIds: regionGroupIds,
      regionIds: regionIds,
      unitIds: unitIds,
      backToBack: filters.backToBack,
    };
    return req;
  }
}
