// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  PropertyTagApi,
  PropertyTagApiCreatePropertyTagRequest,
  PropertyTagApiDeletePropertyTagRequest,
  PropertyTagApiFindPropertyTagsRequest,
  PropertyTagApiGetPropertyTagRequest,
  PropertyTagApiUpdatePropertyTagLabelRequest,
} from '../teams-openapi';
import { BaseService } from './BaseService';

export class PropertyTagService {
  private _baseInstance: BaseService;
  private static _instance: PropertyTagService;
  private api: PropertyTagApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new PropertyTagApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: PropertyTagApiFindPropertyTagsRequest = {
      customerId: this._baseInstance.CustomerId,
      labelStartsWith: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findPropertyTags(req);
  }

  public getById(id: number, version: number) {
    const req: PropertyTagApiGetPropertyTagRequest = {
      id: id,
    };
    return this.api.getPropertyTag(req);
  }

  public create(label: string) {
    const req: PropertyTagApiCreatePropertyTagRequest = {
      createPropertyTagCommandDto: {
        customer: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createPropertyTag(req);
  }

  public delete(id, version) {
    const req: PropertyTagApiDeletePropertyTagRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deletePropertyTag(req);
  }

  public updateLabel(id, label, version) {
    const req: PropertyTagApiUpdatePropertyTagLabelRequest = {
      id: id,
      ifMatch: version,
      updatePropertyTagLabelCommandDto: { label: label },
    };
    return this.api.updatePropertyTagLabel(req);
  }
}
