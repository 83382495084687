import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import rootReducer from './RootReducer';

// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'bottomSheet/toggle/openBottomSheet',
          'bottomSheet/toggle/closeBottomSheet',
        ],
        ignoredPaths: [
          'bottomSheetReducer.children',
          'bottomSheetReducer.titleStartComponent',
          'bottomSheetReducer.titleEndComponent',
        ],
      },
    }),
});

export const persistor = persistStore(store);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const dispatchStoreReset = () => {
  store.dispatch({ type: 'reset' });
};
