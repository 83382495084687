import Box from '@mui/material/Box';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { ThemeProvider, useMedia } from '@operto/ui';
import { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import BottomSheet from './Commons/BottomSheet/BottomSheet';
import { SkeletonBase } from './Commons/Skeleton/SkeletonPageList';
import { TeamsConst } from './constants/Base';
import { BEACON_HELPSCOUT_IDS } from './constants/Constants';
import { useTimeZones } from './hooks/timeZones';
import { useAuthentication } from './hooks/useAuthentication';
import { useAnalytics } from './libs/analytics';
import { LOGIN_STATE } from './models/UserState';
import { initBeacon, prefillBeacon, suggestBeacon } from './utils/HelpBeaconUtils';

LicenseInfo.setLicenseKey(TeamsConst.dataGridProLicense);

enum CurrentAuthState {
  UNKNOWN = 'UNKNOWN',
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

declare global {
  interface Window {
    Beacon: any;
  }
}

const Dashboard = lazy(() => import('pages/Clients/Dashboard'));
const Login = lazy(() => import('pages/Frontend/Login/Login'));

function App() {
  useAnalytics();

  const { userState, isUserLoggedIn, loadUser, loadUserInfo, checkToken } = useAuthentication();
  const [currentAuthState, setCurrentAuthState] = useState<CurrentAuthState>(
    CurrentAuthState.UNKNOWN,
  );
  const { getAllTimeZones } = useTimeZones();

  const { isDesktop } = useMedia();

  useEffect(() => {
    (async () => {
      await loadUser();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isUserLoggedIn() && userState.CurrentCid) {
        await loadUserInfo(userState.CurrentCid);
        await getAllTimeZones();
      }
    })();
  }, [userState.LoginState]);

  useEffect(() => {
    if (userState && userState.UserInfoDto && userState.LoginState !== LOGIN_STATE.NOT_LOADED)
      if (isUserLoggedIn()) {
        setCurrentAuthState(CurrentAuthState.AUTHENTICATED);
        checkToken();
      } else {
        setCurrentAuthState(CurrentAuthState.NOT_AUTHENTICATED);
      }
  }, [userState?.LoginState, userState?.UserInfoState]);

  useEffect(() => {
    if (document.readyState === 'complete' && isDesktop) {
      initBeacon(TeamsConst.beaconKey);
      prefillBeacon({
        name: userState?.UserInfoDto?.name,
        email: userState?.UserInfoDto?.email,
      });
      suggestBeacon(BEACON_HELPSCOUT_IDS.GENERAL_HELP);
    }
  }, [userState, isDesktop]);

  return (
    <ThemeProvider>
      <Suspense
        fallback={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100vh',
              width: '100%',
            }}
          >
            <SkeletonBase />
          </Box>
        }
      >
        {currentAuthState === CurrentAuthState.AUTHENTICATED && <Dashboard />}
        {currentAuthState === CurrentAuthState.NOT_AUTHENTICATED && <Login />}
      </Suspense>
      <BottomSheet />
    </ThemeProvider>
  );
}

export default App;
