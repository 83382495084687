// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyService } from '../../services/PropertyService';
import {
  CommandResultDto,
  PropertyApiFindPropertiesRequest,
  PropertyDto,
  PropertyReferenceDto,
} from '../../teams-openapi';
import { translateError } from '../../utils/ErrorManagement';

export interface PropertyState {
  all: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getProperties = createAsyncThunk(
  'property/find',
  async (filters: PropertyApiFindPropertiesRequest = {}, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.getAll(filters);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);
export const getPropertiesByRegionIds = createAsyncThunk(
  'property/find',
  async (regiondIds: number[], { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.getByRegionIds(regiondIds);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getPropertiesByRegionId = createAsyncThunk(
  'property/find',
  async (regiondId: number, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.getByRegionId(regiondId);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const archiveProperty = createAsyncThunk(
  'property/archive',
  async (property: PropertyDto, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.archive(property.id, property.version);
      const updated = await PropertyService.Instance.getById(res.data.id, res.data.version);
      return updated;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const activateProperty = createAsyncThunk(
  'property/activate',
  async (property: PropertyDto, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.activateProperty(property.id, property.version);
      const updated = await PropertyService.Instance.getById(res.data.id, res.data.version);
      return updated;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deactivateProperty = createAsyncThunk(
  'property/deactivate',
  async (property: PropertyDto, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.deactivateProperty(property.id, property.version);
      const updated = await PropertyService.Instance.getById(res.data.id, res.data.version);
      return updated;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const restoreProperty = createAsyncThunk(
  'property/restore',
  async (property: PropertyDto, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.restoreProperty(property.id, property.version);
      const updated = await PropertyService.Instance.getById(res.data.id, res.data.version);
      return updated;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deactivateProperties = createAsyncThunk(
  'property/bulk/deactivate',
  async (properties: PropertyDto[], { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.deactivateProperties(
        getPropertyReferences(properties),
      );
      return getReplacements(res.data);
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const activateProperties = createAsyncThunk(
  'property/bulk/activate',
  async (properties: PropertyDto[], { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.activateProperties(
        getPropertyReferences(properties),
      );
      return getReplacements(res.data);
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const archiveProperties = createAsyncThunk(
  'property/bulk/archive',
  async (properties: PropertyDto[], { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.archiveProperties(
        getPropertyReferences(properties),
      );
      return getReplacements(res.data);
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const restoreProperties = createAsyncThunk(
  'property/bulk/restore',
  async (properties: PropertyDto[], { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.restoreProperties(
        getPropertyReferences(properties),
      );
      return getReplacements(res.data);
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createProperty = createAsyncThunk(
  'property/create',
  async (property: CreatePropertyCommandDto, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.create(property);
      const latest = await PropertyService.Instance.getById(res.data['id'], res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateProperty = createAsyncThunk(
  'property/update',
  async (property: UpdatePropertyInfoCommandDto, { rejectWithValue }) => {
    try {
      const { id } = property;
      const res = await PropertyService.Instance.update(id, property);
      const latest = await PropertyService.Instance.getById(id, res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

const getPropertyReferences = (properties: PropertyDto[]): PropertyReferenceDto[] => {
  const applyTo = properties.map(x => {
    return {
      propertyId: x.id,
      expectedVersion: x.version,
    };
  }) as PropertyReferenceDto[];
  return applyTo;
};

const getReplacements = async (items: CommandResultDto[]): Promise<PropertyDto[]> => {
  const updates = [] as PropertyDto[];
  for (const cmdResult of items) {
    const updated = await PropertyService.Instance.getById(cmdResult.id, cmdResult.version);
    updates.push(updated.data);
  }
  return updates;
};

const replaceExisting = (state: PropertyState, items: PropertyDto[]) => {
  for (const updated of items) {
    const oldIndex = state.all.findIndex(x => x.id == updated.id);
    state.all[oldIndex] = updated;
  }
};

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyState>) => {
  // --------------- GET Properties ---------------
  builder.addCase(getProperties.pending, (state: PropertyState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getProperties.fulfilled, (state: PropertyState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.name.localeCompare(b.name));
    state.all = data;
  });
  builder.addCase(getProperties.rejected, (state: PropertyState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Activate Property ---------------
  builder.addCase(activateProperty.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(activateProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const data = action.payload.data;
    const oldIndex = state.all.findIndex(x => x.id == data.id);
    state.all[oldIndex] = data;
  });
  builder.addCase(activateProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Deactivate Property ---------------
  builder.addCase(deactivateProperty.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(deactivateProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const data = action.payload.data;
    const oldIndex = state.all.findIndex(x => x.id == data.id);
    state.all[oldIndex] = data;
  });
  builder.addCase(deactivateProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Archive Property ---------------
  builder.addCase(archiveProperty.pending, (state: PropertyState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(archiveProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const data = action.payload.data;
    const oldIndex = state.all.findIndex(x => x.id == data.id);
    state.all[oldIndex] = data;
  });
  builder.addCase(archiveProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Restore Property ---------------
  builder.addCase(restoreProperty.pending, (state: PropertyState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(restoreProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const data = action.payload.data;
    const oldIndex = state.all.findIndex(x => x.id == data.id);
    state.all[oldIndex] = data;
  });
  builder.addCase(restoreProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- BULK ACTIONS ------------------------
  // --------------- Deactivate Properties ---------------
  builder.addCase(deactivateProperties.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(deactivateProperties.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    replaceExisting(state, action.payload);
  });
  builder.addCase(deactivateProperties.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Activate Properties ---------------
  builder.addCase(activateProperties.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(activateProperties.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    replaceExisting(state, action.payload);
  });
  builder.addCase(activateProperties.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Archive Properties ---------------
  builder.addCase(archiveProperties.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(archiveProperties.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    replaceExisting(state, action.payload);
  });
  builder.addCase(archiveProperties.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Restore Properties ---------------
  builder.addCase(restoreProperties.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(restoreProperties.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    replaceExisting(state, action.payload);
  });
  builder.addCase(restoreProperties.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Create Properties ---------------
  builder.addCase(createProperty.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(createProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
  });
  builder.addCase(createProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- Update Properties ---------------
  builder.addCase(updateProperty.pending, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateProperty.fulfilled, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
  });
  builder.addCase(updateProperty.rejected, (state: PropertyState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    state.all = [];
  });
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
      state.currentItem = null;
    },
    setPropertyToEdit: (state, action) => {
      state.currentItem = action.payload;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = propertySlice;
export const { resetCreateStatus, setPropertyToEdit } = propertySlice.actions;
export default reducer;
