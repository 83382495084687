import { TeamsConst } from '../constants/Base';
import { OwnerApi, OwnerApiFindOwnersRequest, OwnerApiGetOwnerRequest } from '../teams-openapi';
import { BaseService } from './BaseService';

export class OwnerService {
  private _baseInstance: BaseService;
  private static _instance: OwnerService;
  private api: OwnerApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new OwnerApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: OwnerApiFindOwnersRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findOwners(req);
  }

  public getById(id: number, version: number) {
    const req: OwnerApiGetOwnerRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.getOwner(req);
  }
}
