/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck

type apiState = {
  roles: object;
};

export default function roleAPIReducer(state: apiState = {}, action) {
  const { type, payload } = action;

  if (type.startsWith('SUCCESS_READ_GET_ROLES')) {
    state.roles = payload;

    return {
      ...state,
    };
  }
  return state;
}
