// @ts-nocheck - TODO: fix typescript errors
export default function otherReducer(state = { control: 'button' }, action) {
  const { type, data } = action;

  if (type.startsWith('ICON_CHANGE')) {
    return {
      ...state,
      control: data,
    };
  }

  return state;
}
