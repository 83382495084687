/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { QueryResultDepartureDto } from '../models';
/**
 * DepartureApi - axios parameter creator
 * @export
 */
export const DepartureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Departure Report
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departure: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/departure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Departure Report Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportCsv: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/departure/exportCSV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Departure Report Html Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportHtml: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/departure/exportHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Departure Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportPdf: async (customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/departure/exportPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (taskDuringStay !== undefined) {
                localVarQueryParameter['taskDuringStay'] = taskDuringStay;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartureApi - functional programming interface
 * @export
 */
export const DepartureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Departure Report
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departure(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultDepartureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departure(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartureApi.departure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Departure Report Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departureExportCsv(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departureExportCsv(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartureApi.departureExportCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Departure Report Html Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departureExportHtml(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departureExportHtml(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartureApi.departureExportHtml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Departure Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [taskDuringStay] 
         * @param {string} [keyword] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departureExportPdf(customerId?: number, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, taskDuringStay?: boolean, keyword?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departureExportPdf(customerId, fromDate, toDate, regionGroupIds, regionIds, unitIds, taskDuringStay, keyword, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartureApi.departureExportPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DepartureApi - factory interface
 * @export
 */
export const DepartureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartureApiFp(configuration)
    return {
        /**
         * 
         * @summary Departure Report
         * @param {DepartureApiDepartureRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departure(requestParameters: DepartureApiDepartureRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultDepartureDto> {
            return localVarFp.departure(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Departure Report Export
         * @param {DepartureApiDepartureExportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportCsv(requestParameters: DepartureApiDepartureExportCsvRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.departureExportCsv(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Departure Report Html Export
         * @param {DepartureApiDepartureExportHtmlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportHtml(requestParameters: DepartureApiDepartureExportHtmlRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.departureExportHtml(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Departure Report PDF Export
         * @param {DepartureApiDepartureExportPdfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departureExportPdf(requestParameters: DepartureApiDepartureExportPdfRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.departureExportPdf(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for departure operation in DepartureApi.
 * @export
 * @interface DepartureApiDepartureRequest
 */
export interface DepartureApiDepartureRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartureApiDeparture
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDeparture
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDeparture
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDeparture
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDeparture
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDeparture
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof DepartureApiDeparture
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDeparture
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDeparture
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDeparture
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DepartureApiDeparture
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for departureExportCsv operation in DepartureApi.
 * @export
 * @interface DepartureApiDepartureExportCsvRequest
 */
export interface DepartureApiDepartureExportCsvRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DepartureApiDepartureExportCsv
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for departureExportHtml operation in DepartureApi.
 * @export
 * @interface DepartureApiDepartureExportHtmlRequest
 */
export interface DepartureApiDepartureExportHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DepartureApiDepartureExportHtml
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for departureExportPdf operation in DepartureApi.
 * @export
 * @interface DepartureApiDepartureExportPdfRequest
 */
export interface DepartureApiDepartureExportPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly taskDuringStay?: boolean

    /**
     * 
     * @type {string}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DepartureApiDepartureExportPdf
     */
    readonly sort?: Array<string>
}

/**
 * DepartureApi - object-oriented interface
 * @export
 * @class DepartureApi
 * @extends {BaseAPI}
 */
export class DepartureApi extends BaseAPI {
    /**
     * 
     * @summary Departure Report
     * @param {DepartureApiDepartureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartureApi
     */
    public departure(requestParameters: DepartureApiDepartureRequest = {}, options?: AxiosRequestConfig) {
        return DepartureApiFp(this.configuration).departure(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Departure Report Export
     * @param {DepartureApiDepartureExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartureApi
     */
    public departureExportCsv(requestParameters: DepartureApiDepartureExportCsvRequest = {}, options?: AxiosRequestConfig) {
        return DepartureApiFp(this.configuration).departureExportCsv(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Departure Report Html Export
     * @param {DepartureApiDepartureExportHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartureApi
     */
    public departureExportHtml(requestParameters: DepartureApiDepartureExportHtmlRequest = {}, options?: AxiosRequestConfig) {
        return DepartureApiFp(this.configuration).departureExportHtml(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Departure Report PDF Export
     * @param {DepartureApiDepartureExportPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartureApi
     */
    public departureExportPdf(requestParameters: DepartureApiDepartureExportPdfRequest = {}, options?: AxiosRequestConfig) {
        return DepartureApiFp(this.configuration).departureExportPdf(requestParameters.customerId, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.taskDuringStay, requestParameters.keyword, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

