/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { QueryResultCheckInOutDto } from '../models';
/**
 * CheckInOutApi - axios parameter creator
 * @export
 */
export const CheckInOutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CheckInOut Report
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOut: async (customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/checkinout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (checkInOrOut !== undefined) {
                localVarQueryParameter['checkInOrOut'] = checkInOrOut;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (backToBack !== undefined) {
                localVarQueryParameter['backToBack'] = backToBack;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CheckInOut Report Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportCsv: async (customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/checkinout/exportCSV`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (checkInOrOut !== undefined) {
                localVarQueryParameter['checkInOrOut'] = checkInOrOut;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (backToBack !== undefined) {
                localVarQueryParameter['backToBack'] = backToBack;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CheckInOut Report Html Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportHtml: async (customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/checkinout/exportHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (checkInOrOut !== undefined) {
                localVarQueryParameter['checkInOrOut'] = checkInOrOut;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (backToBack !== undefined) {
                localVarQueryParameter['backToBack'] = backToBack;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CheckInOut Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportPdf: async (customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/checkinout/exportPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (checkInOrOut !== undefined) {
                localVarQueryParameter['checkInOrOut'] = checkInOrOut;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substring(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substring(0,10) :
                    toDate;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (unitIds) {
                localVarQueryParameter['unitIds'] = unitIds;
            }

            if (backToBack !== undefined) {
                localVarQueryParameter['backToBack'] = backToBack;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckInOutApi - functional programming interface
 * @export
 */
export const CheckInOutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckInOutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CheckInOut Report
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInOut(customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultCheckInOutDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInOut(customerId, checkInOrOut, fromDate, toDate, regionGroupIds, regionIds, unitIds, backToBack, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CheckInOutApi.checkInOut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary CheckInOut Report Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInOutExportCsv(customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInOutExportCsv(customerId, checkInOrOut, fromDate, toDate, regionGroupIds, regionIds, unitIds, backToBack, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CheckInOutApi.checkInOutExportCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary CheckInOut Report Html Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInOutExportHtml(customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInOutExportHtml(customerId, checkInOrOut, fromDate, toDate, regionGroupIds, regionIds, unitIds, backToBack, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CheckInOutApi.checkInOutExportHtml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary CheckInOut Report PDF Export
         * @param {number} [customerId] 
         * @param {string} [checkInOrOut] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [unitIds] 
         * @param {boolean} [backToBack] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInOutExportPdf(customerId?: number, checkInOrOut?: string, fromDate?: string, toDate?: string, regionGroupIds?: Array<number>, regionIds?: Array<number>, unitIds?: Array<number>, backToBack?: boolean, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInOutExportPdf(customerId, checkInOrOut, fromDate, toDate, regionGroupIds, regionIds, unitIds, backToBack, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CheckInOutApi.checkInOutExportPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CheckInOutApi - factory interface
 * @export
 */
export const CheckInOutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckInOutApiFp(configuration)
    return {
        /**
         * 
         * @summary CheckInOut Report
         * @param {CheckInOutApiCheckInOutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOut(requestParameters: CheckInOutApiCheckInOutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultCheckInOutDto> {
            return localVarFp.checkInOut(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CheckInOut Report Export
         * @param {CheckInOutApiCheckInOutExportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportCsv(requestParameters: CheckInOutApiCheckInOutExportCsvRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.checkInOutExportCsv(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CheckInOut Report Html Export
         * @param {CheckInOutApiCheckInOutExportHtmlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportHtml(requestParameters: CheckInOutApiCheckInOutExportHtmlRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.checkInOutExportHtml(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CheckInOut Report PDF Export
         * @param {CheckInOutApiCheckInOutExportPdfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInOutExportPdf(requestParameters: CheckInOutApiCheckInOutExportPdfRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.checkInOutExportPdf(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkInOut operation in CheckInOutApi.
 * @export
 * @interface CheckInOutApiCheckInOutRequest
 */
export interface CheckInOutApiCheckInOutRequest {
    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly checkInOrOut?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly backToBack?: boolean

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CheckInOutApiCheckInOut
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for checkInOutExportCsv operation in CheckInOutApi.
 * @export
 * @interface CheckInOutApiCheckInOutExportCsvRequest
 */
export interface CheckInOutApiCheckInOutExportCsvRequest {
    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly checkInOrOut?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly backToBack?: boolean

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CheckInOutApiCheckInOutExportCsv
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for checkInOutExportHtml operation in CheckInOutApi.
 * @export
 * @interface CheckInOutApiCheckInOutExportHtmlRequest
 */
export interface CheckInOutApiCheckInOutExportHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly checkInOrOut?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly backToBack?: boolean

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CheckInOutApiCheckInOutExportHtml
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for checkInOutExportPdf operation in CheckInOutApi.
 * @export
 * @interface CheckInOutApiCheckInOutExportPdfRequest
 */
export interface CheckInOutApiCheckInOutExportPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly checkInOrOut?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly toDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly unitIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly backToBack?: boolean

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CheckInOutApiCheckInOutExportPdf
     */
    readonly sort?: Array<string>
}

/**
 * CheckInOutApi - object-oriented interface
 * @export
 * @class CheckInOutApi
 * @extends {BaseAPI}
 */
export class CheckInOutApi extends BaseAPI {
    /**
     * 
     * @summary CheckInOut Report
     * @param {CheckInOutApiCheckInOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOutApi
     */
    public checkInOut(requestParameters: CheckInOutApiCheckInOutRequest = {}, options?: AxiosRequestConfig) {
        return CheckInOutApiFp(this.configuration).checkInOut(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CheckInOut Report Export
     * @param {CheckInOutApiCheckInOutExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOutApi
     */
    public checkInOutExportCsv(requestParameters: CheckInOutApiCheckInOutExportCsvRequest = {}, options?: AxiosRequestConfig) {
        return CheckInOutApiFp(this.configuration).checkInOutExportCsv(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CheckInOut Report Html Export
     * @param {CheckInOutApiCheckInOutExportHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOutApi
     */
    public checkInOutExportHtml(requestParameters: CheckInOutApiCheckInOutExportHtmlRequest = {}, options?: AxiosRequestConfig) {
        return CheckInOutApiFp(this.configuration).checkInOutExportHtml(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CheckInOut Report PDF Export
     * @param {CheckInOutApiCheckInOutExportPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckInOutApi
     */
    public checkInOutExportPdf(requestParameters: CheckInOutApiCheckInOutExportPdfRequest = {}, options?: AxiosRequestConfig) {
        return CheckInOutApiFp(this.configuration).checkInOutExportPdf(requestParameters.customerId, requestParameters.checkInOrOut, requestParameters.fromDate, requestParameters.toDate, requestParameters.regionGroupIds, requestParameters.regionIds, requestParameters.unitIds, requestParameters.backToBack, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

