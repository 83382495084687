// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  PropertyItemTypeApi,
  PropertyItemTypeApiCreatePropertyItemTypeRequest,
  PropertyItemTypeApiDeletePropertyItemTypeRequest,
  PropertyItemTypeApiFindPropertyItemTypeRequest,
  PropertyItemTypeApiGetPropertyItemTypeRequest,
  PropertyItemTypeApiMovePropertyItemTypeRequest,
  PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest,
} from '../teams-openapi';
import { BaseService } from './BaseService';

export class PropertyItemTypeService {
  private _baseInstance: BaseService;
  private static _instance: PropertyItemTypeService;
  private api: PropertyItemTypeApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new PropertyItemTypeApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: PropertyItemTypeApiFindPropertyItemTypeRequest = {
      customerId: this._baseInstance.CustomerId,
      labelStartsWith: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findPropertyItemType(req);
  }

  public getAllRefs() {
    const req: PropertyItemTypeApiFindPropertyItemTypeRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findPropertyItemType(req);
  }

  public getById(id: number, version: number) {
    const req: PropertyItemTypeApiGetPropertyItemTypeRequest = {
      id: id,
    };
    return this.api.getPropertyItemType(req);
  }

  public create(label: string) {
    const req: PropertyItemTypeApiCreatePropertyItemTypeRequest = {
      createPropertyItemTypeCommandDto: {
        customer: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createPropertyItemType(req);
  }

  public delete(id, version) {
    const req: PropertyItemTypeApiDeletePropertyItemTypeRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deletePropertyItemType(req);
  }

  public updateLabel(id, label, version) {
    const req: PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest = {
      id: id,
      ifMatch: version,
      updatePropertyItemTypeLabelCommandDto: { label: label },
    };
    return this.api.updatePropertyItemTypeLabel(req);
  }

  public updateSortOrder(id, sortIndex, version) {
    const req: PropertyItemTypeApiMovePropertyItemTypeRequest = {
      id: id,
      ifMatch: version,
      movePropertyItemTypeCommandDto: { moveToSortOrder: sortIndex },
    };
    return this.api.movePropertyItemType(req);
  }
}
