import { TeamsConst } from '../constants/Base';
import { TimeZoneApi } from '../teams-openapi';
import { BaseService } from './BaseService';

export class TimeZoneService {
  private _baseInstance: BaseService;
  private static _instance: TimeZoneService;
  private api: TimeZoneApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new TimeZoneApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    return this.api.findTimeZones();
  }
}
