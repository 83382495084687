// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  RegionGroupApi,
  RegionGroupApiCreateRegionGroupRequest,
  RegionGroupApiDeleteRegionGroupRequest,
  RegionGroupApiFindRegionGroupsRequest,
  RegionGroupApiGetRegionGroupRequest,
  RegionGroupApiMoveRegionGroupRequest,
  RegionGroupApiUpdateRegionGroupLabelRequest,
} from '../teams-openapi';
import { BaseService } from './BaseService';

export class RegionGroupService {
  private _baseInstance: BaseService;
  private static _instance: RegionGroupService;
  private api: RegionGroupApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new RegionGroupApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: RegionGroupApiFindRegionGroupsRequest = {
      customerId: this._baseInstance.CustomerId,
      labelStartsWith: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findRegionGroups(req);
  }

  public getAllRefs() {
    const req: RegionGroupApiFindRegionGroupsRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findRegionGroups(req);
  }

  public getById(id: number, version: number) {
    const req: RegionGroupApiGetRegionGroupRequest = {
      id: id,
    };
    return this.api.getRegionGroup(req);
  }

  public create(label: string) {
    const req: RegionGroupApiCreateRegionGroupRequest = {
      createRegionGroupCommandDto: {
        customer: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createRegionGroup(req);
  }

  public delete(id, version) {
    const req: RegionGroupApiDeleteRegionGroupRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deleteRegionGroup(req);
  }

  public updateLabel(id, label, version) {
    const req: RegionGroupApiUpdateRegionGroupLabelRequest = {
      id: id,
      ifMatch: version,
      updateRegionGroupLabelCommandDto: { label: label },
    };
    return this.api.updateRegionGroupLabel(req);
  }

  public updateSortOrder(id, sortIndex, version) {
    const req: RegionGroupApiMoveRegionGroupRequest = {
      id: id,
      ifMatch: version,
      moveRegionGroupCommandDto: { moveToSortOrder: sortIndex },
    };
    return this.api.moveRegionGroup(req);
  }
}
