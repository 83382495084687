/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck

type apiState = {
  preferenceSave: object;
};
export default function preferenceSaveReducer(state: apiState = {}, action) {
  const { type, params } = action;

  if (type.startsWith('SAVE_PREFERENCES')) {
    // console.log("Reducing preference");
    // let preferencesInStorage = JSON.parse(localStorage.getItem("preferences"));
    // if (!preferencesInStorage) {
    //   preferencesInStorage = []
    // }
    // preferencesInStorage.push(params.body)
    // localStorage.setItem("preferences", JSON.stringify(preferencesInStorage));

    return {
      ...state,
    };
  }
  return state;
}
