// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { TimeZoneService } from '../../services/TimezoneService';
import { TimeZoneDto } from '../../teams-openapi';
import { translateError } from '../../utils/ErrorManagement';

export interface TimeZoneState {
  all: TimeZoneDto[];
  fetchAllStatus: API_STATUS;
}

const initialState: TimeZoneState = {
  all: [],
  fetchAllStatus: API_STATUS.IDLE,
};

export const getTimeZones = createAsyncThunk(
  'timezones/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await TimeZoneService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<TimeZoneState>) => {
  // --------------- GET TIME ZONES ---------------
  builder.addCase(getTimeZones.pending, (state: TimeZoneState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getTimeZones.fulfilled, (state: TimeZoneState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.all = action.payload.data;
  });
  builder.addCase(getTimeZones.rejected, (state: TimeZoneState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });
};

const regionSlice = createSlice({
  name: 'timezones',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = regionSlice;
export default reducer;
