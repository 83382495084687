import { Box } from '@mui/material';
import { BottomSheet as BottomSheetOperto } from '@operto/ui';
import { useBottomSheet } from 'hooks/bottomSheet';

export default function BottomSheet() {
  const { bottomSheetState, closeBottomSheetHook } = useBottomSheet();

  return bottomSheetState.children ? (
    <BottomSheetOperto
      open={!!bottomSheetState.children}
      onClose={() => closeBottomSheetHook()}
      title={bottomSheetState.title}
      titleStartComponent={bottomSheetState.titleStartComponent}
      titleEndComponent={bottomSheetState.titleEndComponent}
      titleSx={bottomSheetState.titleSx}
      titleVariant={bottomSheetState.titleVariant}
      stackSx={bottomSheetState.stackSx}
      paperSx={{
        sx: {
          backgroundColor: 'white',
          maxHeight: 'calc(100vh - 16px)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
        ...bottomSheetState.paperSx,
      }}
      rootSx={bottomSheetState.rootSx}
    >
      <Box width='100%' height='100%' data-testid='bottom-sheet-test'>
        {bottomSheetState.children}
      </Box>
    </BottomSheetOperto>
  ) : null;
}
