/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { LoginRequest } from '../models';
// @ts-ignore
import { UserInfoDto } from '../models';
// @ts-ignore
import { UserJwtDto } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cfTokenLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/cfwToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cfTokenLogin1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/login/cfwToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (cid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cid' is not null or undefined
            assertParamExists('getUserInfo', 'cid', cid)
            const localVarPath = `/v1/login/userinfo/{cid}`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo1: async (cid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cid' is not null or undefined
            assertParamExists('getUserInfo1', 'cid', cid)
            const localVarPath = `/v1/auth/userinfo/{cid}`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('postLogin', 'loginRequest', loginRequest)
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin1: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('postLogin1', 'loginRequest', loginRequest)
            const localVarPath = `/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cfTokenLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cfTokenLogin(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.cfTokenLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cfTokenLogin1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cfTokenLogin1(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.cfTokenLogin1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(cid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(cid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.getUserInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo1(cid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo1(cid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.getUserInfo1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogin(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogin(loginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.postLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogin1(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogin1(loginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.postLogin1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cfTokenLogin(options?: AxiosRequestConfig): AxiosPromise<UserJwtDto> {
            return localVarFp.cfTokenLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cfTokenLogin1(options?: AxiosRequestConfig): AxiosPromise<UserJwtDto> {
            return localVarFp.cfTokenLogin1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationApiGetUserInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(requestParameters: AuthenticationApiGetUserInfoRequest, options?: AxiosRequestConfig): AxiosPromise<UserInfoDto> {
            return localVarFp.getUserInfo(requestParameters.cid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationApiGetUserInfo1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo1(requestParameters: AuthenticationApiGetUserInfo1Request, options?: AxiosRequestConfig): AxiosPromise<UserInfoDto> {
            return localVarFp.getUserInfo1(requestParameters.cid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {AuthenticationApiPostLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin(requestParameters: AuthenticationApiPostLoginRequest, options?: AxiosRequestConfig): AxiosPromise<UserJwtDto> {
            return localVarFp.postLogin(requestParameters.loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login via Email and Password
         * @param {AuthenticationApiPostLogin1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin1(requestParameters: AuthenticationApiPostLogin1Request, options?: AxiosRequestConfig): AxiosPromise<UserJwtDto> {
            return localVarFp.postLogin1(requestParameters.loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUserInfo operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiGetUserInfoRequest
 */
export interface AuthenticationApiGetUserInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationApiGetUserInfo
     */
    readonly cid: number
}

/**
 * Request parameters for getUserInfo1 operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiGetUserInfo1Request
 */
export interface AuthenticationApiGetUserInfo1Request {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationApiGetUserInfo1
     */
    readonly cid: number
}

/**
 * Request parameters for postLogin operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiPostLoginRequest
 */
export interface AuthenticationApiPostLoginRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof AuthenticationApiPostLogin
     */
    readonly loginRequest: LoginRequest
}

/**
 * Request parameters for postLogin1 operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiPostLogin1Request
 */
export interface AuthenticationApiPostLogin1Request {
    /**
     * 
     * @type {LoginRequest}
     * @memberof AuthenticationApiPostLogin1
     */
    readonly loginRequest: LoginRequest
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Login via Email and Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public cfTokenLogin(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).cfTokenLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login via Email and Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public cfTokenLogin1(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).cfTokenLogin1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiGetUserInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getUserInfo(requestParameters: AuthenticationApiGetUserInfoRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getUserInfo(requestParameters.cid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiGetUserInfo1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getUserInfo1(requestParameters: AuthenticationApiGetUserInfo1Request, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getUserInfo1(requestParameters.cid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login via Email and Password
     * @param {AuthenticationApiPostLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postLogin(requestParameters: AuthenticationApiPostLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postLogin(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login via Email and Password
     * @param {AuthenticationApiPostLogin1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postLogin1(requestParameters: AuthenticationApiPostLogin1Request, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postLogin1(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

