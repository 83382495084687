import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Grid, Paper, Stack, SxProps, Typography } from '@mui/material';
import { ThemeProvider, paletteStyles } from '@operto/ui';
import { t } from 'i18next';
import { OpertoLogoTeamsBlack2 } from '../../assets/icons';
import { useAuthentication } from '../../hooks/useAuthentication';

export default function ErrorFallback({ error }: IErrorFallbackProps) {
  const { userState } = useAuthentication();

  const CID = userState?.CurrentCid ?? '';
  const email = userState?.UserInfoDto?.email ?? '';

  return (
    <ThemeProvider>
      <Box sx={containerStyle}>
        <Paper sx={paperStyle}>
          <Stack sx={headerStyle}>
            <img src={OpertoLogoTeamsBlack2} />
            <Typography variant='h1-700'>{error.message}</Typography>
            <Typography variant='h2-700'>{t('error_boundary_header_message')}</Typography>
          </Stack>

          <Stack sx={bodyStyle}>
            <Typography variant='body-sm-400'>
              <div dangerouslySetInnerHTML={{ __html: t('error_boundary_body_message') }} />
            </Typography>
          </Stack>

          <Grid container sx={footerStyle} spacing={1}>
            <Grid item xs={12} md={6}>
              <Button
                data-testid='refresh-button'
                variant='outlined'
                color='primary'
                startIcon={<RefreshIcon />}
                onClick={() => window.location.reload()}
                sx={buttonStyle}
              >
                {t('error_boundary_button_try_again')}
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                data-testid='contact-support-button'
                variant='contained'
                color='primary'
                startIcon={<MailOutlineIcon />}
                sx={buttonStyle}
                href={`mailto:help-teams@operto.com?subject=[${error.message}] [${email}/CID: ${CID}]`}
              >
                {t('error_boundary_button_contact_support')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

interface IErrorFallbackProps {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}

const containerStyle: SxProps = {
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '80px',
  width: '100vw',
  height: '100vh',
  backgroundColor: paletteStyles.palette.background.default01,
  overflow: 'auto',
};

const paperStyle: SxProps = {
  display: 'flex',
  maxWidth: '500px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '10px',
  elevation: 1,
};

const headerStyle: SxProps = {
  gap: '8px',
  padding: '24px 24px 16px 24px',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  textAlign: 'center',
};

const bodyStyle: SxProps = {
  padding: '8px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};

const footerStyle: SxProps = {
  padding: '8px 24px 24px 24px',
};

const buttonStyle: SxProps = {
  textTransform: 'capitalize',
  width: '100%',
};
