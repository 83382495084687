//GR1 -- Do not remove comment
import { Theme } from '@mui/material';
import normal from './Normal';
import pastelCandy from './PastelCandy';

const themes: Record<string, Theme> = {
  //GR2 -- Do not remove comment
  normal,
  pastelCandy,
};

export default function getTheme(theme: string) {
  return themes[theme];
}
