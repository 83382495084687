// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from './../constants/Base';

export interface ErrorResponse {
  httpStatusCode: number;
  errorMessage: string;
  friendlyMessage: string;
  friendlyMessages: string[];
}

export const handleError = e => {
  if (e.response) {
    switch (e.response.status) {
      case TeamsConst.errorConst.UNAUTHORISED_ACCESS: {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          break;
        }
        window.location.replace(TeamsConst.coldFusionWebsiteUrl);
        break;
      }
      default:
        break;
    }
  }
};

export const translateError = (err: any): ErrorResponse => {
  let validationMessagesAsString = '';
  const validationMessages = [];
  const httpStatusCode = err.response.status;
  if (httpStatusCode === 422) {
    validationMessagesAsString = err.response.data.results
      .map(x =>
        x.code === 'DUPLICATE_VALUE'
          ? (x.translatedMessage = `${x.values[3]} already exists.`)
          : x.translatedMessage,
      )
      .join('. ');
    err.response.data.results.map(x => validationMessages.push(x.translatedMessage));
  }

  return {
    httpStatusCode: httpStatusCode,
    errorMessage: err.message,
    friendlyMessage: `${getFriendlyReasonForErrorCode(
      httpStatusCode,
    )} ${validationMessagesAsString}`,
    friendlyMessages: validationMessages,
  };
};

export const getFriendlyReasonForErrorCode = (httpStatusCode: number): string => {
  switch (httpStatusCode) {
    case 403:
      return 'You do not have access to this data.';
    case 409:
      return 'A conflict has occurred.';
    case 422:
      return 'Errors :';
    default:
      return '';
  }
};

export const handlePromise = (promise: any) => {
  return {
    originalPromise: promise,
    success: promise.meta.requestStatus === 'fulfilled' ? true : false,
    statusCode: promise.payload?.httpStatusCode,
    validationErrors: [],
  };
};
