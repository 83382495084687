import { TeamsConst } from '../constants/Base';
import { AccountApi } from '../teams-openapi';
import { BaseService } from './BaseService';

export class AccountService {
  private _baseInstance: BaseService;
  private static _instance: AccountService;
  private api: AccountApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new AccountApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public updateUseNewGui(useNewGui: boolean) {
    const req = {
      updateGuiPreferenceCommandDto: {
        cid: this._baseInstance.CustomerId,
        useNewGui: useNewGui,
      },
    };
    return this.api.updateGuiPreference(req, { withCredentials: true });
  }
}
