/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateMasterCalendarNoteCommandDto } from '../models';
// @ts-ignore
import { MasterCalendarNoteDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarNoteDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteEndDateCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteHoverNoteCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteLongDescriptionCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNotePropertyCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteShortNoteCommandDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteStartDateCommandDto } from '../models';
/**
 * MasterCalendarNoteApi - axios parameter creator
 * @export
 */
export const MasterCalendarNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Master Calendar Note Command
         * @param {CreateMasterCalendarNoteCommandDto} createMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMasterCalendarNote: async (createMasterCalendarNoteCommandDto: CreateMasterCalendarNoteCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMasterCalendarNoteCommandDto' is not null or undefined
            assertParamExists('createMasterCalendarNote', 'createMasterCalendarNoteCommandDto', createMasterCalendarNoteCommandDto)
            const localVarPath = `/v1/master-calendar-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMasterCalendarNoteCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterCalendarNote: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMasterCalendarNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteMasterCalendarNote', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMasterCalendarNotes: async (customerId?: number, labelStartsWith?: string, startDate?: string, endDate?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Master Calendar Note by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterCalendarNote: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMasterCalendarNote', 'id', id)
            const localVarPath = `/v1/master-calendar-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteCommandDto} updateMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNote: async (id: number, ifMatch: number, updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNote', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNote', 'updateMasterCalendarNoteCommandDto', updateMasterCalendarNoteCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note End Date Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteEndDateCommandDto} updateMasterCalendarNoteEndDateCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteEndDate: async (id: number, ifMatch: number, updateMasterCalendarNoteEndDateCommandDto: UpdateMasterCalendarNoteEndDateCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteEndDate', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteEndDate', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteEndDateCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteEndDate', 'updateMasterCalendarNoteEndDateCommandDto', updateMasterCalendarNoteEndDateCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-end-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteEndDateCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Hover Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteHoverNoteCommandDto} updateMasterCalendarNoteHoverNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteHoverNote: async (id: number, ifMatch: number, updateMasterCalendarNoteHoverNoteCommandDto: UpdateMasterCalendarNoteHoverNoteCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteHoverNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteHoverNote', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteHoverNoteCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteHoverNote', 'updateMasterCalendarNoteHoverNoteCommandDto', updateMasterCalendarNoteHoverNoteCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-hover-note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteHoverNoteCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Long Description Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteLongDescriptionCommandDto} updateMasterCalendarNoteLongDescriptionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteLongDescription: async (id: number, ifMatch: number, updateMasterCalendarNoteLongDescriptionCommandDto: UpdateMasterCalendarNoteLongDescriptionCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteLongDescription', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteLongDescription', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteLongDescriptionCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteLongDescription', 'updateMasterCalendarNoteLongDescriptionCommandDto', updateMasterCalendarNoteLongDescriptionCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-long-description`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteLongDescriptionCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNotePropertyCommandDto} updateMasterCalendarNotePropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteProperty: async (id: number, ifMatch: number, updateMasterCalendarNotePropertyCommandDto: UpdateMasterCalendarNotePropertyCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteProperty', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteProperty', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNotePropertyCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteProperty', 'updateMasterCalendarNotePropertyCommandDto', updateMasterCalendarNotePropertyCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-property`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNotePropertyCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Short Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteShortNoteCommandDto} updateMasterCalendarNoteShortNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteShortNote: async (id: number, ifMatch: number, updateMasterCalendarNoteShortNoteCommandDto: UpdateMasterCalendarNoteShortNoteCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteShortNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteShortNote', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteShortNoteCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteShortNote', 'updateMasterCalendarNoteShortNoteCommandDto', updateMasterCalendarNoteShortNoteCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-short-note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteShortNoteCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Start Date Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteStartDateCommandDto} updateMasterCalendarNoteStartDateCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteStartDate: async (id: number, ifMatch: number, updateMasterCalendarNoteStartDateCommandDto: UpdateMasterCalendarNoteStartDateCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMasterCalendarNoteStartDate', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateMasterCalendarNoteStartDate', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteStartDateCommandDto' is not null or undefined
            assertParamExists('updateMasterCalendarNoteStartDate', 'updateMasterCalendarNoteStartDateCommandDto', updateMasterCalendarNoteStartDateCommandDto)
            const localVarPath = `/v1/master-calendar-note/{id}/update-start-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteStartDateCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterCalendarNoteApi - functional programming interface
 * @export
 */
export const MasterCalendarNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterCalendarNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Master Calendar Note Command
         * @param {CreateMasterCalendarNoteCommandDto} createMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMasterCalendarNote(createMasterCalendarNoteCommandDto: CreateMasterCalendarNoteCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMasterCalendarNote(createMasterCalendarNoteCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.createMasterCalendarNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMasterCalendarNote(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMasterCalendarNote(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.deleteMasterCalendarNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMasterCalendarNotes(customerId?: number, labelStartsWith?: string, startDate?: string, endDate?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMasterCalendarNotes(customerId, labelStartsWith, startDate, endDate, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.findMasterCalendarNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Master Calendar Note by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterCalendarNote(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterCalendarNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterCalendarNote(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.getMasterCalendarNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteCommandDto} updateMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNote(id: number, ifMatch: number, updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNote(id, ifMatch, updateMasterCalendarNoteCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note End Date Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteEndDateCommandDto} updateMasterCalendarNoteEndDateCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteEndDate(id: number, ifMatch: number, updateMasterCalendarNoteEndDateCommandDto: UpdateMasterCalendarNoteEndDateCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteEndDate(id, ifMatch, updateMasterCalendarNoteEndDateCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteEndDate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Hover Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteHoverNoteCommandDto} updateMasterCalendarNoteHoverNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteHoverNote(id: number, ifMatch: number, updateMasterCalendarNoteHoverNoteCommandDto: UpdateMasterCalendarNoteHoverNoteCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteHoverNote(id, ifMatch, updateMasterCalendarNoteHoverNoteCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteHoverNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Long Description Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteLongDescriptionCommandDto} updateMasterCalendarNoteLongDescriptionCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteLongDescription(id: number, ifMatch: number, updateMasterCalendarNoteLongDescriptionCommandDto: UpdateMasterCalendarNoteLongDescriptionCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteLongDescription(id, ifMatch, updateMasterCalendarNoteLongDescriptionCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteLongDescription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Property Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNotePropertyCommandDto} updateMasterCalendarNotePropertyCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteProperty(id: number, ifMatch: number, updateMasterCalendarNotePropertyCommandDto: UpdateMasterCalendarNotePropertyCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteProperty(id, ifMatch, updateMasterCalendarNotePropertyCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Short Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteShortNoteCommandDto} updateMasterCalendarNoteShortNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteShortNote(id: number, ifMatch: number, updateMasterCalendarNoteShortNoteCommandDto: UpdateMasterCalendarNoteShortNoteCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteShortNote(id, ifMatch, updateMasterCalendarNoteShortNoteCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteShortNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Start Date Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteStartDateCommandDto} updateMasterCalendarNoteStartDateCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterCalendarNoteStartDate(id: number, ifMatch: number, updateMasterCalendarNoteStartDateCommandDto: UpdateMasterCalendarNoteStartDateCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasterCalendarNoteStartDate(id, ifMatch, updateMasterCalendarNoteStartDateCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarNoteApi.updateMasterCalendarNoteStartDate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MasterCalendarNoteApi - factory interface
 * @export
 */
export const MasterCalendarNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterCalendarNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Master Calendar Note Command
         * @param {MasterCalendarNoteApiCreateMasterCalendarNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMasterCalendarNote(requestParameters: MasterCalendarNoteApiCreateMasterCalendarNoteRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createMasterCalendarNote(requestParameters.createMasterCalendarNoteCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {MasterCalendarNoteApiDeleteMasterCalendarNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMasterCalendarNote(requestParameters: MasterCalendarNoteApiDeleteMasterCalendarNoteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {MasterCalendarNoteApiFindMasterCalendarNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMasterCalendarNotes(requestParameters: MasterCalendarNoteApiFindMasterCalendarNotesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarNoteDto> {
            return localVarFp.findMasterCalendarNotes(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.startDate, requestParameters.endDate, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Master Calendar Note by ID
         * @param {MasterCalendarNoteApiGetMasterCalendarNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterCalendarNote(requestParameters: MasterCalendarNoteApiGetMasterCalendarNoteRequest, options?: AxiosRequestConfig): AxiosPromise<MasterCalendarNoteDto> {
            return localVarFp.getMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note End Date Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteEndDate(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteEndDate(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteEndDateCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Hover Note Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteHoverNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteHoverNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteHoverNoteCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Long Description Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteLongDescription(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteLongDescription(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteLongDescriptionCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Property Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteProperty(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNotePropertyCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Short Note Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteShortNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteShortNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteShortNoteCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Start Date Command
         * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterCalendarNoteStartDate(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateMasterCalendarNoteStartDate(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteStartDateCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMasterCalendarNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiCreateMasterCalendarNoteRequest
 */
export interface MasterCalendarNoteApiCreateMasterCalendarNoteRequest {
    /**
     * 
     * @type {CreateMasterCalendarNoteCommandDto}
     * @memberof MasterCalendarNoteApiCreateMasterCalendarNote
     */
    readonly createMasterCalendarNoteCommandDto: CreateMasterCalendarNoteCommandDto
}

/**
 * Request parameters for deleteMasterCalendarNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiDeleteMasterCalendarNoteRequest
 */
export interface MasterCalendarNoteApiDeleteMasterCalendarNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiDeleteMasterCalendarNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiDeleteMasterCalendarNote
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findMasterCalendarNotes operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiFindMasterCalendarNotesRequest
 */
export interface MasterCalendarNoteApiFindMasterCalendarNotesRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly endDate?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarNoteApiFindMasterCalendarNotes
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getMasterCalendarNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiGetMasterCalendarNoteRequest
 */
export interface MasterCalendarNoteApiGetMasterCalendarNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiGetMasterCalendarNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiGetMasterCalendarNote
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for updateMasterCalendarNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNote
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNote
     */
    readonly updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteEndDate operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteEndDate
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteEndDate
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteEndDateCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteEndDate
     */
    readonly updateMasterCalendarNoteEndDateCommandDto: UpdateMasterCalendarNoteEndDateCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteHoverNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNote
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteHoverNoteCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNote
     */
    readonly updateMasterCalendarNoteHoverNoteCommandDto: UpdateMasterCalendarNoteHoverNoteCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteLongDescription operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescription
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescription
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteLongDescriptionCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescription
     */
    readonly updateMasterCalendarNoteLongDescriptionCommandDto: UpdateMasterCalendarNoteLongDescriptionCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteProperty operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteProperty
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteProperty
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNotePropertyCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteProperty
     */
    readonly updateMasterCalendarNotePropertyCommandDto: UpdateMasterCalendarNotePropertyCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteShortNote operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteShortNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteShortNote
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteShortNoteCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteShortNote
     */
    readonly updateMasterCalendarNoteShortNoteCommandDto: UpdateMasterCalendarNoteShortNoteCommandDto
}

/**
 * Request parameters for updateMasterCalendarNoteStartDate operation in MasterCalendarNoteApi.
 * @export
 * @interface MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest
 */
export interface MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteStartDate
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteStartDate
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteStartDateCommandDto}
     * @memberof MasterCalendarNoteApiUpdateMasterCalendarNoteStartDate
     */
    readonly updateMasterCalendarNoteStartDateCommandDto: UpdateMasterCalendarNoteStartDateCommandDto
}

/**
 * MasterCalendarNoteApi - object-oriented interface
 * @export
 * @class MasterCalendarNoteApi
 * @extends {BaseAPI}
 */
export class MasterCalendarNoteApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Master Calendar Note Command
     * @param {MasterCalendarNoteApiCreateMasterCalendarNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public createMasterCalendarNote(requestParameters: MasterCalendarNoteApiCreateMasterCalendarNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).createMasterCalendarNote(requestParameters.createMasterCalendarNoteCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Master Calendar Note Command
     * @param {MasterCalendarNoteApiDeleteMasterCalendarNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public deleteMasterCalendarNote(requestParameters: MasterCalendarNoteApiDeleteMasterCalendarNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).deleteMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Master Calendar Notes
     * @param {MasterCalendarNoteApiFindMasterCalendarNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public findMasterCalendarNotes(requestParameters: MasterCalendarNoteApiFindMasterCalendarNotesRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).findMasterCalendarNotes(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.startDate, requestParameters.endDate, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Master Calendar Note by ID
     * @param {MasterCalendarNoteApiGetMasterCalendarNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public getMasterCalendarNote(requestParameters: MasterCalendarNoteApiGetMasterCalendarNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).getMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note End Date Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteEndDate(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteEndDateRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteEndDate(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteEndDateCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Hover Note Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteHoverNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteHoverNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteHoverNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteHoverNoteCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Long Description Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteLongDescription(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteLongDescriptionRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteLongDescription(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteLongDescriptionCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Property Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteProperty(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNotePropertyRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteProperty(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNotePropertyCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Short Note Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteShortNote(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteShortNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteShortNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteShortNoteCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Start Date Command
     * @param {MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarNoteApi
     */
    public updateMasterCalendarNoteStartDate(requestParameters: MasterCalendarNoteApiUpdateMasterCalendarNoteStartDateRequest, options?: AxiosRequestConfig) {
        return MasterCalendarNoteApiFp(this.configuration).updateMasterCalendarNoteStartDate(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteStartDateCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

