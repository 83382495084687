import { AnyAction, Reducer, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import dateReducer from '../GlobalVariables/DateValue';
import localStorageComponent from '../GlobalVariables/LocalStorageComponent';
import saveReducer from '../GlobalVariables/SaveFilter';
import searchReducer from '../GlobalVariables/SearchText';
import apiReducer from './reducers/Api';
import filterAPIReducer from './reducers/FiltersReducer';
import languageReducer from './reducers/Languages';
import memberPermissionAPIReducer from './reducers/MemberPermissions';
import otherReducer from './reducers/Others';
import permissionsAPIReducer from './reducers/Permissions';
import pingReducer from './reducers/PingReducer';
import preferenceFilterAPIReducer from './reducers/PreferenceFilter';
import preferenceSaveReducer from './reducers/PreferenceSave';
import roleAPIReducer from './reducers/Roles';
import testimonialAPIReducer from './reducers/Testimonials';

// new
import arrivalFilterReducer from './slices/ArrivalFilterSlice';
import arrivalReportReducer from './slices/ArrivalReportSlice';
import authReducer from './slices/AuthSlice';
import blockReducer from './slices/BlocksSlice';
import bookingReducer from './slices/BookingSlice';
import bottomSheetReducer from './slices/BottomSheetSlice';
import checkInOutReportReducer from './slices/CheckInOutReportSlice';
import departmentReducer from './slices/DepartmentSlice';
import departureFilterReducer from './slices/DepartureFilterSlice';
import departureReportReducer from './slices/DepartureReportSlice';
import filterReducer from './slices/FilterSlice';
import masterCalendarIssueCountReducer from './slices/MasterCalendarIssueCountSlice';
import masterCalendarPropertyDetailReducer from './slices/MasterCalendarPropertyDetailSlice';
import masterCalendarPropertyReducer from './slices/MasterCalendarPropertySlice';
import masterCalendarSettingsSlice from './slices/MasterCalendarSettingsSlice';
import masterCalendarReducer from './slices/MasterCalendarSlice';
import noteReducer from './slices/NoteSlice';
import notificationsReducer from './slices/NotificationSlice';
import ownerReducer from './slices/OwnerSlice';
import permissions from './slices/PermissionsSlice';
import propertiesFilteredReducer from './slices/PropertyFilteredSlice';
import propertyItemTypeReducer from './slices/PropertyItemTypeSlice';
import propertiesReducer from './slices/PropertySlice';
import propertyStatusReducer from './slices/PropertyStatusSlice';
import propertyTagReducer from './slices/PropertyTagSlice';
import propertyUtilityTypeReducer from './slices/PropertyUtilityTypeSlice';
import regionGroupReducer from './slices/RegionGroupSlice';
import regionReducer from './slices/RegionSlice';
import staffTagReducer from './slices/StaffTagSlice';
import taskReducer from './slices/TaskSlice';
import timeZoneReducer from './slices/TimeZoneSlice';
import uiReducer from './slices/UiSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['userAPIReducer', 'preferenceAPIReducer'],
};

export const combinedReducer = combineReducers({
  masterCalendarPropertyDetailReducer,
  languageReducer,
  apiReducer,
  otherReducer,
  roleAPIReducer,
  masterCalendarReducer,
  pingReducer,
  testimonialAPIReducer, //Was duplicated and removed?
  memberPermissionAPIReducer,
  permissionsAPIReducer,
  filterAPIReducer,
  dateReducer,
  saveReducer,
  preferenceFilterAPIReducer,
  preferenceSaveReducer,
  localStorageComponent,
  searchReducer,
  regionReducer,
  regionGroupReducer,
  authReducer,
  uiReducer,
  notificationsReducer,
  timeZoneReducer,
  propertyTagReducer,
  departmentReducer,
  propertiesReducer,
  propertiesFilteredReducer,
  checkInOutReportReducer,
  filterReducer,
  propertyStatusReducer,
  ownerReducer,
  staffTagReducer,
  propertyItemTypeReducer,
  propertyUtilityTypeReducer,
  arrivalFilterReducer,
  arrivalReportReducer,
  noteReducer,
  bookingReducer,
  blockReducer,
  taskReducer,
  masterCalendarPropertyReducer,
  masterCalendarIssueCountReducer,
  masterCalendarSettingsSlice,
  departureReportReducer,
  departureFilterReducer,
  bottomSheetReducer,
  permissions,
});

const rootReducer = (state: ReturnType<typeof combinedReducer>, action: AnyAction) => {
  if (action.type === 'reset') {
    state = {} as ReturnType<typeof combinedReducer>;
  }

  // NOTE: can add other root reducer actions if needed

  return combinedReducer(state, action);
};

export default persistReducer(
  persistConfig,
  rootReducer as Reducer<ReturnType<typeof rootReducer>>,
);
