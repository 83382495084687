/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { QueryResultTimeZoneDto } from '../models';
// @ts-ignore
import { TimeZoneDto } from '../models';
/**
 * TimeZoneApi - axios parameter creator
 * @export
 */
export const TimeZoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find Time Zones
         * @param {string} [startsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTimeZones: async (startsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/timezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startsWith !== undefined) {
                localVarQueryParameter['startsWith'] = startsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Time Zone by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZone: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTimeZone', 'id', id)
            const localVarPath = `/v1/timezone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeZoneApi - functional programming interface
 * @export
 */
export const TimeZoneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeZoneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find Time Zones
         * @param {string} [startsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTimeZones(startsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultTimeZoneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTimeZones(startsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimeZoneApi.findTimeZones']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Time Zone by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeZone(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeZoneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeZone(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimeZoneApi.getTimeZone']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TimeZoneApi - factory interface
 * @export
 */
export const TimeZoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeZoneApiFp(configuration)
    return {
        /**
         * 
         * @summary Find Time Zones
         * @param {TimeZoneApiFindTimeZonesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTimeZones(requestParameters: TimeZoneApiFindTimeZonesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultTimeZoneDto> {
            return localVarFp.findTimeZones(requestParameters.startsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Time Zone by ID
         * @param {TimeZoneApiGetTimeZoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZone(requestParameters: TimeZoneApiGetTimeZoneRequest, options?: AxiosRequestConfig): AxiosPromise<TimeZoneDto> {
            return localVarFp.getTimeZone(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findTimeZones operation in TimeZoneApi.
 * @export
 * @interface TimeZoneApiFindTimeZonesRequest
 */
export interface TimeZoneApiFindTimeZonesRequest {
    /**
     * 
     * @type {string}
     * @memberof TimeZoneApiFindTimeZones
     */
    readonly startsWith?: string

    /**
     * 
     * @type {number}
     * @memberof TimeZoneApiFindTimeZones
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TimeZoneApiFindTimeZones
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof TimeZoneApiFindTimeZones
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getTimeZone operation in TimeZoneApi.
 * @export
 * @interface TimeZoneApiGetTimeZoneRequest
 */
export interface TimeZoneApiGetTimeZoneRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeZoneApiGetTimeZone
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof TimeZoneApiGetTimeZone
     */
    readonly ifMatch?: number
}

/**
 * TimeZoneApi - object-oriented interface
 * @export
 * @class TimeZoneApi
 * @extends {BaseAPI}
 */
export class TimeZoneApi extends BaseAPI {
    /**
     * 
     * @summary Find Time Zones
     * @param {TimeZoneApiFindTimeZonesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeZoneApi
     */
    public findTimeZones(requestParameters: TimeZoneApiFindTimeZonesRequest = {}, options?: AxiosRequestConfig) {
        return TimeZoneApiFp(this.configuration).findTimeZones(requestParameters.startsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Time Zone by ID
     * @param {TimeZoneApiGetTimeZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeZoneApi
     */
    public getTimeZone(requestParameters: TimeZoneApiGetTimeZoneRequest, options?: AxiosRequestConfig) {
        return TimeZoneApiFp(this.configuration).getTimeZone(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}

