import { createSlice } from '@reduxjs/toolkit';
import { useMasterCalendar } from '../../hooks/useMasterCalendar';
useMasterCalendar;
export interface FilterState {
  MasterCalendarFilters: MasterCalendarFilters;
}

export interface MasterCalendarFilters {
  keyword: string;
}

const initialState: FilterState = {
  MasterCalendarFilters: {
    keyword: '',
  },
};

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.MasterCalendarFilters.keyword = action.payload;
    },
  },
});

const { reducer } = filterSlice;
export const { setKeyword } = filterSlice.actions;
export default reducer;
