import { TeamsConst } from 'constants/Base';
import {
  MasterCalendarNoteApi,
  MasterCalendarNoteApiDeleteMasterCalendarNoteRequest,
  MasterCalendarNoteApiFindMasterCalendarNotesRequest,
} from 'teams-openapi';
import { BaseService } from './BaseService';

export class NoteService {
  private _baseInstance: BaseService;
  private static _instance: NoteService;
  private api: MasterCalendarNoteApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarNoteApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getNotes(filters: MasterCalendarNoteApiFindMasterCalendarNotesRequest) {
    return this.api.findMasterCalendarNotes(filters);
  }

  public deleteNote = (filters: MasterCalendarNoteApiDeleteMasterCalendarNoteRequest) => {
    return this.api.deleteMasterCalendarNote(filters);
  };
}
