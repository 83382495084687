import { TeamsConst } from 'constants/Base';
import {
  MasterCalendarApi,
  MasterCalendarApiCreateSettingsRequest,
  MasterCalendarApiDragAndDropTaskRequest,
  MasterCalendarApiGetPropertyListRequest,
  MasterCalendarApiGetSettingsRequest,
  MasterCalendarApiUpdateNoteRequest,
  MasterCalendarSettingsDto,
  UpdateMasterCalendarNoteCommandDto,
  UpdateTaskDateTimeCommandDto,
} from 'teams-openapi';
import { BaseService } from './BaseService';

export class MasterCalendarService {
  private _baseInstance: BaseService;
  private static _instance: MasterCalendarService;
  private api: MasterCalendarApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public getMasterCalendarPropertyList(args: MasterCalendarApiGetPropertyListRequest) {
    const request = {
      ...args,
      customerId: this._baseInstance.CustomerId,
      servicerId: this._baseInstance.ServicerId,
    };
    return this.api.getPropertyList(request);
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getIssueCount() {
    return this.api.getIssueCount();
  }

  public getSettings(args: MasterCalendarApiGetSettingsRequest) {
    return this.api.getSettings(args);
  }

  public createSettings(settings: MasterCalendarSettingsDto) {
    const req: MasterCalendarApiCreateSettingsRequest = {
      createMasterCalendarSettingsCommandDto: { ...settings },
    };

    return this.api.createSettings(req);
  }

  public updateNote(id: number, note: UpdateMasterCalendarNoteCommandDto, version: number) {
    const req: MasterCalendarApiUpdateNoteRequest = {
      id,
      ifMatch: version,
      updateMasterCalendarNoteCommandDto: note,
    };
    return this.api.updateNote(req);
  }

  public updateTask(id: number, version: number, task: UpdateTaskDateTimeCommandDto) {
    const req: MasterCalendarApiDragAndDropTaskRequest = {
      id,
      ifMatch: version,
      updateTaskDateTimeCommandDto: task,
    };
    return this.api.dragAndDropTask(req);
  }

  public getPermissions() {
    return this.api.getPermissions({
      customerId: this._baseInstance.CustomerId,
      servicerId: this._baseInstance.ServicerId,
    });
  }
}
