import { TBottomSheetTypes } from '@operto/ui';
import { createSlice } from '@reduxjs/toolkit';

export interface BottomSheetState {
  children: TBottomSheetTypes['children'];
  title: TBottomSheetTypes['title'];
  titleStartComponent?: TBottomSheetTypes['titleStartComponent'];
  titleEndComponent?: TBottomSheetTypes['titleEndComponent'];
  titleSx?: TBottomSheetTypes['titleSx'];
  titleVariant?: TBottomSheetTypes['titleVariant'];
  stackSx?: TBottomSheetTypes['stackSx'];
  paperSx?: TBottomSheetTypes['paperSx'];
  rootSx?: TBottomSheetTypes['rootSx'];
  isOpen?: boolean;
}

const initialState: BottomSheetState = {
  children: undefined,
  title: undefined,
  titleStartComponent: undefined,
  titleEndComponent: undefined,
  titleSx: undefined,
  titleVariant: undefined,
  stackSx: undefined,
  paperSx: undefined,
  rootSx: undefined,
  isOpen: false,
};

const bottomSheetSlice = createSlice({
  name: 'bottomSheet/toggle',
  initialState,
  reducers: {
    openBottomSheet: (state, action) => {
      state.children = action.payload.children;
      state.title = action.payload.title;
      state.titleStartComponent = action.payload.titleStartComponent;
      state.titleEndComponent = action.payload.titleEndComponent;
      state.titleSx = action.payload.titleSx;
      state.titleVariant = action.payload.titleVariant;
      state.stackSx = action.payload.stackSx;
      state.paperSx = action.payload.paperSx;
      state.rootSx = action.payload.rootSx;
      state.isOpen = true;
    },
    closeBottomSheet: state => {
      state.children = undefined;
      state.title = undefined;
      state.titleStartComponent = undefined;
      state.titleEndComponent = undefined;
      state.titleSx = undefined;
      state.titleVariant = undefined;
      state.stackSx = undefined;
      state.paperSx = undefined;
      state.rootSx = undefined;
      state.isOpen = false;
    },
  },
});

const { reducer } = bottomSheetSlice;
export const { openBottomSheet, closeBottomSheet } = bottomSheetSlice.actions;
export default reducer;
