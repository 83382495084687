import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { translateError } from 'utils/ErrorManagement';

import {
  MasterCalendarApiActivateTaskRequest,
  MasterCalendarApiDeactivateTaskRequest,
  MasterCalendarApiGetTasksRequest,
  MasterCalendarTaskExtendedPropsDto,
  MasterCalendarTaskResultDto,
} from '@/teams-openapi';
import { TaskService } from 'services/TaskService';

export interface TaskState {
  fetchAllStatus: API_STATUS;
  activateStatus: API_STATUS;
  deactivateStatus: API_STATUS;
  result: MasterCalendarTaskResultDto[];
}

const initialState: TaskState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
  activateStatus: API_STATUS.IDLE,
  deactivateStatus: API_STATUS.IDLE,
};

export const getTasks = createAsyncThunk(
  'master-calendar-task',
  async (filters: MasterCalendarApiGetTasksRequest, { rejectWithValue }) => {
    try {
      const dataResult = await TaskService.Instance.getTasks(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const activateTaskById = createAsyncThunk(
  'master-calendar-task/activate',
  async (filters: MasterCalendarApiActivateTaskRequest, { rejectWithValue }) => {
    try {
      const dataResult = await TaskService.Instance.activateTask(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deactivateTaskById = createAsyncThunk(
  'master-calendar-task/deactivate',
  async (filters: MasterCalendarApiDeactivateTaskRequest, { rejectWithValue }) => {
    try {
      const dataResult = await TaskService.Instance.deactivateTask(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<TaskState>) => {
  // --------------- GET TASKS ---------------
  builder.addCase(getTasks.pending, (state: TaskState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getTasks.fulfilled, (state: TaskState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(getTasks.rejected, (state: TaskState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });

  // --------------- ACTIVATE A TASK ---------------
  builder.addCase(activateTaskById.pending, (state: TaskState) => {
    state.activateStatus = API_STATUS.PENDING;
  });
  builder.addCase(activateTaskById.fulfilled, (state: TaskState, action) => {
    state.activateStatus = API_STATUS.SUCCESS;
    state.result = state.result.map(task => {
      if (task.id === action.payload.result.id) {
        const taskExtendedProps: MasterCalendarTaskExtendedPropsDto | undefined =
          task.extendedProps;
        if (taskExtendedProps != undefined) taskExtendedProps.active = true;
        task.extendedProps = taskExtendedProps;
      }
      return task;
    });
  });
  builder.addCase(activateTaskById.rejected, (state: TaskState) => {
    state.activateStatus = API_STATUS.FAILURE;
  });

  // --------------- DEACTIVATE A TASK ---------------
  builder.addCase(deactivateTaskById.pending, (state: TaskState) => {
    state.deactivateStatus = API_STATUS.PENDING;
  });
  builder.addCase(deactivateTaskById.fulfilled, (state: TaskState, action) => {
    state.deactivateStatus = API_STATUS.SUCCESS;
    state.result = state.result.map(task => {
      if (task.id === action.payload.result.id) {
        const taskExtendedProps: MasterCalendarTaskExtendedPropsDto | undefined =
          task.extendedProps;
        if (taskExtendedProps != undefined) taskExtendedProps.active = false;
        task.extendedProps = taskExtendedProps;
      }
      return task;
    });
  });
  builder.addCase(deactivateTaskById.rejected, (state: TaskState) => {
    state.deactivateStatus = API_STATUS.FAILURE;
  });
};

const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {
    clearTasks: state => {
      state.result = [];
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = taskSlice;
export const { clearTasks } = taskSlice.actions;
export default reducer;
