export enum API_STATUS {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export const NO_REGION_GROUP_ID = -1;
export const NO_REGION_ID = -1;
export const ARRIVAL_REPORT_CSV = 'arrivalreport.csv';
export const ARRIVAL_REPORT_PDF = 'arrivalreport.pdf';
export const EXPECTED_DATE_RANGE_LENGTH = 2;

export enum BEACON_HELPSCOUT_IDS {
  GENERAL_HELP = '["5afb2478042863158411cd18","5c75d41a2c7d3a0cb931fbaf","5d605c8304286350aeeaf5c1","5afb20742c7d3a640ed6dd58","5ca380102c7d3a154461aba4","5ca381772c7d3a154461abc6","60f71e00b55c2b04bf6d625d"]',
  QUICK_REPORTS = '["5afb23492c7d3a640ed6dd94"]',
  MASTER_CALENDAR_HELP = '["64fb7d5a6064d371c84cbad4","5f833eb746e0fb001798bd16","5c75d41a2c7d3a0cb931fbaf","5d605c8304286350aeeaf5c1","5ca380102c7d3a154461aba4","5ca381772c7d3a154461abc6"]',
}
