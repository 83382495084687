import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import { addMonths } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export const FORMAT_YYYY_MM__DD = 'yyyy-MM-dd';

export const DEFAULT_1_MONTH_DATE_RANGE: RangeInput<Date> = [new Date(), addMonths(new Date(), 1)];

const formatDateFromRawDateString = (dateString: string) => {
  if (new Date(dateString).toString() !== 'Invalid Date') {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const day = dateString.slice(8, 10);
    const time = dateString.slice(11, 16);
    const [hours, minutes] = time.split(':');
    return { year, month, day, hours, minutes };
  }
  return {
    year: null,
    month: null,
    day: null,
    hours: null,
    minutes: null,
  };
};

const formatDate = (date: Date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
const changeDateFormatLocale = (date: Date, locale = 'en-US', dateStyle = 'medium') => {
  let option = {};
  switch (dateStyle) {
    case 'short': {
      option = { month: 'numeric', day: 'numeric', year: '2-digit' };
      break;
    }
    case 'full': {
      option = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      break;
    }
    case 'shortdt': {
      option = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      break;
    }
    case 'weekdaydt': {
      option = {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
      };
      break;
    }
    default: {
      option = { month: 'numeric', day: 'numeric', year: 'numeric' };
    }
  }
  const localeValue = locale || 'en-US';
  // eslint-disable-next-line no-useless-escape
  // const oldDate = new Date(date.toLocaleString().substr(0, 10).replace(/-/g, '\/')).toLocaleString(locale, {dateStyle});
  // eslint-disable-next-line no-useless-escape
  const newDate = new Intl.DateTimeFormat(localeValue, option).format(
    new Date(date.toLocaleString().substr(0, 10).replace(/-/g, '/')),
  );
  return newDate;
};

const changeTimeZone = (date: Date, timezone = 'US/Pacific', formatStr = 'yyyy-MM-dd') => {
  const zonedDate = utcToZonedTime(date, timezone);
  return format(zonedDate, formatStr);
};

const getPreviousDate = (noOfDays: number) => {
  const date = new Date(Date.now());
  return `${date.getMonth()}/${date.getDate() - noOfDays}/${date.getFullYear()}`;
};

// date1 must be earlier than date2, otherwise it will return undefined
const getDateDifference = (date1: Date, date2: Date) => {
  if (date2 < date1) {
    return undefined;
  }
  const timeInMilliseconds = date2.getTime() - date1.getTime();
  const daysDifference = timeInMilliseconds / (1000 * 60 * 60 * 24);
  return Math.ceil(daysDifference);
};

export {
  changeDateFormatLocale,
  changeTimeZone,
  formatDate,
  formatDateFromRawDateString,
  getDateDifference,
  getPreviousDate,
};
