import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import CustomThemeProvider from './components/base/CustomThemeProvider';
import { RELEASE_STAGE, TeamsConst } from './constants/Base';
import './index.css';
import { persistor, store } from './redux/Store';
import reportWebVitals from './reportWebVitals';
import { setupApiServices } from './services';
import './services/i18n';

const rootElement = document.getElementById('root') as HTMLElement;

export let throwError: (error: string) => void = () => void 0;

// Unit tests are loading this file, but root is not being created because we are only testing specific components.
if (rootElement) {
  const root = createRoot(rootElement);

  Bugsnag.start({
    apiKey: TeamsConst.bugsnagApiKey,
    releaseStage: RELEASE_STAGE,
    plugins: [new BugsnagPluginReact()],
  });
  const ErrorBoundaryBugsnag = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  const ErrorBoundary = ErrorBoundaryBugsnag || React.Fragment;

  setupApiServices(store);

  throwError = (error: string) => {
    root.render(
      <Provider store={store}>
        <ErrorFallback
          error={new Error(error)}
          info={{ componentStack: '' }}
          clearError={() => void 0}
        />
      </Provider>,
    );
  };

  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CustomThemeProvider>
          <BrowserRouter>
            <PersistGate persistor={persistor}>
              <SnackbarProvider preventDuplicate>
                <App />
              </SnackbarProvider>
            </PersistGate>
          </BrowserRouter>
        </CustomThemeProvider>
      </ErrorBoundary>
    </Provider>,
    // </React.StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
