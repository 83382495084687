import axios, { AxiosRequestConfig } from 'axios';
import { TeamsConst } from '../constants/Base';
import { AuthenticationApi } from '../teams-openapi';
import { BaseService, redirectMap } from './BaseService';

export class AuthService {
  private _baseInstance: BaseService;
  private static _instance: AuthService;
  private api: AuthenticationApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new AuthenticationApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public authenticateUser(email: string, password: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: 'POST',
      url: '/login',
      baseURL: TeamsConst.apiBaseUrl,
      withCredentials: true,
      data: {
        email: email,
        password: password,
      },
    };
    return axios.request(axiosConfig).then((response: { data: ICfwToken }) => response.data);
  }

  public authenticateUserFromCfwJwt() {
    const redirectPath = redirectMap[window.location.pathname] ?? '';
    const axiosConfig: AxiosRequestConfig = {
      method: 'POST',
      url: '/login/cfwToken',
      baseURL: TeamsConst.apiBaseUrl,
      withCredentials: true,
      data: {},
    };
    return axios
      .request(axiosConfig)
      .then((response: { data: ICfwToken }) => response.data)
      .catch(() => {
        window.location.replace(
          `${TeamsConst.coldFusionWebsiteUrl}/login${
            redirectPath ? `?redirect=${redirectPath}` : ''
          }`,
        ); // redirect to coldfusion login
      });
  }

  public getUserInfo(cid: number) {
    return this.api.getUserInfo({ cid: cid }, { withCredentials: true });
  }
}

interface ICfwToken {
  customerId: number;
  servicerId: number;
  jwtToken: string;
  cfwToken: string;
}
