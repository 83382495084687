export const TeamsConst = {
  apiBaseUrl: process.env.REACT_APP_JAVA_API_URL?.toString() ?? "http://localhost:8080",
  staffDashboardUrl: process.env.REACT_APP_STAFF_DASHBOARD_URL?.toString() ?? "http://localhost:3001",
  staffDashboardShortUrl: process.env.REACT_APP_STAFF_DASHBOARD_SHORT_URL?.toString() ?? "http://localhost:3001/servicer-dashboard",
  coldFusionWebsiteUrl: process.env.REACT_APP_COLDFUSION_WEBSITE_URL?.toString() ?? "http://localhost:8084",
  buildNumber: process.env.REACT_APP_CODEBUILD_BUILD_NUMBER?.toString() ?? "0",
  environmentName: process.env.REACT_APP_ENV_NAME?.toString() ?? "localdev",
  environmentColor: process.env.REACT_APP_ENV_COLOR?.toString() ?? "gold",
  copywriteName: 'Operto Teams',
  beaconKey: process.env.REACT_APP_BEACON_KEY?.toString() ?? "1f6ef33f-b090-443c-a88c-b1b111bcffe1",
  errorConst: {
    UNAUTHORISED_ACCESS: 401,
  },
  dataGridProLicense: process.env.REACT_APP_DATA_GRID_PRO_LICENSE_KEY?.toString() ?? "36f64ba54ab15dd648a94b3f9ada1d64T1JERVI6NjA1OTYsRVhQSVJZPTE3MDkxOTYwODQ3NDUsS0VZVkVSU0lPTj0x",
  coldFusionCookieName: process.env.REACT_APP_CFJWT_COOKIE_NAME?.toString() ?? "CFWJWTLOCAL" ,
  bugsnagApiKey: process.env.REACT_APP_FRONT_END_BUGSNAG_API_KEY?.toString() ?? "",
  fullStoryOrgId: process.env.REACT_APP_TEAMS_FULLSTORY_ORG_ID?.toString() ?? "",
};

export const RELEASE_STAGE = TeamsConst.environmentName?.startsWith('prod')
  ? 'production'
  : ['sandbox', 'qa1', 'qa2'].includes(TeamsConst.environmentName)
  ? 'staging'
  : 'development';
export const isProduction = RELEASE_STAGE === 'production';
export const isStaging = RELEASE_STAGE === 'staging';
export const isDevelopment = RELEASE_STAGE === 'development';
