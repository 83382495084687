// @ts-nocheck - TODO: fix typescript errors
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { TeamsConst } from '../constants/Base';
import { LOGIN_STATE, UserState } from '../models/UserState';
import { AppState, useAppDispatch } from '../redux/Store';
import {
  authenticateUser,
  authenticateUserFromCfwJwt,
  getUserInfo,
  loadUserFromStorage,
  logoutUser,
  updateUseNewGui,
} from '../redux/slices/AuthSlice';

export const useAuthentication = () => {
  const CHECK_TOKEN_TIMEOUT_MS = 60000; // 60 seconds
  let tokenTimeoutInterval: any;
  const dispatch = useAppDispatch();
  const userState: UserState = useSelector((state: AppState) => state.authReducer);
  const rawUserFromStorage = localStorage.getItem('userState');

  const authorizeFor = (claimRequired: string) => {
    const isAuthorizedFor = userState.Claims.includes(claimRequired);
    return isAuthorizedFor;
  };

  const loginUserWithCredentials = (email, password) => {
    return dispatch(authenticateUser({ email: email, password: password }));
  };

  const isUserLoggedIn = (): boolean => {
    return (
      userState !== undefined &&
      (userState?.LoginState === LOGIN_STATE.LOADED_FROM_LOGIN ||
        userState?.LoginState === LOGIN_STATE.LOADED_FROM_STORAGE ||
        userState?.LoginState === LOGIN_STATE.LOADED_FROM_CFWJWT_LOGIN) &&
      rawUserFromStorage !== undefined
    );
  };

  const loadUser = () => {
    if (userState === undefined || userState.LoginState === LOGIN_STATE.NOT_LOADED) {
      const rawUserState = localStorage.getItem('userState');
      const userStateFromStorage = rawUserState ? JSON.parse(rawUserState) : undefined;
      if (
        userStateFromStorage?.User !== undefined &&
        (userStateFromStorage?.LoginState === LOGIN_STATE.LOADED_FROM_CFWJWT_LOGIN ||
          userStateFromStorage?.LoginState === LOGIN_STATE.LOADED_FROM_LOGIN)
      ) {
        return dispatch(loadUserFromStorage(userStateFromStorage));
      } else {
        return login();
      }
    } else {
      return login();
    }
  };

  const loadUserInfo = (cid: number) => {
    return dispatch(getUserInfo(cid));
  };

  const login = () => {
    return dispatch(authenticateUserFromCfwJwt(null));
  };

  const logout = async () => {
    clearInterval(tokenTimeoutInterval);
    Cookies.remove(TeamsConst.coldFusionCookieName);
    await dispatch(logoutUser({}));
    window.location.replace(`${TeamsConst.coldFusionWebsiteUrl}/logout`); // redirect to coldfusion logout to force logout from CF site
  };

  const checkToken = () => {
    tokenTimeoutInterval = setInterval(() => {
      const cfwjwtCookie = Cookies.get(TeamsConst.coldFusionCookieName);
      if (cfwjwtCookie === undefined) {
        logout();
      }
    }, CHECK_TOKEN_TIMEOUT_MS);
  };

  const updateGuiPreferences = (guiPreferences: boolean) => {
    return dispatch(updateUseNewGui(guiPreferences));
  };

  return {
    rawUserFromStorage,
    loadUser,
    loadUserInfo,
    isUserLoggedIn,
    userState,
    checkToken,
    logout,
    login,
    loginUserWithCredentials,
    authorizeFor,
    updateGuiPreferences,
  };
};
