import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { MasterCalendarPropertyDetailService } from 'services/MasterCalendarPropertyDetailService';
import {
  MasterCalendarApiGetPropertyDetailsRequest,
  MasterCalendarPropertyDetailDto,
} from 'teams-openapi';
import { translateError } from 'utils/ErrorManagement';

export interface MasterCalendarPropertyDetailState {
  fetchAllStatus: API_STATUS;
  result: MasterCalendarPropertyDetailDto[];
}

const initialState: MasterCalendarPropertyDetailState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
};

export const getMasterCalendarPropertyDetail = createAsyncThunk(
  'master-calendar-property-detail',
  async (args: MasterCalendarApiGetPropertyDetailsRequest, { rejectWithValue }) => {
    try {
      const dataResult = await MasterCalendarPropertyDetailService.Instance.getProperties(args);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (
  builder: ActionReducerMapBuilder<MasterCalendarPropertyDetailState>,
) => {
  builder.addCase(
    getMasterCalendarPropertyDetail.pending,
    (state: MasterCalendarPropertyDetailState) => {
      state.fetchAllStatus = API_STATUS.PENDING;
    },
  );
  builder.addCase(
    getMasterCalendarPropertyDetail.fulfilled,
    (state: MasterCalendarPropertyDetailState, action) => {
      state.fetchAllStatus = API_STATUS.SUCCESS;
      state.result = action.payload.result.data || [];
    },
  );
  builder.addCase(
    getMasterCalendarPropertyDetail.rejected,
    (state: MasterCalendarPropertyDetailState) => {
      state.fetchAllStatus = API_STATUS.FAILURE;
      state.result = [];
    },
  );
};

const masterCalendarPropertyDetailSlice = createSlice({
  name: 'masterCalendarPropertyDetailSlice',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = masterCalendarPropertyDetailSlice;
export default reducer;
