// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { RegionGroupService } from '../../services/RegionGroupService';
import { translateError } from '../../utils/ErrorManagement';

export interface RegionGroupState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getRegionGroups = createAsyncThunk(
  'regionGroups/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await RegionGroupService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getRegionGroupsReferences = createAsyncThunk(
  'regionGroups/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await RegionGroupService.Instance.getAllRefs();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createRegionGroup = createAsyncThunk(
  'regionGroups/create',
  async (regionGroup: any, { rejectWithValue }) => {
    try {
      const { label } = regionGroup;
      const res = await RegionGroupService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);
export const updateRegionGroup = createAsyncThunk(
  'regionGroups/update',
  async (regionGroup: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = regionGroup;
      const res = await RegionGroupService.Instance.updateLabel(id, label, version);
      const latest = await RegionGroupService.Instance.getById(id, res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegionGroupLabel = createAsyncThunk(
  'regionGroups/updateLabel',
  async (regionGroup: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = regionGroup;
      const res = await RegionGroupService.Instance.updateLabel(id, label, version);
      // const latest = await RegionGroupService.Instance.getById(id, res.data.version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateRegionGroupSortOrder = createAsyncThunk(
  'regionGroups/updateSortOrder',
  async (regionGroup: any, { rejectWithValue }) => {
    try {
      const { id, sortOrder, version } = regionGroup;
      const res = await RegionGroupService.Instance.updateSortOrder(id, sortOrder, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteRegionGroup = createAsyncThunk(
  'regionGroups/delete',
  async (toDelete: any, { rejectWithValue }) => {
    try {
      const { id, version } = toDelete;
      const res = await RegionGroupService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<RegionGroupState>) => {
  // --------------- GET REGION GROUPS ---------------
  builder.addCase(getRegionGroups.pending, (state: RegionGroupState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getRegionGroups.fulfilled, (state: RegionGroupState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = data;
  });
  builder.addCase(getRegionGroups.rejected, (state: RegionGroupState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  builder.addCase(getRegionGroupsReferences.pending, (state: RegionGroupState, action) => {
    state.fetchRefsStatus = API_STATUS.PENDING;
  });
  builder.addCase(getRegionGroupsReferences.fulfilled, (state: RegionGroupState, action) => {
    state.fetchRefsStatus = API_STATUS.SUCCESS;
    const data = action.payload.data
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(ref => ({ id: ref.id, label: ref.label }));
    state.refs = data;
  });
  builder.addCase(getRegionGroupsReferences.rejected, (state: RegionGroupState, action) => {
    state.fetchRefsStatus = API_STATUS.FAILURE;
    state.refs = [];
  });

  // --------------- CREATE NEW ---------------
  builder.addCase(createRegionGroup.pending, (state: RegionGroupState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createRegionGroup.fulfilled, (state: RegionGroupState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(createRegionGroup.rejected, (state: RegionGroupState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE  ---------------
  builder.addCase(updateRegionGroup.pending, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionGroup.fulfilled, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { id, label, version: newVersion } = action.payload.data;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);

    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updateRegionGroup.rejected, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updateRegionGroupLabel.pending, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionGroupLabel.fulfilled, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updateRegionGroupLabel.rejected, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- MOVE (UPDATE SORT INDEX) ---------------
  builder.addCase(updateRegionGroupSortOrder.pending, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateRegionGroupSortOrder.fulfilled, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { sortOrder } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, sortOrder: sortOrder, version: action.payload.version };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, sortOrder: sortOrder, version: newVersion };
  });
  builder.addCase(updateRegionGroupSortOrder.rejected, (state: RegionGroupState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- DELETE ---------------
  builder.addCase(deleteRegionGroup.pending, (state: RegionGroupState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deleteRegionGroup.fulfilled, (state: RegionGroupState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.all = state.all.filter(item => item.id !== id).sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(deleteRegionGroup.rejected, (state: RegionGroupState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const regionGroupSlice = createSlice({
  name: 'regionGroup',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setRegionGroupToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
    updateLocalRegionGroupSort: (state, action) => {
      state.all = action.payload;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = regionGroupSlice;
export const { resetCreateStatus, setRegionGroupToEdit, updateLocalRegionGroupSort } =
  regionGroupSlice.actions;
export default reducer;
