import { createSlice } from '@reduxjs/toolkit';

export interface Snack {
  message: string;
  type: 'error' | 'success';
}

export interface NotificationState {
  snack?: Snack;
}

const initialState: NotificationState = {
  snack: undefined,
};

const notificationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addSnack: (state, action) => {
      state.snack = action.payload;
    },
    removeSnack: () => {
      // TODO: Implement this
    },
  },
});

const { reducer } = notificationSlice;
export const { addSnack, removeSnack } = notificationSlice.actions;
export default reducer;
