import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { NoteService } from 'services/NoteService';
import {
  MasterCalendarApiDeleteNoteRequest,
  MasterCalendarNoteApiFindMasterCalendarNotesRequest,
  MasterCalendarNoteDto,
} from 'teams-openapi';
import { translateError } from 'utils/ErrorManagement';

export interface NoteState {
  fetchAllStatus: API_STATUS;
  deleteStatus: API_STATUS;
  result: MasterCalendarNoteDto[];
}

const initialState: NoteState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getNotes = createAsyncThunk(
  'master-calendar-note',
  async (filters: MasterCalendarNoteApiFindMasterCalendarNotesRequest, { rejectWithValue }) => {
    try {
      const dataResult = await NoteService.Instance.getNotes(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteNoteAction = createAsyncThunk(
  'master-calendar-note-delete',
  async (filters: MasterCalendarApiDeleteNoteRequest, { rejectWithValue }) => {
    try {
      const dataResult = await NoteService.Instance.deleteNote(filters);
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<NoteState>) => {
  // --------------- GET NOTES ---------------
  builder.addCase(getNotes.pending, (state: NoteState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getNotes.fulfilled, (state: NoteState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(getNotes.rejected, (state: NoteState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });

  // --------------- DELETE NOTE ---------------
  builder.addCase(deleteNoteAction.pending, (state: NoteState) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deleteNoteAction.fulfilled, (state: NoteState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.result = state.result.filter(item => item.id !== id);
  });
  builder.addCase(deleteNoteAction.rejected, (state: NoteState) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const noteSlice = createSlice({
  name: 'noteSlice',
  initialState,
  reducers: {
    clearNotes: state => {
      state.result = [];
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = noteSlice;
export const { clearNotes } = noteSlice.actions;
export default reducer;
