// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { OwnerService } from '../../services/OwnerService';
import { OwnerDto } from '../../teams-openapi';
import { translateError } from '../../utils/ErrorManagement';

export interface OwnerState {
  all: OwnerDto[];
  refs: OwnerDto[];
  currentItem: OwnerDto;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState: OwnerState = {
  all: [] as OwnerDto[],
  refs: [] as OwnerDto[],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getOwners = createAsyncThunk('owners/get', async (_: void, { rejectWithValue }) => {
  try {
    const res = await OwnerService.Instance.getAll();
    return res.data;
  } catch (err) {
    return rejectWithValue(translateError(err));
  }
});

export const getOwnerRefs = createAsyncThunk(
  'owners/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await OwnerService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<OwnerState>) => {
  //------------------- GET OWNERS --------------------------------
  builder.addCase(getOwners.pending, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getOwners.fulfilled, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.ownerName.localeCompare(b.ownerName));
    state.all = data;
  });
  builder.addCase(getOwners.rejected, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [] as OwnerDto[];
  });

  //------------------- GET OWNER REFS --------------------------------
  builder.addCase(getOwnerRefs.pending, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getOwnerRefs.fulfilled, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.ownerName.localeCompare(b.ownerName));
    state.refs = data;
  });
  builder.addCase(getOwnerRefs.rejected, (state: OwnerState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.refs = [] as OwnerDto[];
  });
};

const ownerSlice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setOwnerToEdit: (state, action) => {
      state.currentItem = action.payload;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = ownerSlice;
export const { resetCreateStatus, setOwnerToEdit } = ownerSlice.actions;
export default reducer;
