export const REQUEST_CREATE_USER_LOGIN_FROM_CFWJWT = 'REQUEST_CREATE_USER_LOGIN_FROM_CFWJWT';
export const RESPONSE_CREATE_USER_LOGIN_FROM_CFWJWT = 'RESPONSE_CREATE_USER_LOGIN_FROM_CFWJWT';
export const FAIL_CREATE_USER_LOGIN_FROM_CFWJWT = 'FAIL_CREATE_USER_LOGIN_FROM_CFWJWT';
export const REQUEST_CREATE_USER_LOGIN = 'REQUEST_CREATE_USER_LOGIN';
export const RESPONSE_CREATE_USER_LOGIN = 'RESPONSE_CREATE_USER_LOGIN';
export const FAIL_CREATE_USER_LOGIN = 'FAIL_CREATE_USER_LOGIN';
export const USER_SET_FROM_STORAGE = 'USER_SET_FROM_STORAGE';
export const USER_LOGOUT = 'USER_LOGOUT';

export const REQUEST_READ_PING = 'REQUEST_READ_PING';
export const RESPONSE_READ_PING = 'RESPONSE_READ_PING';

export const SET_HAPPY_ACCOUNT = 'SET_HAPPY_ACCOUNT';
export const REQUEST_READ_ACCOUNT = 'REQUEST_READ_ACCOUNT';
export const RESPONSE_READ_ACCOUNT = 'RESPONSE_READ_ACCOUNT';
export const FAIL_READ_ACCOUNT = 'FAIL_READ_ACCOUNT';
export const REQUEST_UPDATE_ACCOUNT = 'REQUEST_UPDATE_ACCOUNT';
export const RESPONSE_UPDATE_ACCOUNT = 'RESPONSE_UPDATE_ACCOUNT';
export const FAIL_UPDATE_ACCOUNT = 'FAIL_UPDATE_ACCOUNT';

export const REQUEST_CREATE_CHECK_IN_OUT = 'REQUEST_CREATE_CHECK_IN_OUT';
export const RESPONSE_CREATE_CHECK_IN_OUT = 'RESPONSE_CREATE_CHECK_IN_OUT';
export const REQUEST_CREATE_EXPORT_CHECK_IN_OUT = 'REQUEST_CREATE_EXPORT_CHECK_IN_OUT';
