import * as claims from './ClaimConstants';

export default {
  teams_customer_admin: Object.values(claims),
  teams_staff_admin: [claims.MAIN_MENU_RHS_SETUP_STAFF],
  teams_setup: [
    claims.MAIN_MENU_RHS_SETUP,
    claims.MAIN_MENU_RHS_SETUP_STAFF_OWNER_NOTIFICATIONS,
    claims.MAIN_MENU_RHS_SETUP_ADMIN_NOTIFICATIONS,
    claims.MAIN_MENU_RHS_SETUP_NOTIFCATION_RULES,
    claims.MAIN_MENU_RHS_SETUP_OWNER_NOTIFICATION_RULES,
    claims.MAIN_MENU_RHS_SETUP_TASK_RULES,
    claims.MAIN_MENU_RHS_SETUP_TASK_FORMS,
    claims.MAIN_MENU_RHS_SETUP_STAFF,
    claims.MAIN_MENU_RHS_SETUP_PROPERTIES,
    claims.MAIN_MENU_RHS_SETUP_REGIONS,
    claims.MAIN_MENU_RHS_SETUP_OWNERS,
    claims.MAIN_MENU_RHS_SETUP_VENDORS,
    claims.MAIN_MENU_RHS_SETUP_PROPERTY_STATUSES,
    claims.MAIN_MENU_RHS_SETUP_DEPARTMENTS,
    claims.MAIN_MENU_RHS_SETUP_STAFF_TAGS,
    claims.MAIN_MENU_RHS_SETUP_PROPERTY_TAGS,
    claims.MAIN_MENU_RHS_SETUP_TASK_FORM_REPORT_TYPES,
    claims.MAIN_MENU_RHS_SETUP_SYSTEM_SETTINGS,
    claims.MAIN_MENU_RHS_SETUP_ICAL_FEEDS,
    claims.MAIN_MENU_RHS_SETUP_API_KEYS,
    claims.MAIN_MENU_RHS_SETUP_ONBOARDING_STEPS,
  ],
  teams_account: [claims.MAIN_MENU_ACCOUNT_SETTINGS],
  teams_master_calendar: [claims.MAIN_MENU_MASTER_CALENDAR],
  teams_issues: [claims.MAIN_MENU_ISSUES],
  teams_schedule: [claims.MAIN_MENU_SCHEDULE, claims.MAIN_MENU_SCHEDULING],
  teams_edit_booking: [claims.MAIN_MENU_BOOKINGS],
  teams_edit_tasks: [],
  teams_drag_and_drop: [],
  teams_edit_notes: [],
  teams_tracking: [claims.MAIN_MENU_TRACKING],
  teams_reports: [
    claims.MAIN_MENU_RHS_REPORTS_TASK_HISTORY,
    claims.MAIN_MENU_RHS_REPORTS_ISSUES_HISTORY,
    claims.MAIN_MENU_RHS_REPORTS_TASK_FORM_REPORTS,
    claims.MAIN_MENU_RHS_REPORTS_TIME_TRACKING_TASKS,
    claims.MAIN_MENU_RHS_REPORTS_TIME_TRACKING_EMPLOYEE,
    claims.MAIN_MENU_RHS_REPORTS_TIME_TRACKING_ALL,
    claims.MAIN_MENU_RHS_REPORTS_TIME_TRACKING_DETAIL,
    claims.MAIN_MENU_RHS_REPORTS_PIECE_PAY,
    claims.MAIN_MENU_RHS_REPORTS_BLENDED_PAY,
    claims.MAIN_MENU_RHS_REPORTS_OWNER_STATEMENT_REPORT,
    claims.MAIN_MENU_RHS_REPORTS_ACCEPT_DECLINE_REPORT,
    claims.MAIN_MENU_RHS_REPORTS_BOOKING_HISTORY,
    claims.MAIN_MENU_RHS_REPORTS_GUEST_HISTORY,
    claims.MAIN_MENU_RHS_REPORTS_NOTIFICATION_HISTORY,
  ],
  teams_manage: [claims.MAIN_MENU_RHS_MORE_MANAGE],
  teams_quick_reports: [
    claims.MAIN_MENU_QUICK_REPORTS,
    claims.MAIN_MENU_QUICK_REPORTS_ARRIVALS,
    claims.MAIN_MENU_QUICK_REPORTS_DEPARTURES,
    claims.MAIN_MENU_QUICK_REPORTS_CHECK_IN_OUT,
    claims.MAIN_MENU_QUICK_REPORTS_PROPERTY_STATUS,
    claims.MAIN_MENU_QUICK_REPORTS_LINEN_PACKING,
    claims.MAIN_MENU_QUICK_REPORTS_TASKS,
    claims.MAIN_MENU_QUICK_REPORTS_PIECE_PAY_REVIEW,
    claims.MAIN_MENU_QUICK_REPORTS_BLENDED_PAY_REVIEW,
  ],
  teams_edit_task_piece_pay: [],
  teams_service_assignment: [claims.MAIN_MENU_RHS_SETUP, claims.MAIN_MENU_RHS_SETUP_TASK_RULES],
  teams_issues_edit: [claims.MAIN_MENU_ISSUES],
};
