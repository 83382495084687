// @ts-nocheck - TODO: fix typescript errors
import { EMPTY, from } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { TeamsConst } from '../constants/Base';
import {
  PropertyApi,
  PropertyApiActivatePropertyRequest,
  PropertyApiArchivePropertyRequest,
  PropertyApiBulkActivatePropertiesRequest,
  PropertyApiBulkArchivePropertiesRequest,
  PropertyApiBulkDeactivatePropertiesRequest,
  PropertyApiBulkRestorePropertiesRequest,
  PropertyApiCreatePropertyRequest,
  PropertyApiDeactivatePropertyRequest,
  PropertyApiFindPropertiesRequest,
  PropertyApiGetPropertyRequest,
  PropertyApiRestorePropertyRequest,
  PropertyApiUpdatePropertyInfoRequest,
  PropertyDto,
  PropertyReferenceDto,
} from '../teams-openapi';
import { QueryHelper } from '../utils/QueryUtils';
import { BaseService } from './BaseService';

export class PropertyService {
  private _baseInstance: BaseService;
  private static _instance: PropertyService;
  private api: PropertyApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new PropertyApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll(filters: PropertyApiFindPropertiesRequest = {}) {
    const req: PropertyApiFindPropertiesRequest = {
      customerId: this._baseInstance.CustomerId,
      nameStartsWith: undefined,
      createdOnOrAfter: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
      ownerId: undefined,
      propertyGroupId: undefined,
      regionGroupId: undefined,
      regionId: undefined,
      ...filters,
    };

    return from(this.api.findProperties(req))
      .pipe(
        expand(apiResponse => {
          if (!apiResponse.data.nextUrl) {
            return EMPTY;
          }
          const skip = QueryHelper.GetSkipFrom(apiResponse.data.nextUrl);
          const take = QueryHelper.GetTakeFrom(apiResponse.data.nextUrl);
          const newReq = { ...req, skip: skip, take: take };
          return this.api.findProperties(newReq);
        }),
        reduce((accData, data) => {
          if (data.status !== 200) return data;
          accData.data.pageRecords += data.data.pageRecords;
          accData.data.data = accData.data.data.concat(data.data.data);
          return accData;
        }),
      )
      .toPromise();
  }

  public getById(id: number, version: number) {
    const req: PropertyApiGetPropertyRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.getProperty(req);
  }

  public getByRegionIds(regionIds: number) {
    const req: PropertyApiFindPropertiesRequest = {
      customerId: this._baseInstance.CustomerId,
      nameStartsWith: undefined,
      createdOnOrAfter: undefined,
      ownerId: undefined,
      propertyGroupId: undefined,
      regionGroupId: undefined,
      regionIds: regionIds,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findProperties(req);
  }

  public getByRegionId(regionId: number) {
    const req: PropertyApiFindPropertiesRequest = {
      customerId: this._baseInstance.CustomerId,
      nameStartsWith: undefined,
      createdOnOrAfter: undefined,
      ownerId: undefined,
      propertyGroupId: undefined,
      regionGroupId: undefined,
      regionId: regionId,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findProperties(req);
  }

  public activateProperty(id: number, version: number) {
    const req: PropertyApiActivatePropertyRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.activateProperty(req);
  }

  public deactivateProperty(id: number, version: number) {
    const req: PropertyApiDeactivatePropertyRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deactivateProperty(req);
  }

  public restoreProperty(id: number, version: number) {
    const req: PropertyApiRestorePropertyRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.restoreProperty(req);
  }

  public deactivateProperties(properties: PropertyReferenceDto[]) {
    const req: PropertyApiBulkDeactivatePropertiesRequest = {
      bulkDeactivatePropertiesCommandDto: {
        applyToProperties: properties,
      },
    };
    return this.api.bulkDeactivateProperties(req);
  }

  public activateProperties(properties: PropertyReferenceDto[]) {
    const req: PropertyApiBulkActivatePropertiesRequest = {
      bulkActivatePropertiesCommandDto: {
        applyToProperties: properties,
      },
    };
    return this.api.bulkActivateProperties(req);
  }

  public archiveProperties(properties: PropertyReferenceDto[]) {
    const req: PropertyApiBulkArchivePropertiesRequest = {
      bulkArchivePropertiesCommandDto: {
        applyToProperties: properties,
      },
    };
    return this.api.bulkArchiveProperties(req);
  }

  public restoreProperties(properties: PropertyReferenceDto[]) {
    const req: PropertyApiBulkRestorePropertiesRequest = {
      bulkRestorePropertiesCommandDto: {
        applyToProperties: properties,
      },
    };
    return this.api.bulkRestoreProperties(req);
  }

  public archive(id: number, version: number) {
    const req: PropertyApiArchivePropertyRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.archiveProperty(req);
  }

  public create(property: PropertyDto) {
    const req: PropertyApiCreatePropertyRequest = {
      customer: this._baseInstance.CustomerId,
      createPropertyCommandDto: { ...property, customer: this._baseInstance.CustomerId },
    };
    return this.api.createProperty(req);
  }

  public update(id: number, property: UpdatePropertyInfoCommandDto) {
    const req: PropertyApiUpdatePropertyInfoRequest = {
      id: id,
      ifMatch: property.version ?? 1,
      updatePropertyInfoCommandDto: property,
    };

    return this.api.updatePropertyInfo(req);
  }
}
