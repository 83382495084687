/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck
type apiState = {
  regionGroupFilter: object;
  regionFilter: object;
  unitTagFilter: object;
  unitFilter: any;
  staffTagFilter: object;
  staffFilter: any;
};

export default function filterAPIReducer(state: apiState = {}, action) {
  const { type, payload } = action;
  if (type.startsWith('RESPONSE_READ_REGION_GROUP_FILTER')) {
    // console.log("RESPONSE_READ_REGION_GROUP_FILTER")
    // console.log(payload.data.payload)
    state.regionGroupFilter = payload.data.payload;
    return {
      ...state,
    };
  } else if (type == 'RESPONSE_READ_REGION_FILTER') {
    console.log('RESPONSE_READ_REGION_FILTER');
    console.log(payload);
    state.regionFilter = payload.data.payload;
    return {
      ...state,
    };
  } else if (type == 'SUCCESS_READ_UNIT_TAGS_FILTER') {
    state.unitTagFilter = payload.data.payload;
    return {
      ...state,
    };
  } else if (type.startsWith('RESPONSE_READ_UNIT_FILTER')) {
    console.log('RESPONSE_READ_UNIT_FILTER');
    console.log(payload);
    state.unitFilter = payload.data.payload;
    return {
      ...state,
    };
  } else if (type == 'SUCCESS_READ_STAFF_TAGS_FILTER') {
    state.staffTagFilter = payload.data.payload;
    return {
      ...state,
    };
  } else if (type == 'SUCCESS_READ_STAFF_FILTER') {
    state.staffFilter = payload.data.payload;
    return {
      ...state,
    };
  }
  return state;
}
