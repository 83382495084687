/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateRegionGroupCommandDto } from '../models';
// @ts-ignore
import { MoveRegionGroupCommandDto } from '../models';
// @ts-ignore
import { RegionGroupDto } from '../models';
// @ts-ignore
import { RegionGroupQueryResult } from '../models';
// @ts-ignore
import { UpdateRegionGroupCommandDto } from '../models';
// @ts-ignore
import { UpdateRegionGroupLabelCommandDto } from '../models';
/**
 * RegionGroupApi - axios parameter creator
 * @export
 */
export const RegionGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Region Group Command
         * @param {CreateRegionGroupCommandDto} createRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegionGroup: async (createRegionGroupCommandDto: CreateRegionGroupCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRegionGroupCommandDto' is not null or undefined
            assertParamExists('createRegionGroup', 'createRegionGroupCommandDto', createRegionGroupCommandDto)
            const localVarPath = `/v1/regiongroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegionGroupCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Region Group Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionGroup: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRegionGroup', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteRegionGroup', 'ifMatch', ifMatch)
            const localVarPath = `/v1/regiongroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Region Groups
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRegionGroups: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/regiongroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Region Group by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionGroup: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegionGroup', 'id', id)
            const localVarPath = `/v1/regiongroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Move Region Group Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MoveRegionGroupCommandDto} moveRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveRegionGroup: async (id: number, ifMatch: number, moveRegionGroupCommandDto: MoveRegionGroupCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveRegionGroup', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('moveRegionGroup', 'ifMatch', ifMatch)
            // verify required parameter 'moveRegionGroupCommandDto' is not null or undefined
            assertParamExists('moveRegionGroup', 'moveRegionGroupCommandDto', moveRegionGroupCommandDto)
            const localVarPath = `/v1/regiongroup/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveRegionGroupCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Region Group Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionGroupCommandDto} updateRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionGroup: async (id: number, ifMatch: number, updateRegionGroupCommandDto: UpdateRegionGroupCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegionGroup', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateRegionGroup', 'ifMatch', ifMatch)
            // verify required parameter 'updateRegionGroupCommandDto' is not null or undefined
            assertParamExists('updateRegionGroup', 'updateRegionGroupCommandDto', updateRegionGroupCommandDto)
            const localVarPath = `/v1/regiongroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegionGroupCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Region Group Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionGroupLabelCommandDto} updateRegionGroupLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionGroupLabel: async (id: number, ifMatch: number, updateRegionGroupLabelCommandDto: UpdateRegionGroupLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegionGroupLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateRegionGroupLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updateRegionGroupLabelCommandDto' is not null or undefined
            assertParamExists('updateRegionGroupLabel', 'updateRegionGroupLabelCommandDto', updateRegionGroupLabelCommandDto)
            const localVarPath = `/v1/regiongroup/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegionGroupLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionGroupApi - functional programming interface
 * @export
 */
export const RegionGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Region Group Command
         * @param {CreateRegionGroupCommandDto} createRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegionGroup(createRegionGroupCommandDto: CreateRegionGroupCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegionGroup(createRegionGroupCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.createRegionGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Region Group Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegionGroup(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegionGroup(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.deleteRegionGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Region Groups
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRegionGroups(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionGroupQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRegionGroups(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.findRegionGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Region Group by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionGroup(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionGroup(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.getRegionGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Move Region Group Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MoveRegionGroupCommandDto} moveRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveRegionGroup(id: number, ifMatch: number, moveRegionGroupCommandDto: MoveRegionGroupCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveRegionGroup(id, ifMatch, moveRegionGroupCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.moveRegionGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Region Group Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionGroupCommandDto} updateRegionGroupCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegionGroup(id: number, ifMatch: number, updateRegionGroupCommandDto: UpdateRegionGroupCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegionGroup(id, ifMatch, updateRegionGroupCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.updateRegionGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Region Group Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateRegionGroupLabelCommandDto} updateRegionGroupLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegionGroupLabel(id: number, ifMatch: number, updateRegionGroupLabelCommandDto: UpdateRegionGroupLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegionGroupLabel(id, ifMatch, updateRegionGroupLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RegionGroupApi.updateRegionGroupLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RegionGroupApi - factory interface
 * @export
 */
export const RegionGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Region Group Command
         * @param {RegionGroupApiCreateRegionGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegionGroup(requestParameters: RegionGroupApiCreateRegionGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createRegionGroup(requestParameters.createRegionGroupCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Region Group Command
         * @param {RegionGroupApiDeleteRegionGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionGroup(requestParameters: RegionGroupApiDeleteRegionGroupRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRegionGroup(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Region Groups
         * @param {RegionGroupApiFindRegionGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRegionGroups(requestParameters: RegionGroupApiFindRegionGroupsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RegionGroupQueryResult> {
            return localVarFp.findRegionGroups(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Region Group by ID
         * @param {RegionGroupApiGetRegionGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionGroup(requestParameters: RegionGroupApiGetRegionGroupRequest, options?: AxiosRequestConfig): AxiosPromise<RegionGroupDto> {
            return localVarFp.getRegionGroup(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Move Region Group Sort Order Command
         * @param {RegionGroupApiMoveRegionGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveRegionGroup(requestParameters: RegionGroupApiMoveRegionGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.moveRegionGroup(requestParameters.id, requestParameters.ifMatch, requestParameters.moveRegionGroupCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Region Group Command
         * @param {RegionGroupApiUpdateRegionGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionGroup(requestParameters: RegionGroupApiUpdateRegionGroupRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateRegionGroup(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionGroupCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Region Group Label Command
         * @param {RegionGroupApiUpdateRegionGroupLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegionGroupLabel(requestParameters: RegionGroupApiUpdateRegionGroupLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateRegionGroupLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionGroupLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRegionGroup operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiCreateRegionGroupRequest
 */
export interface RegionGroupApiCreateRegionGroupRequest {
    /**
     * 
     * @type {CreateRegionGroupCommandDto}
     * @memberof RegionGroupApiCreateRegionGroup
     */
    readonly createRegionGroupCommandDto: CreateRegionGroupCommandDto
}

/**
 * Request parameters for deleteRegionGroup operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiDeleteRegionGroupRequest
 */
export interface RegionGroupApiDeleteRegionGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiDeleteRegionGroup
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiDeleteRegionGroup
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findRegionGroups operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiFindRegionGroupsRequest
 */
export interface RegionGroupApiFindRegionGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiFindRegionGroups
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof RegionGroupApiFindRegionGroups
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiFindRegionGroups
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiFindRegionGroups
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof RegionGroupApiFindRegionGroups
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getRegionGroup operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiGetRegionGroupRequest
 */
export interface RegionGroupApiGetRegionGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiGetRegionGroup
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiGetRegionGroup
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for moveRegionGroup operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiMoveRegionGroupRequest
 */
export interface RegionGroupApiMoveRegionGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiMoveRegionGroup
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiMoveRegionGroup
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MoveRegionGroupCommandDto}
     * @memberof RegionGroupApiMoveRegionGroup
     */
    readonly moveRegionGroupCommandDto: MoveRegionGroupCommandDto
}

/**
 * Request parameters for updateRegionGroup operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiUpdateRegionGroupRequest
 */
export interface RegionGroupApiUpdateRegionGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiUpdateRegionGroup
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiUpdateRegionGroup
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateRegionGroupCommandDto}
     * @memberof RegionGroupApiUpdateRegionGroup
     */
    readonly updateRegionGroupCommandDto: UpdateRegionGroupCommandDto
}

/**
 * Request parameters for updateRegionGroupLabel operation in RegionGroupApi.
 * @export
 * @interface RegionGroupApiUpdateRegionGroupLabelRequest
 */
export interface RegionGroupApiUpdateRegionGroupLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiUpdateRegionGroupLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof RegionGroupApiUpdateRegionGroupLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateRegionGroupLabelCommandDto}
     * @memberof RegionGroupApiUpdateRegionGroupLabel
     */
    readonly updateRegionGroupLabelCommandDto: UpdateRegionGroupLabelCommandDto
}

/**
 * RegionGroupApi - object-oriented interface
 * @export
 * @class RegionGroupApi
 * @extends {BaseAPI}
 */
export class RegionGroupApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Region Group Command
     * @param {RegionGroupApiCreateRegionGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public createRegionGroup(requestParameters: RegionGroupApiCreateRegionGroupRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).createRegionGroup(requestParameters.createRegionGroupCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Region Group Command
     * @param {RegionGroupApiDeleteRegionGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public deleteRegionGroup(requestParameters: RegionGroupApiDeleteRegionGroupRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).deleteRegionGroup(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Region Groups
     * @param {RegionGroupApiFindRegionGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public findRegionGroups(requestParameters: RegionGroupApiFindRegionGroupsRequest = {}, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).findRegionGroups(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Region Group by ID
     * @param {RegionGroupApiGetRegionGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public getRegionGroup(requestParameters: RegionGroupApiGetRegionGroupRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).getRegionGroup(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Move Region Group Sort Order Command
     * @param {RegionGroupApiMoveRegionGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public moveRegionGroup(requestParameters: RegionGroupApiMoveRegionGroupRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).moveRegionGroup(requestParameters.id, requestParameters.ifMatch, requestParameters.moveRegionGroupCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Region Group Command
     * @param {RegionGroupApiUpdateRegionGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public updateRegionGroup(requestParameters: RegionGroupApiUpdateRegionGroupRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).updateRegionGroup(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionGroupCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Region Group Label Command
     * @param {RegionGroupApiUpdateRegionGroupLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionGroupApi
     */
    public updateRegionGroupLabel(requestParameters: RegionGroupApiUpdateRegionGroupLabelRequest, options?: AxiosRequestConfig) {
        return RegionGroupApiFp(this.configuration).updateRegionGroupLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updateRegionGroupLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

