// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  RegionApi,
  RegionApiAssignRegionGroupToRegionRequest,
  RegionApiAssignTimeZoneToRegionRequest,
  RegionApiCreateRegionRequest,
  RegionApiDeleteRegionGroup1Request,
  RegionApiFindRegionsRequest,
  RegionApiGetRegionRequest,
  RegionApiMoveRegionRequest,
  RegionApiSetRegionColorRequest,
  RegionApiUpdateRegionLabelRequest,
  RegionApiUpdateRegionRequest,
} from '../teams-openapi';
import { BaseService } from './BaseService';

export class RegionService {
  private _baseInstance: BaseService;
  private static _instance: RegionService;
  private api: RegionApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new RegionApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: RegionApiFindRegionsRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findRegions(req);
  }

  public getAllForRegionGroups(regionGroupIds: number[]) {
    const req: RegionApiFindRegionsRequest = {
      customerId: this._baseInstance.CustomerId,
      regionGroupIds: regionGroupIds,
    };
    return this.api.findRegions(req);
  }

  public getById(id: number, version) {
    const req: RegionApiGetRegionRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.getRegion(req);
  }

  public update(
    id: number,
    version: number,
    color: string,
    label: string,
    regionGroupId: number,
    timeZoneId: number,
  ) {
    const req: RegionApiUpdateRegionRequest = {
      id: id,
      ifMatch: version,
      updateRegionCommandDto: {
        hexColor: color,
        label: label,
        regionGroupId: regionGroupId,
        timeZoneId: timeZoneId,
      },
    };

    return this.api.updateRegion(req);
  }

  public updateColor(id: number, version: number, color: string) {
    const req: RegionApiSetRegionColorRequest = {
      id: id,
      ifMatch: version,
      setRegionColorCommandDto: { hexColor: color },
    };
    return this.api.setRegionColor(req);
  }

  public updateLabel(id: number, version: number, label: string) {
    const req: RegionApiUpdateRegionLabelRequest = {
      id: id,
      ifMatch: version,
      updateRegionLabelCommandDto: { label: label },
    };

    return this.api.updateRegionLabel(req);
  }

  public updateSortOrder(id: number, version: number, sortOrder: number) {
    const req: RegionApiMoveRegionRequest = {
      id: id,
      ifMatch: version,
      moveRegionCommandDto: { moveToSortOrder: sortOrder },
    };
    return this.api.moveRegion(req);
  }

  public assignRegionGroup(id: number, version: number, regionGroupId: number) {
    const req: RegionApiAssignRegionGroupToRegionRequest = {
      id: id,
      ifMatch: version,
      assignRegionGroupToRegionCommandDto: { regionGroupId: regionGroupId },
    };
    return this.api.assignRegionGroupToRegion(req);
  }

  public assignTimezone(id: number, version: number, timeZoneId: number) {
    const req: RegionApiAssignTimeZoneToRegionRequest = {
      id: id,
      ifMatch: version,
      assignTimeZoneToRegionCommandDto: { timeZoneId: timeZoneId },
    };
    return this.api.assignTimeZoneToRegion(req);
  }

  public delete(id: number, version: number) {
    const req: RegionApiDeleteRegionGroup1Request = {
      id: id,
      ifMatch: version,
    };
    return this.api.deleteRegionGroup1(req);
  }

  public create(color: string, label: string, regionGroupId: number, timeZoneId: number) {
    const req: RegionApiCreateRegionRequest = {
      createRegionCommandDto: {
        customerId: this._baseInstance.CustomerId,
        hexColor: color,
        label: label,
        regionGroupId: regionGroupId,
        timeZoneId: timeZoneId,
      },
    };
    return this.api.createRegion(req);
  }
}
