import { TeamsConst } from 'constants/Base';
import {
  MasterCalendarApi,
  MasterCalendarApiDeletePropertyBlockRequest,
  MasterCalendarApiGetPropertyBlockRequest,
} from 'teams-openapi';
import { BaseService } from './BaseService';

export class BlockService {
  private _baseInstance: BaseService;
  private static _instance: BlockService;
  private api: MasterCalendarApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getBlocks(filters: MasterCalendarApiGetPropertyBlockRequest) {
    return this.api.getPropertyBlock(filters);
  }

  public deleteBlock = (filters: MasterCalendarApiDeletePropertyBlockRequest) => {
    return this.api.deletePropertyBlock(filters);
  };
}
