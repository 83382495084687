import { createTheme } from '@mui/material/styles';

const PastelCandy = createTheme({
  palette: {
    primary: {
      main: '#004EFF',
      contrastText: '#fff',
      dark: '#37598C',
    },
    secondary: {
      main: '#37598C',
      light: '#C5C6CA',
    },
    background: {
      default: '#caffbf',
    },
    text: {
      primary: '#004EFF',
    },
  },
});

export default PastelCandy;
