/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
//@ts-nocheck
type apiState = {
  permissions: object;
};

export default function permissionsAPIReducer(state: apiState = {}, action) {
  const { type, payload } = action;
  if (type.startsWith('SUCCESS_READ_GET_PERMISSIONS')) {
    state.permissions = payload;
    return {
      ...state,
    };
  }
  if (type == 'UPDATE_PERMISSION') {
    return {
      ...state,
      permissions: {
        ...state.permissions,
        data: {
          ...state.permissions.data,
          rows: [...payload],
        },
      },
    };
  }
  return state;
}
