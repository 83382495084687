/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateStaffTagCommandDto } from '../models';
// @ts-ignore
import { QueryResultStaffTagDto } from '../models';
// @ts-ignore
import { StaffTagDto } from '../models';
// @ts-ignore
import { UpdateStaffTagCommandDto } from '../models';
/**
 * StaffTagApi - axios parameter creator
 * @export
 */
export const StaffTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Staff Tag Command
         * @param {CreateStaffTagCommandDto} createStaffTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffTag: async (createStaffTagCommandDto: CreateStaffTagCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStaffTagCommandDto' is not null or undefined
            assertParamExists('createStaffTag', 'createStaffTagCommandDto', createStaffTagCommandDto)
            const localVarPath = `/v1/stafftag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStaffTagCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Staff Tag Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffTag: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStaffTag', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteStaffTag', 'ifMatch', ifMatch)
            const localVarPath = `/v1/stafftag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Staff Tags
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStaffTags: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stafftag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Staff Tag by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffTag: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStaffTag', 'id', id)
            const localVarPath = `/v1/stafftag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Staff Tag Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateStaffTagCommandDto} updateStaffTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffTag: async (id: number, ifMatch: number, updateStaffTagCommandDto: UpdateStaffTagCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStaffTag', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateStaffTag', 'ifMatch', ifMatch)
            // verify required parameter 'updateStaffTagCommandDto' is not null or undefined
            assertParamExists('updateStaffTag', 'updateStaffTagCommandDto', updateStaffTagCommandDto)
            const localVarPath = `/v1/stafftag/{id}/label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStaffTagCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffTagApi - functional programming interface
 * @export
 */
export const StaffTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Staff Tag Command
         * @param {CreateStaffTagCommandDto} createStaffTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStaffTag(createStaffTagCommandDto: CreateStaffTagCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStaffTag(createStaffTagCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffTagApi.createStaffTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Staff Tag Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStaffTag(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStaffTag(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffTagApi.deleteStaffTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Staff Tags
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStaffTags(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultStaffTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStaffTags(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffTagApi.findStaffTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Staff Tag by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffTag(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffTag(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffTagApi.getStaffTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Staff Tag Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateStaffTagCommandDto} updateStaffTagCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStaffTag(id: number, ifMatch: number, updateStaffTagCommandDto: UpdateStaffTagCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStaffTag(id, ifMatch, updateStaffTagCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffTagApi.updateStaffTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StaffTagApi - factory interface
 * @export
 */
export const StaffTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffTagApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Staff Tag Command
         * @param {StaffTagApiCreateStaffTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffTag(requestParameters: StaffTagApiCreateStaffTagRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createStaffTag(requestParameters.createStaffTagCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Staff Tag Command
         * @param {StaffTagApiDeleteStaffTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffTag(requestParameters: StaffTagApiDeleteStaffTagRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteStaffTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Staff Tags
         * @param {StaffTagApiFindStaffTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStaffTags(requestParameters: StaffTagApiFindStaffTagsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultStaffTagDto> {
            return localVarFp.findStaffTags(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Staff Tag by ID
         * @param {StaffTagApiGetStaffTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffTag(requestParameters: StaffTagApiGetStaffTagRequest, options?: AxiosRequestConfig): AxiosPromise<StaffTagDto> {
            return localVarFp.getStaffTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Staff Tag Label Command
         * @param {StaffTagApiUpdateStaffTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffTag(requestParameters: StaffTagApiUpdateStaffTagRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateStaffTag(requestParameters.id, requestParameters.ifMatch, requestParameters.updateStaffTagCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createStaffTag operation in StaffTagApi.
 * @export
 * @interface StaffTagApiCreateStaffTagRequest
 */
export interface StaffTagApiCreateStaffTagRequest {
    /**
     * 
     * @type {CreateStaffTagCommandDto}
     * @memberof StaffTagApiCreateStaffTag
     */
    readonly createStaffTagCommandDto: CreateStaffTagCommandDto
}

/**
 * Request parameters for deleteStaffTag operation in StaffTagApi.
 * @export
 * @interface StaffTagApiDeleteStaffTagRequest
 */
export interface StaffTagApiDeleteStaffTagRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffTagApiDeleteStaffTag
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof StaffTagApiDeleteStaffTag
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findStaffTags operation in StaffTagApi.
 * @export
 * @interface StaffTagApiFindStaffTagsRequest
 */
export interface StaffTagApiFindStaffTagsRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffTagApiFindStaffTags
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof StaffTagApiFindStaffTags
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof StaffTagApiFindStaffTags
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof StaffTagApiFindStaffTags
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof StaffTagApiFindStaffTags
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getStaffTag operation in StaffTagApi.
 * @export
 * @interface StaffTagApiGetStaffTagRequest
 */
export interface StaffTagApiGetStaffTagRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffTagApiGetStaffTag
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof StaffTagApiGetStaffTag
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for updateStaffTag operation in StaffTagApi.
 * @export
 * @interface StaffTagApiUpdateStaffTagRequest
 */
export interface StaffTagApiUpdateStaffTagRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffTagApiUpdateStaffTag
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof StaffTagApiUpdateStaffTag
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateStaffTagCommandDto}
     * @memberof StaffTagApiUpdateStaffTag
     */
    readonly updateStaffTagCommandDto: UpdateStaffTagCommandDto
}

/**
 * StaffTagApi - object-oriented interface
 * @export
 * @class StaffTagApi
 * @extends {BaseAPI}
 */
export class StaffTagApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Staff Tag Command
     * @param {StaffTagApiCreateStaffTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffTagApi
     */
    public createStaffTag(requestParameters: StaffTagApiCreateStaffTagRequest, options?: AxiosRequestConfig) {
        return StaffTagApiFp(this.configuration).createStaffTag(requestParameters.createStaffTagCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Staff Tag Command
     * @param {StaffTagApiDeleteStaffTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffTagApi
     */
    public deleteStaffTag(requestParameters: StaffTagApiDeleteStaffTagRequest, options?: AxiosRequestConfig) {
        return StaffTagApiFp(this.configuration).deleteStaffTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Staff Tags
     * @param {StaffTagApiFindStaffTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffTagApi
     */
    public findStaffTags(requestParameters: StaffTagApiFindStaffTagsRequest = {}, options?: AxiosRequestConfig) {
        return StaffTagApiFp(this.configuration).findStaffTags(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Staff Tag by ID
     * @param {StaffTagApiGetStaffTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffTagApi
     */
    public getStaffTag(requestParameters: StaffTagApiGetStaffTagRequest, options?: AxiosRequestConfig) {
        return StaffTagApiFp(this.configuration).getStaffTag(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Staff Tag Label Command
     * @param {StaffTagApiUpdateStaffTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffTagApi
     */
    public updateStaffTag(requestParameters: StaffTagApiUpdateStaffTagRequest, options?: AxiosRequestConfig) {
        return StaffTagApiFp(this.configuration).updateStaffTag(requestParameters.id, requestParameters.ifMatch, requestParameters.updateStaffTagCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

