import { Box, Skeleton, SxProps } from '@mui/material';
import { HeadingSkeleton } from './HeadingSkeleton';
import {
  SkeletonLeftSidebar,
  SkeletonMasterCalendarRightSidebar,
  SkeletonVerticalElementsList,
} from './SkeletonComponents';

const BASE_HEIGHT = 30;
const CIRCULAR_WIDTH = 30;
const ROUNDED_WIDTH = 30;
const SMALL_LOGO_SIZE = 40;

export const SkeletonBase = () => {
  const windowHeight = window.innerHeight;
  const numSkeletonElements = Math.floor((windowHeight - 190) / 60);

  const skeletonElements = Array.from({ length: numSkeletonElements }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Box key={index}>
      <Skeleton sx={ElementSx} variant='rounded' height={60} />
    </Box>
  ));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
      }}
    >
      <HeadingSkeleton />
      <Box sx={{ width: '100vw', display: 'flex', height: '100%' }} padding='0 10px'>
        <Box sx={{ width: '25%' }}>
          <Box>{skeletonElements}</Box>
        </Box>

        <Box sx={{ width: '75%', padding: '0 10px' }}>
          <Box>{skeletonElements}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export const SkeletonMasterCalendarPage = () => {
  const windowHeight = window.innerHeight;
  const numSkeletonElements = Math.floor((windowHeight - 200) / 60);

  const skeletonElements = Array.from({ length: numSkeletonElements }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Box key={index}>
      <Skeleton sx={headingElementSx} variant='rounded' width='95%' height={60} />
    </Box>
  ));

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <Box width='25%'>
        <Box display='flex' alignItems='center'>
          <Box width={CIRCULAR_WIDTH}>
            <Skeleton
              sx={headingElementSx}
              variant='circular'
              width={CIRCULAR_WIDTH}
              height={BASE_HEIGHT}
            />
          </Box>
          <Box width='100%' padding='0 10px'>
            <Skeleton sx={headingElementSx} variant='text' height={15} />
          </Box>
        </Box>
        {SkeletonLeftSidebar()}
      </Box>
      <Box width='75%'>{SkeletonMasterCalendarRightSidebar()}</Box>
    </Box>
  );
};

export const SkeletonPropertyPage = () => {
  const roundedElementsCount = 6;
  const headingList = Array.from({ length: roundedElementsCount }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Skeleton sx={headingElementSx} variant='rounded' height={30} width={100} key={index} />
  ));

  return (
    <Box>
      <Box sx={headingPageSx}>
        <Box display='flex'>
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
        </Box>
        <Box width='100%' display='flex'>
          {headingList}
        </Box>
      </Box>
      <Box>{SkeletonVerticalElementsList()}</Box>
    </Box>
  );
};

export const SkeletonArrivalDeparturePage = () => {
  return (
    <Box>
      <Box sx={headingPageSx}>
        <Box sx={headingLeftSidebarSx}>
          <Skeleton sx={headingElementSx} variant='rounded' width={60} height={BASE_HEIGHT} />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
        </Box>
        <Box sx={headingRightSidebarSx}>
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton sx={headingElementSx} variant='rounded' width={60} height={BASE_HEIGHT} />
        </Box>
      </Box>
      <Box>{SkeletonVerticalElementsList()}</Box>
    </Box>
  );
};

export const SkeletonCheckInOutePage = () => {
  const windowHeight = window.innerHeight;
  const numSkeletonElements = Math.floor((windowHeight - 600) / 60);

  const listOfELements = Array.from({ length: numSkeletonElements }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Box key={index}>
      <Skeleton sx={headingElementSx} variant='rounded' width='100%' height={50} />
    </Box>
  ));

  return (
    <Box>
      <Box sx={headingPageSx}>
        <Box sx={headingLeftSidebarSx}>
          <Skeleton sx={headingElementSx} variant='rounded' width={60} height={BASE_HEIGHT} />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
        </Box>
        <Box sx={headingRightSidebarSx}>
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton sx={headingElementSx} variant='rounded' width={60} height={BASE_HEIGHT} />
        </Box>
      </Box>
      <Box>{listOfELements}</Box>
    </Box>
  );
};

export const SkeletonDepartmentPage = () => {
  return (
    <Box>
      <Box sx={headingPageSx}>
        <Box sx={headingLeftSidebarSx}>
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
          <Skeleton
            sx={headingElementSx}
            variant='circular'
            width={CIRCULAR_WIDTH}
            height={CIRCULAR_WIDTH}
          />
        </Box>
      </Box>
      <Box>{SkeletonVerticalElementsList()}</Box>
    </Box>
  );
};

export const SkeletonPropertyItemsUtilityPage = () => {
  return (
    <Box>
      <Box sx={headingPageSx}>
        <Box sx={headingLeftSidebarSx} width='100%'>
          <Skeleton sx={headingElementSx} variant='text' width={70} height={BASE_HEIGHT} />
          <Skeleton sx={headingElementSx} variant='text' width={70} height={BASE_HEIGHT} />
        </Box>
      </Box>
      <Box>{SkeletonVerticalElementsList()}</Box>
    </Box>
  );
};

const headingLeftSidebarSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const headingRightSidebarSx: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
};

const headingPageSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  height: '65px',
};

const headingElementSx: SxProps = {
  margin: '10px',
};
const ElementSx: SxProps = {
  margin: '10px',
  width: '100%',
};
