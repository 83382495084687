// MAIN MENU
export const MAIN_MENU_ISSUES = 'main-menu-issues';
export const MAIN_MENU_QUICK_REPORTS = 'main-menu-quick-reports';
export const MAIN_MENU_QUICK_REPORTS_ARRIVALS = 'main-menu-quick-reports-arrivals';
export const MAIN_MENU_QUICK_REPORTS_DEPARTURES = 'main-menu-quick-reports-departures';
export const MAIN_MENU_QUICK_REPORTS_CHECK_IN_OUT = 'main-menu-quick-reports-checkinout';
export const MAIN_MENU_QUICK_REPORTS_PROPERTY_STATUS = 'main-menu-quick-reports-property-status';
export const MAIN_MENU_QUICK_REPORTS_LINEN_PACKING = 'main-menu-quick-reports-linen-packing';
export const MAIN_MENU_QUICK_REPORTS_TASKS = 'main-menu-quick-reports-tasks';
export const MAIN_MENU_QUICK_REPORTS_PIECE_PAY_REVIEW = 'main-menu-quick-reports-piece-pay-review';
export const MAIN_MENU_QUICK_REPORTS_BLENDED_PAY_REVIEW =
  'main-menu-quick-reports-blended-pay-review';
export const MAIN_MENU_QUICK_REPORTS_FORECASTING = 'main-menu-quick-reports-forecasting';
export const MAIN_MENU_TRACKING = 'main-menu-tracking';
export const MAIN_MENU_SCHEDULING = 'main-menu-scheduling';
export const MAIN_MENU_SCHEDULE = 'main-menu-schedule';
export const MAIN_MENU_MASTER_CALENDAR = 'main-menu-master-calendar';
export const MAIN_MENU_BOOKINGS = 'main-menu-bookings';
export const MAIN_MENU_FILTERS = 'main-menu-filters';
export const MAIN_MENU_RHS_MORE = 'main-menu-more';
export const MAIN_MENU_RHS_MORE_MANAGE = 'main-menu-more-manage';
export const MAIN_MENU_RHS_MORE_STRIPE_OWNER_BOOKING = 'main-menu-more-stripe-owner-booking';
export const MAIN_MENU_RHS_MORE_QUICKBOOKS = 'main-menu-more-quickbooks';
export const MAIN_MENU_RHS_MORE_TIME_REPORT_QB = 'main-menu-more-timereport-qb';
export const MAIN_MENU_RHS_REPORTS = 'main-menu-reports';
export const MAIN_MENU_RHS_REPORTS_TASK_HISTORY = 'main-menu-reports-task-history';
export const MAIN_MENU_RHS_REPORTS_ISSUES_HISTORY = 'main-menu-reports-issues-history';
export const MAIN_MENU_RHS_REPORTS_TASK_FORM_REPORTS = 'main-menu-reports-task-form-reports';
export const MAIN_MENU_RHS_REPORTS_TIME_TRACKING_TASKS = 'main-menu-reports-time-tracking-tasks';
export const MAIN_MENU_RHS_REPORTS_TIME_TRACKING_EMPLOYEE =
  'main-menu-reports-time-tracking-employee';
export const MAIN_MENU_RHS_REPORTS_TIME_TRACKING_ALL = 'main-menu-reports-time-tracking-all';
export const MAIN_MENU_RHS_REPORTS_TIME_TRACKING_DETAIL = 'main-menu-reports-time-tracking-detail';
export const MAIN_MENU_RHS_REPORTS_PIECE_PAY = 'main-menu-reports-piece-pay';
export const MAIN_MENU_RHS_REPORTS_BLENDED_PAY = 'main-menu-reports-blended-pay';
export const MAIN_MENU_RHS_REPORTS_OWNER_STATEMENT_REPORT =
  'main-menu-reports-owner-statement-report';
export const MAIN_MENU_RHS_REPORTS_ACCEPT_DECLINE_REPORT =
  'main-menu-reports-accept-decline-report';
export const MAIN_MENU_RHS_REPORTS_BOOKING_HISTORY = 'main-menu-reports-booking-history';
export const MAIN_MENU_RHS_REPORTS_GUEST_HISTORY = 'main-menu-reports-guest-history';
export const MAIN_MENU_RHS_REPORTS_NOTIFICATION_HISTORY = 'main-menu-reports-notification-history';
export const MAIN_MENU_RHS_SETUP = 'main-menu-setup';
export const MAIN_MENU_RHS_SETUP_STAFF_OWNER_NOTIFICATIONS =
  'main-menu-setup-staff-owner-notifications';
export const MAIN_MENU_RHS_SETUP_ADMIN_NOTIFICATIONS = 'main-menu-setup-admin-notifications';
export const MAIN_MENU_RHS_SETUP_NOTIFCATION_RULES = 'main-menu-setup-notification-rules';
export const MAIN_MENU_RHS_SETUP_OWNER_NOTIFICATION_RULES =
  'main-menu-setup-owner-notification-rules';
export const MAIN_MENU_RHS_SETUP_TASK_RULES = 'main-menu-setup-task-rules';
export const MAIN_MENU_RHS_SETUP_TASK_FORMS = 'main-menu-setup-task-forms';
export const MAIN_MENU_RHS_SETUP_STAFF = 'main-menu-setup-staff';
export const MAIN_MENU_RHS_SETUP_PROPERTIES = 'main-menu-setup-properties';
export const MAIN_MENU_RHS_SETUP_REGIONS = 'main-menu-setup-regions';
export const MAIN_MENU_RHS_SETUP_OWNERS = 'main-menu-setup-owners';
export const MAIN_MENU_RHS_SETUP_VENDORS = 'main-menu-setup-vendors';
export const MAIN_MENU_RHS_SETUP_PROPERTY_STATUSES = 'main-menu-setup-property-statuses';
export const MAIN_MENU_RHS_SETUP_DEPARTMENTS = 'main-menu-setup-deparments';
export const MAIN_MENU_RHS_SETUP_STAFF_TAGS = 'main-menu-setup-staff-tags';
export const MAIN_MENU_RHS_SETUP_PROPERTY_TAGS = 'main-menu-setup-property-tags';
export const MAIN_MENU_RHS_SETUP_TASK_FORM_REPORT_TYPES = 'main-menu-setup-task-form-report-types';
export const MAIN_MENU_RHS_SETUP_SYSTEM_SETTINGS = 'main-menu-setup-system-settings';
export const MAIN_MENU_RHS_SETUP_ICAL_FEEDS = 'main-menu-setup-ical-feeds';
export const MAIN_MENU_RHS_SETUP_API_KEYS = 'main-menu-setup-api-keys';
export const MAIN_MENU_RHS_SETUP_ONBOARDING_STEPS = 'main-menu-setup-onboarding-steps';
export const MAIN_MENU_HELP = 'main-menu-help';
export const MAIN_MENU_ACCOUNT_SETTINGS = 'main-menu-account-settings';
export const DEVOPS = 'devops';

// PAGE SPECIFIC
export const REGIONS_VIEW = 'regions-view';
export const REGIONS_EDIT = 'regions-edit';
export const REGIONS_DELETE = 'regions-delete';

export const REGIONGROUP_VIEW = 'regiongroup-view';
export const REGIONGROUP_EDIT = 'regiongroup-edit';
export const REGIONGROUP_DELETE = 'regiongroup-delete';

export const PROPERTY_ITEM_TYPES_VIEW = 'property-item-types-view';
export const PROPERTY_ITEM_TYPES_EDIT = 'property-item-types-edit';
export const PROPERTY_ITEM_TYPES_DELETE = 'property-item-types-delete';

export const PROPERTY_UTILITY_TYPES_VIEW = 'property-utility-types-view';
export const PROPERTY_UTILITY_TYPES_EDIT = 'property-utility-types-edit';
export const PROPERTY_UTILITY_TYPES_DELETE = 'property-utility-types-delete';
