import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { MasterCalendarService } from '../../services/MasterCalendarService';
import { MasterCalendarPermissionDto } from '../../teams-openapi';
import { translateError } from '../../utils/ErrorManagement';

export interface PermissionsState {
  permissions: {
    masterCalendar?: MasterCalendarPermissionDto;
  };
  status: string;
  message: string;
}

const initialState: PermissionsState = {
  permissions: {},
  status: API_STATUS.IDLE,
  message: '',
};

export const getMasterCalendarPermissions = createAsyncThunk(
  'get-calendar-permissions',
  async (_: void, { rejectWithValue }) => {
    try {
      const dataResult = await MasterCalendarService.Instance.getPermissions();
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PermissionsState>) => {
  builder.addCase(getMasterCalendarPermissions.fulfilled, (state: PermissionsState, action) => {
    state.status = API_STATUS.SUCCESS;
    state.permissions.masterCalendar = action.payload.result;
  });
  builder.addCase(getMasterCalendarPermissions.pending, (state: PermissionsState) => {
    state.status = API_STATUS.PENDING;
  });
  builder.addCase(getMasterCalendarPermissions.rejected, (state: PermissionsState) => {
    state.status = API_STATUS.FAILURE;
  });
};

const permissionsSlice = createSlice({
  name: 'persmissions',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = permissionsSlice;
export default reducer;
