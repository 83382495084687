import { MasterCalendarPropertyDto } from '@/teams-openapi/models/master-calendar-property-dto';
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { MasterCalendarService } from 'services/MasterCalendarService';
import { MasterCalendarApiGetPropertyListRequest } from 'teams-openapi';
import { translateError } from 'utils/ErrorManagement';

export interface MasterCalendarPropertyState {
  fetchAllStatus: API_STATUS;
  result: MasterCalendarPropertyDto[];
}

const initialState: MasterCalendarPropertyState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
};

export const getMasterCalendarProperties = createAsyncThunk(
  'master-calendar-property',
  async (filters: MasterCalendarApiGetPropertyListRequest = {}, { rejectWithValue }) => {
    try {
      const dataResult = await MasterCalendarService.Instance.getMasterCalendarPropertyList(
        filters,
      );
      return { result: dataResult.data.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<MasterCalendarPropertyState>) => {
  builder.addCase(getMasterCalendarProperties.pending, (state: MasterCalendarPropertyState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(
    getMasterCalendarProperties.fulfilled,
    (state: MasterCalendarPropertyState, action) => {
      state.fetchAllStatus = API_STATUS.SUCCESS;
      state.result = action.payload.result || [];
    },
  );
  builder.addCase(getMasterCalendarProperties.rejected, (state: MasterCalendarPropertyState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });
};

const masterCalendarPropertySlice = createSlice({
  name: 'masterCalendarPropertySlice',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = masterCalendarPropertySlice;
export default reducer;
