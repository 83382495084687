export class QueryHelper {
  public static GetSkipFrom(queryUrl: string) {
    const url = new URL(queryUrl);
    const skipParam = url.searchParams.get('skip');
    if (skipParam == null) {
      return undefined;
    }

    const skipInt = parseInt(skipParam, 10);
    return skipInt;
  }

  public static GetTakeFrom(queryUrl: string) {
    const url = new URL(queryUrl);
    const topParam = url.searchParams.get('take');
    if (topParam == null) {
      return undefined;
    }

    const topInt = parseInt(topParam, 10);
    return topInt;
  }
}
