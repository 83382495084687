import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'constants/Constants';
import { MasterCalendarService } from 'services/MasterCalendarService';
import { MasterCalendarIssueCountDto } from 'teams-openapi';
import { translateError } from 'utils/ErrorManagement';

export interface MasterCalendarIssueCountState {
  fetchAllStatus: API_STATUS;
  result: MasterCalendarIssueCountDto[];
}

const initialState: MasterCalendarIssueCountState = {
  result: [],
  fetchAllStatus: API_STATUS.IDLE,
};

export const getIssueCount = createAsyncThunk(
  'master-calendar-issue-count',
  async (_: void, { rejectWithValue }) => {
    try {
      const dataResult = await MasterCalendarService.Instance.getIssueCount();
      return { result: dataResult.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<MasterCalendarIssueCountState>) => {
  builder.addCase(getIssueCount.pending, (state: MasterCalendarIssueCountState) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getIssueCount.fulfilled, (state: MasterCalendarIssueCountState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.result.data || [];
  });
  builder.addCase(getIssueCount.rejected, (state: MasterCalendarIssueCountState) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });
};

const masterCalendarIssueCountSlice = createSlice({
  name: 'masterCalendarIssueCountSlice',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = masterCalendarIssueCountSlice;
export default reducer;
