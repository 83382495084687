// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { DepartmentService } from '../../services/DepartmentService';
import { translateError } from '../../utils/ErrorManagement';

export interface DepartmentState {
  all: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getDepartment = createAsyncThunk(
  'department/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await DepartmentService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createDepartment = createAsyncThunk(
  'department/create',
  async (departmentLabel: any, { rejectWithValue }) => {
    try {
      const { label } = departmentLabel;
      const res = await DepartmentService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateDepartment = createAsyncThunk(
  'department/update',
  async (department: any, { rejectWithValue }) => {
    try {
      const res = await DepartmentService.Instance.update(department);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteDepartment = createAsyncThunk(
  'department/delete',
  async (department: any, { rejectWithValue }) => {
    try {
      const res = await DepartmentService.Instance.delete(department);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<DepartmentState>) => {
  // --------------- GET DEPARTMENT ---------------
  builder.addCase(getDepartment.pending, (state: DepartmentState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getDepartment.fulfilled, (state: DepartmentState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data;
    state.all = data;
  });
  builder.addCase(getDepartment.rejected, (state: DepartmentState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // --------------- CREATE NEW ---------------
  builder.addCase(createDepartment.pending, (state: DepartmentState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createDepartment.fulfilled, (state: DepartmentState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
  });
  builder.addCase(createDepartment.rejected, (state: DepartmentState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updateDepartment.pending, (state: DepartmentState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateDepartment.fulfilled, (state: DepartmentState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all.map(item => {
      const newItem = { ...item, label: label, version: newVersion };
      return item.id === action.payload.id ? newItem : item;
    });
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updateDepartment.rejected, (state: DepartmentState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- DELETE ---------------
  builder.addCase(deleteDepartment.pending, (state: DepartmentState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deleteDepartment.fulfilled, (state: DepartmentState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.all = state.all.filter(item => item.id !== id);
  });
  builder.addCase(deleteDepartment.rejected, (state: DepartmentState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setDepartmentToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = departmentSlice;
export const { resetCreateStatus, setDepartmentToEdit } = departmentSlice.actions;
export default reducer;
