import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import axios, { AxiosInstance } from 'axios';
import { AuthService } from './AuthService';
import { RegionGroupService } from './RegionGroupService';

export const setupApiServices = (store: ToolkitStore) => {
  const authService = AuthService.Instance;
  const regionGroupService = RegionGroupService.Instance;
};

export const mainAxiosInstance = () => {
  // const axiosInstance: AxiosInstance = axios.create({});
  // );
  // return axiosInstance;
};
