// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyUtilityTypeService } from '../../services/PropertyUtilityTypeService';
import { translateError } from '../../utils/ErrorManagement';

export interface PropertyUtilityTypeState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getPropertyUtilityType = createAsyncThunk(
  'propertyutilitytype/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyUtilityTypeService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getPropertyUtilityTypeReferences = createAsyncThunk(
  'propertyutilitytype/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyUtilityTypeService.Instance.getAllRefs();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createPropertyUtilityType = createAsyncThunk(
  'propertyutilitytype/create',
  async (propertyutilitytype: any, { rejectWithValue }) => {
    try {
      const { label } = propertyutilitytype;
      const res = await PropertyUtilityTypeService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);
export const updatePropertyUtilityType = createAsyncThunk(
  'propertyutilitytype/update',
  async (propertyUtilityType: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyUtilityType;
      const res = await PropertyUtilityTypeService.Instance.updateLabel(id, label, version);
      const latest = await PropertyUtilityTypeService.Instance.getById(id, res.data['version']);
      return latest;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyUtilityTypeLabel = createAsyncThunk(
  'propertyUtilityType/updateLabel',
  async (propertyUtilityType: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyUtilityType;
      const res = await PropertyUtilityTypeService.Instance.updateLabel(id, label, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyUtilityTypeSortOrder = createAsyncThunk(
  'propertyUtilityType/updateSortOrder',
  async (propertyUtilityType: any, { rejectWithValue }) => {
    try {
      const { id, sortOrder, version } = propertyUtilityType;
      const res = await PropertyUtilityTypeService.Instance.updateSortOrder(id, sortOrder, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deletePropertyUtilityType = createAsyncThunk(
  'propertyUtilityType/delete',
  async (toDelete: any, { rejectWithValue }) => {
    try {
      const { id, version } = toDelete;
      const res = await PropertyUtilityTypeService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyUtilityTypeState>) => {
  // --------------- GET Property Utility Type ---------------
  builder.addCase(getPropertyUtilityType.pending, (state: PropertyUtilityTypeState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertyUtilityType.fulfilled, (state: PropertyUtilityTypeState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = data;
  });
  builder.addCase(getPropertyUtilityType.rejected, (state: PropertyUtilityTypeState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  builder.addCase(
    getPropertyUtilityTypeReferences.pending,
    (state: PropertyUtilityTypeState, action) => {
      state.fetchRefsStatus = API_STATUS.PENDING;
    },
  );
  builder.addCase(
    getPropertyUtilityTypeReferences.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.fetchRefsStatus = API_STATUS.SUCCESS;
      const data = action.payload.data
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(ref => ({ id: ref.id, label: ref.label }));
      state.refs = data;
    },
  );
  builder.addCase(
    getPropertyUtilityTypeReferences.rejected,
    (state: PropertyUtilityTypeState, action) => {
      state.fetchRefsStatus = API_STATUS.FAILURE;
      state.refs = [];
    },
  );

  // --------------- CREATE NEW ---------------
  builder.addCase(createPropertyUtilityType.pending, (state: PropertyUtilityTypeState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(
    createPropertyUtilityType.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.createStatus = API_STATUS.SUCCESS;
      state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
    },
  );
  builder.addCase(createPropertyUtilityType.rejected, (state: PropertyUtilityTypeState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE  ---------------
  builder.addCase(updatePropertyUtilityType.pending, (state: PropertyUtilityTypeState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(
    updatePropertyUtilityType.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.SUCCESS;
      const { id, label, version: newVersion } = action.payload.data;
      state.all = state.all
        .map(item => {
          const newItem = { ...item, label: label, version: newVersion };
          return item.id === id ? newItem : item;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);

      state.currentItem = { ...state.currentItem, label: label, version: newVersion };
    },
  );
  builder.addCase(updatePropertyUtilityType.rejected, (state: PropertyUtilityTypeState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(
    updatePropertyUtilityTypeLabel.pending,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.PENDING;
    },
  );
  builder.addCase(
    updatePropertyUtilityTypeLabel.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.SUCCESS;
      const { label } = action.meta.arg;
      const newVersion = action.payload.version;
      state.all = state.all
        .map(item => {
          const newItem = { ...item, label: label, version: newVersion };
          return item.id === action.payload.id ? newItem : item;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
      state.currentItem = { ...state.currentItem, label: label, version: newVersion };
    },
  );
  builder.addCase(
    updatePropertyUtilityTypeLabel.rejected,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.FAILURE;
    },
  );

  // --------------- MOVE (UPDATE SORT INDEX) ---------------
  builder.addCase(
    updatePropertyUtilityTypeSortOrder.pending,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.PENDING;
    },
  );
  builder.addCase(
    updatePropertyUtilityTypeSortOrder.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.SUCCESS;
      const { sortOrder } = action.meta.arg;
      const newVersion = action.payload.version;
      state.all = state.all
        .map(item => {
          const newItem = { ...item, sortOrder: sortOrder, version: action.payload.version };
          return item.id === action.payload.id ? newItem : item;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
      state.currentItem = { ...state.currentItem, sortOrder: sortOrder, version: newVersion };
    },
  );
  builder.addCase(
    updatePropertyUtilityTypeSortOrder.rejected,
    (state: PropertyUtilityTypeState, action) => {
      state.updateStatus = API_STATUS.FAILURE;
    },
  );

  // --------------- DELETE ---------------
  builder.addCase(deletePropertyUtilityType.pending, (state: PropertyUtilityTypeState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(
    deletePropertyUtilityType.fulfilled,
    (state: PropertyUtilityTypeState, action) => {
      state.deleteStatus = API_STATUS.SUCCESS;
      const { id } = action.meta.arg;
      state.all = state.all
        .filter(item => item.id !== id)
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
  );
  builder.addCase(deletePropertyUtilityType.rejected, (state: PropertyUtilityTypeState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const propertyUtilityTypeSlice = createSlice({
  name: 'propertyUtilityType',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setPropertyUtilityTypeToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = propertyUtilityTypeSlice;
export const { resetCreateStatus, setPropertyUtilityTypeToEdit } = propertyUtilityTypeSlice.actions;
export default reducer;
