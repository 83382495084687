// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  StaffTagApi,
  StaffTagApiCreateStaffTagRequest,
  StaffTagApiDeleteStaffTagRequest,
  StaffTagApiFindStaffTagsRequest,
  StaffTagApiGetStaffTagRequest,
  StaffTagApiUpdateStaffTagRequest,
} from '../teams-openapi';
import { BaseService } from './BaseService';

export class StaffTagService {
  private _baseInstance: BaseService;
  private static _instance: StaffTagService;
  private api: StaffTagApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new StaffTagApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAllRefs() {
    const req: StaffTagApiFindStaffTagsRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findStaffTags(req);
  }

  public getById(id: number, version: number) {
    const req: StaffTagApiGetStaffTagRequest = {
      id: id,
    };
    return this.api.getStaffTag(req);
  }

  public create(label: string) {
    const req: StaffTagApiCreateStaffTagRequest = {
      createStaffTagCommandDto: {
        customer: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createStaffTag(req);
  }

  public delete(id, version) {
    const req: StaffTagApiDeleteStaffTagRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deleteStaffTag(req);
  }

  public updateLabel(id, label, version) {
    const req: StaffTagApiUpdateStaffTagRequest = {
      id: id,
      ifMatch: version,
      updateStaffTagCommandDto: { label: label },
    };
    return this.api.updateStaffTag(req);
  }
}
