// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyTagService } from '../../services/PropertyTagService';
import { ErrorResponse, translateError } from '../../utils/ErrorManagement';

export type PropertyTagState = {
  skip: number;
  take: number;
  total: number;
  loading: boolean;
  data: any[];
  currentItem: any;
  fetchAllStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
  updateError: string;
};

const initialState: PropertyTagState = {
  skip: 0,
  take: 10,
  total: 0,
  loading: false,
  data: null,
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getPropertyTags = createAsyncThunk(
  'propertyTags/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyTagService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getPropertyTagsProperties = createAsyncThunk(
  'propertyTags/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyTagService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createPropertyTag = createAsyncThunk(
  'propertyTags/create',
  async (propertyTag: any, { rejectWithValue }) => {
    try {
      const { label } = propertyTag;
      const res = await PropertyTagService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyTagsLabel = createAsyncThunk(
  'propertyTags/updateLabel',
  async (propertyTag: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyTag;
      const res = await PropertyTagService.Instance.updateLabel(id, label, version);
      // const latest = await PropertyTagService.Instance.getById(id, res.data.version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deletePropertyTag = createAsyncThunk(
  'propertyTags/delete',
  async (propertyTag: any, { rejectWithValue }) => {
    try {
      const { id, version } = propertyTag;
      const res = await PropertyTagService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyTagState>) => {
  // --------------- GET REGION GROUPS ---------------
  builder.addCase(getPropertyTags.pending, (state: PropertyTagState, action) => {
    state.loading = true;
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertyTags.fulfilled, (state: PropertyTagState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.label?.localeCompare(b.label));
    state.data = data;
    state.loading = false;
    state.total = action.payload.totalRecords;
  });
  builder.addCase(getPropertyTags.rejected, (state: PropertyTagState, action) => {
    state.loading = false;
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.data = [];
    state.total = 0;
  });

  // --------------- CREATE NEW ---------------
  builder.addCase(createPropertyTag.pending, (state: PropertyTagState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createPropertyTag.fulfilled, (state: PropertyTagState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.data = [...state.data, action.payload].sort((a, b) => a.label?.localeCompare(b.label));
  });
  builder.addCase(createPropertyTag.rejected, (state: PropertyTagState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updatePropertyTagsLabel.pending, (state: PropertyTagState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyTagsLabel.fulfilled, (state: PropertyTagState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.data = state.data
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.label?.localeCompare(b.label));
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updatePropertyTagsLabel.rejected, (state: PropertyTagState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
    const { friendlyMessage } = action.payload as ErrorResponse;
    state.updateError = friendlyMessage;
  });

  // --------------- DELETE ---------------
  builder.addCase(deletePropertyTag.pending, (state: PropertyTagState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deletePropertyTag.fulfilled, (state: PropertyTagState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.data = state.data
      .filter(item => item.id !== id)
      .sort((a, b) => a.label?.localeCompare(b.label));
  });
  builder.addCase(deletePropertyTag.rejected, (state: PropertyTagState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const propertyTagSlice = createSlice({
  name: 'propertyTag',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setPropertyTagToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = propertyTagSlice;
export const { resetCreateStatus, setPropertyTagToEdit } = propertyTagSlice.actions;
export default reducer;
