/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOwnerCommandDto } from '../models';
// @ts-ignore
import { OwnerDto } from '../models';
// @ts-ignore
import { QueryResultOwnerDto } from '../models';
/**
 * OwnerApi - axios parameter creator
 * @export
 */
export const OwnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Owner Command
         * @param {CreateOwnerCommandDto} createOwnerCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwner: async (createOwnerCommandDto: CreateOwnerCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOwnerCommandDto' is not null or undefined
            assertParamExists('createOwner', 'createOwnerCommandDto', createOwnerCommandDto)
            const localVarPath = `/v1/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOwnerCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Owners
         * @param {number} [customerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOwners: async (customerId?: number, nameStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (nameStartsWith !== undefined) {
                localVarQueryParameter['nameStartsWith'] = nameStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Owner by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwner: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOwner', 'id', id)
            const localVarPath = `/v1/owner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OwnerApi - functional programming interface
 * @export
 */
export const OwnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OwnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Owner Command
         * @param {CreateOwnerCommandDto} createOwnerCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwner(createOwnerCommandDto: CreateOwnerCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOwner(createOwnerCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OwnerApi.createOwner']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Owners
         * @param {number} [customerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOwners(customerId?: number, nameStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultOwnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOwners(customerId, nameStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OwnerApi.findOwners']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Owner by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwner(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwner(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OwnerApi.getOwner']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OwnerApi - factory interface
 * @export
 */
export const OwnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OwnerApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Owner Command
         * @param {OwnerApiCreateOwnerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwner(requestParameters: OwnerApiCreateOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.createOwner(requestParameters.createOwnerCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Owners
         * @param {OwnerApiFindOwnersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOwners(requestParameters: OwnerApiFindOwnersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultOwnerDto> {
            return localVarFp.findOwners(requestParameters.customerId, requestParameters.nameStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Owner by ID
         * @param {OwnerApiGetOwnerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwner(requestParameters: OwnerApiGetOwnerRequest, options?: AxiosRequestConfig): AxiosPromise<OwnerDto> {
            return localVarFp.getOwner(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiCreateOwnerRequest
 */
export interface OwnerApiCreateOwnerRequest {
    /**
     * 
     * @type {CreateOwnerCommandDto}
     * @memberof OwnerApiCreateOwner
     */
    readonly createOwnerCommandDto: CreateOwnerCommandDto
}

/**
 * Request parameters for findOwners operation in OwnerApi.
 * @export
 * @interface OwnerApiFindOwnersRequest
 */
export interface OwnerApiFindOwnersRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiFindOwners
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof OwnerApiFindOwners
     */
    readonly nameStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof OwnerApiFindOwners
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof OwnerApiFindOwners
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof OwnerApiFindOwners
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getOwner operation in OwnerApi.
 * @export
 * @interface OwnerApiGetOwnerRequest
 */
export interface OwnerApiGetOwnerRequest {
    /**
     * 
     * @type {number}
     * @memberof OwnerApiGetOwner
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof OwnerApiGetOwner
     */
    readonly ifMatch?: number
}

/**
 * OwnerApi - object-oriented interface
 * @export
 * @class OwnerApi
 * @extends {BaseAPI}
 */
export class OwnerApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Owner Command
     * @param {OwnerApiCreateOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public createOwner(requestParameters: OwnerApiCreateOwnerRequest, options?: AxiosRequestConfig) {
        return OwnerApiFp(this.configuration).createOwner(requestParameters.createOwnerCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Owners
     * @param {OwnerApiFindOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public findOwners(requestParameters: OwnerApiFindOwnersRequest = {}, options?: AxiosRequestConfig) {
        return OwnerApiFp(this.configuration).findOwners(requestParameters.customerId, requestParameters.nameStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Owner by ID
     * @param {OwnerApiGetOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnerApi
     */
    public getOwner(requestParameters: OwnerApiGetOwnerRequest, options?: AxiosRequestConfig) {
        return OwnerApiFp(this.configuration).getOwner(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}

