import { TeamsConst } from 'constants/Base';
import { EMPTY, from } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import {
  MasterCalendarApi,
  MasterCalendarApiGetPropertyDetailsRequest,
  PropertyApiFindPropertiesRequest,
} from 'teams-openapi';
import { QueryHelper } from '../utils/QueryUtils';
import { BaseService } from './BaseService';

export class MasterCalendarPropertyDetailService {
  private _baseInstance: BaseService;
  private static _instance: MasterCalendarPropertyDetailService;
  private api: MasterCalendarApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new MasterCalendarApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getProperties(filters: MasterCalendarApiGetPropertyDetailsRequest) {
    const req: PropertyApiFindPropertiesRequest = {
      customerId: this._baseInstance.CustomerId,
      servicerId: this._baseInstance.ServicerId,
      nameStartsWith: undefined,
      createdOnOrAfter: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
      ownerId: undefined,
      propertyGroupId: undefined,
      regionGroupId: undefined,
      regionId: undefined,
      ...filters,
    };

    return from(this.api.getPropertyDetails(req))
      .pipe(
        expand(apiResponse => {
          if (!apiResponse.data.nextUrl) {
            return EMPTY;
          }
          const skip = QueryHelper.GetSkipFrom(apiResponse.data.nextUrl);
          const take = QueryHelper.GetTakeFrom(apiResponse.data.nextUrl);
          const newReq = { ...req, skip: skip, take: take };
          return this.api.getPropertyDetails(newReq);
        }),
        reduce((accData, data) => {
          if (data.status !== 200) return data;
          if (!accData.data) {
            accData.data = {};
          }
          if (!accData.data.pageRecords) {
            accData.data.pageRecords = 0;
          }
          accData.data.pageRecords += data.data.pageRecords || 0;
          if (!accData.data.data) {
            accData.data.data = [];
          }
          accData.data.data = accData.data.data.concat(data.data.data || []);
          return accData;
        }),
      )
      .toPromise();
  }
}
