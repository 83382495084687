import { setupAppFeatures } from '@operto/app-features';

// declare your app features here
const appFeatures = {
  fullstory: {
    enabled: false,
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const { useAppFeatures } = setupAppFeatures<AppFeatureFlags>(appFeatures);
