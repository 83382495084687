import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { throwError } from '..';
import { TeamsConst } from '../constants/Base';
import { UserState } from '../models/UserState';
import { Configuration } from '../teams-openapi';

export class BaseService {
  private static _instance: BaseService;

  private userState: UserState | undefined;
  private jwtToken: string | undefined;
  private axiosInstance: AxiosInstance | undefined;
  private isRefreshing = false;
  private failedRequests: Record<string, (value: unknown) => void>[] = [];
  private failedRequestCount = 0;

  constructor() {
    const rawUserData = localStorage.getItem('userState');
    const userStateFromStorage: UserState = rawUserData ? JSON.parse(rawUserData) : undefined;
    if (userStateFromStorage) {
      this.setUserState(userStateFromStorage);
    }
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get JwtToken() {
    return this.jwtToken;
  }

  public get CustomerId() {
    return this.userState?.CurrentCid;
  }

  public get ServicerId() {
    return this.userState?.ServicerId;
  }

  public get Configuration(): Configuration {
    return new Configuration({
      accessToken: this.jwtToken,
    });
  }

  public get AxiosInstance(): AxiosInstance {
    this.axiosInstance = axios.create({});
    this.axiosInstance.interceptors.request.use(this.onRequest, this.onRequestRejected);
    this.axiosInstance.interceptors.response.use(response => response, this.onResponseRejected);
    return this.axiosInstance;
  }

  private onRequest = (config: AxiosRequestConfig) => {
    if (this.isRefreshing) {
      return;
    }

    config.headers.Authorization = `Bearer ${this.jwtToken}`;
    return config;
  };

  private onRequestRejected = (err: unknown) => {
    return Promise.reject(err);
  };

  private onResponseRejected = async (err: AxiosError) => {
    const { response } = err;
    const redirectPath = redirectMap[window.location.pathname] ?? '';

    if (response) {
      if (Object.keys(errorMessage).some(errCode => +errCode === response.status)) {
        throwError(response.status + ' ' + errorMessage[response.status]);
      }

      if (response.status === 401) {
        if (this.isRefreshing) {
          try {
            await new Promise((resolve, reject) => {
              this.failedRequests.push({ resolve, reject });
            });

            err.config.headers.Authorization = `Bearer ${this.jwtToken}`;
            return this.axiosInstance?.(err.config);
          } catch (e) {
            return e;
          }
        }

        this.isRefreshing = true;
        return new Promise((resolve, reject) => {
          this.grabNewToken()
            .then(token => {
              this.jwtToken = token.jwtToken;

              // todo: pull BELOW to function or delegate
              const rawUserState = localStorage.getItem('userState');
              const userStateFromStorage: UserState = rawUserState
                ? JSON.parse(rawUserState)
                : undefined;

              const newState: UserState = {
                ...userStateFromStorage,
                JwtToken: token.jwtToken,
              };
              localStorage.setItem('userState', JSON.stringify(newState));
              // todo: pull ABOVE to function or delegate

              this.setUserState(newState);

              err.config.headers.Authorization = `Bearer ${token.jwtToken}`;
              this.isRefreshing = false;
              this.processQueue(null, token.jwtToken);
              resolve(this.axiosInstance?.(err.config));
            })
            .catch(() => {
              window.location.replace(
                `${TeamsConst.coldFusionWebsiteUrl}/login${
                  redirectPath ? `?redirect=${redirectPath}` : ''
                }`,
              ); // redirect to coldfusion login
              reject(err.response);
            });
        });
      }
    }

    return Promise.reject(err);
  };

  private grabNewToken = async () => {
    const axiosConfig: AxiosRequestConfig = {
      method: 'POST',
      url: '/login/cfwToken',
      baseURL: TeamsConst.apiBaseUrl,
      withCredentials: true,
      data: {},
    };
    return axios
      .request(axiosConfig)
      .then((response: { data: { jwtToken: string } }) => response.data);
  };

  private processQueue = (error: unknown, token: string | null = null) => {
    this.failedRequests.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedRequests = [];
  };

  private setUserState = (userState: UserState) => {
    this.userState = userState;
    this.jwtToken = userState.JwtToken;
  };
}

export const redirectMap: Record<string, string> = {
  '/master-calendar': 'mc',
};

const errorMessage: Record<number, string> = {
  403: 'Forbidden',
  404: 'Not Found',
  422: 'Unprocessable Content',
  503: 'Service Unavailable',
};
