// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyItemTypeService } from '../../services/PropertyItemTypeService';
import { translateError } from '../../utils/ErrorManagement';

export interface PropertyItemTypeState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getPropertyItemType = createAsyncThunk(
  'propertyItemTypes/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyItemTypeService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getPropertyItemTypeReferences = createAsyncThunk(
  'propertyItemTypes/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyItemTypeService.Instance.getAllRefs();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createPropertyItemType = createAsyncThunk(
  'propertyItemTypes/create',
  async (propertyItemType: any, { rejectWithValue }) => {
    try {
      const { label } = propertyItemType;
      const res = await PropertyItemTypeService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);
export const updatePropertyItemType = createAsyncThunk(
  'propertyItemTypes/update',
  async (propertyItemType: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyItemType;
      const res = await PropertyItemTypeService.Instance.updateLabel(id, label, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyItemTypeLabel = createAsyncThunk(
  'propertyItemTypes/updateLabel',
  async (propertyItemType: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyItemType;
      const res = await PropertyItemTypeService.Instance.updateLabel(id, label, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyItemTypeSortOrder = createAsyncThunk(
  'propertyItemTypes/updateSortOrder',
  async (propertyItemType: any, { rejectWithValue }) => {
    try {
      const { id, sortOrder, version } = propertyItemType;
      const res = await PropertyItemTypeService.Instance.updateSortOrder(id, sortOrder, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deletePropertyItemType = createAsyncThunk(
  'propertyItemTypes/delete',
  async (toDelete: any, { rejectWithValue }) => {
    try {
      const { id, version } = toDelete;
      const res = await PropertyItemTypeService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyItemTypeState>) => {
  // --------------- GET REGION GROUPS ---------------
  builder.addCase(getPropertyItemType.pending, (state: PropertyItemTypeState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertyItemType.fulfilled, (state: PropertyItemTypeState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = data;
  });
  builder.addCase(getPropertyItemType.rejected, (state: PropertyItemTypeState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  builder.addCase(getPropertyItemTypeReferences.pending, (state: PropertyItemTypeState, action) => {
    state.fetchRefsStatus = API_STATUS.PENDING;
  });
  builder.addCase(
    getPropertyItemTypeReferences.fulfilled,
    (state: PropertyItemTypeState, action) => {
      state.fetchRefsStatus = API_STATUS.SUCCESS;
      const data = action.payload.data
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(ref => ({ id: ref.id, label: ref.label }));
      state.refs = data;
    },
  );
  builder.addCase(
    getPropertyItemTypeReferences.rejected,
    (state: PropertyItemTypeState, action) => {
      state.fetchRefsStatus = API_STATUS.FAILURE;
      state.refs = [];
    },
  );

  // --------------- CREATE NEW ---------------
  builder.addCase(createPropertyItemType.pending, (state: PropertyItemTypeState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createPropertyItemType.fulfilled, (state: PropertyItemTypeState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(createPropertyItemType.rejected, (state: PropertyItemTypeState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE  ---------------
  builder.addCase(updatePropertyItemType.pending, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyItemType.fulfilled, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updatePropertyItemType.rejected, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updatePropertyItemTypeLabel.pending, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyItemTypeLabel.fulfilled, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updatePropertyItemTypeLabel.rejected, (state: PropertyItemTypeState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- MOVE (UPDATE SORT INDEX) ---------------
  builder.addCase(
    updatePropertyItemTypeSortOrder.pending,
    (state: PropertyItemTypeState, action) => {
      state.updateStatus = API_STATUS.PENDING;
    },
  );
  builder.addCase(
    updatePropertyItemTypeSortOrder.fulfilled,
    (state: PropertyItemTypeState, action) => {
      state.updateStatus = API_STATUS.SUCCESS;
      const { sortOrder } = action.meta.arg;
      const newVersion = action.payload.version;
      state.all = state.all
        .map(item => {
          const newItem = { ...item, sortOrder: sortOrder, version: action.payload.version };
          return item.id === action.payload.id ? newItem : item;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
      state.currentItem = { ...state.currentItem, sortOrder: sortOrder, version: newVersion };
    },
  );
  builder.addCase(
    updatePropertyItemTypeSortOrder.rejected,
    (state: PropertyItemTypeState, action) => {
      state.updateStatus = API_STATUS.FAILURE;
    },
  );

  // --------------- DELETE ---------------
  builder.addCase(deletePropertyItemType.pending, (state: PropertyItemTypeState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deletePropertyItemType.fulfilled, (state: PropertyItemTypeState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.all = state.all.filter(item => item.id !== id).sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(deletePropertyItemType.rejected, (state: PropertyItemTypeState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const propertyItemTypeSlice = createSlice({
  name: 'propertyItemType',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setPropertyItemTypeToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = propertyItemTypeSlice;
export const { resetCreateStatus, setPropertyItemTypeToEdit } = propertyItemTypeSlice.actions;
export default reducer;
