// @ts-nocheck - TODO: fix typescript errors
import * as actionTypes from './../ActionTypes';
type apiState = {
  ping: string;
};
export default function pingReducer(state: apiState = { ping: 'ping' }, action) {
  const { type, payload } = action;
  if (type === actionTypes.RESPONSE_READ_PING) {
    const newState = {
      ...state,
      ping: payload.data,
    };
    return newState;
  }
  return state;
}
