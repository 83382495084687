/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreateMasterCalendarSettingsCommandDto } from '../models';
// @ts-ignore
import { MasterCalendarPermissionDto } from '../models';
// @ts-ignore
import { MasterCalendarSettingsDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarBlockDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarBookingDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarIssueCountDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarNoteResultDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarPropertyDetailDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarPropertyDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarStaffDto } from '../models';
// @ts-ignore
import { QueryResultMasterCalendarTaskResultDto } from '../models';
// @ts-ignore
import { UpdateMasterCalendarNoteCommandDto } from '../models';
// @ts-ignore
import { UpdatePropertyBlockCommandDto } from '../models';
// @ts-ignore
import { UpdateTaskDateTimeCommandDto } from '../models';
/**
 * MasterCalendarApi - axios parameter creator
 * @export
 */
export const MasterCalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateBooking: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateBooking', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('activateBooking', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/bookings/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTask: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateTask', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('activateTask', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/tasks/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Create Command
         * @param {CreateMasterCalendarSettingsCommandDto} createMasterCalendarSettingsCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings: async (createMasterCalendarSettingsCommandDto: CreateMasterCalendarSettingsCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMasterCalendarSettingsCommandDto' is not null or undefined
            assertParamExists('createSettings', 'createMasterCalendarSettingsCommandDto', createMasterCalendarSettingsCommandDto)
            const localVarPath = `/v1/master-calendar/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMasterCalendarSettingsCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateBooking: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateBooking', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deactivateBooking', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/bookings/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTask: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateTask', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deactivateTask', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/tasks/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deleteNote', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Block Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyBlock: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePropertyBlock', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deletePropertyBlock', 'ifMatch', ifMatch)
            const localVarPath = `/v1/master-calendar/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Task Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateTaskDateTimeCommandDto} updateTaskDateTimeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dragAndDropTask: async (id: number, ifMatch: number, updateTaskDateTimeCommandDto: UpdateTaskDateTimeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dragAndDropTask', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('dragAndDropTask', 'ifMatch', ifMatch)
            // verify required parameter 'updateTaskDateTimeCommandDto' is not null or undefined
            assertParamExists('dragAndDropTask', 'updateTaskDateTimeCommandDto', updateTaskDateTimeCommandDto)
            const localVarPath = `/v1/master-calendar/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskDateTimeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Bookings
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookings: async (customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (assignedStaff) {
                localVarQueryParameter['assignedStaff'] = assignedStaff;
            }

            if (staffTypes) {
                localVarQueryParameter['staffTypes'] = staffTypes;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Count of Issues
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCount: async (skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/issue-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (assignedStaff) {
                localVarQueryParameter['assignedStaff'] = assignedStaff;
            }

            if (staffTypes) {
                localVarQueryParameter['staffTypes'] = staffTypes;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Permissions
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (customerId?: number, servicerId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Master Calendar Blocks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyBlock: async (customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (assignedStaff) {
                localVarQueryParameter['assignedStaff'] = assignedStaff;
            }

            if (staffTypes) {
                localVarQueryParameter['staffTypes'] = staffTypes;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Master Calendar Property Details
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetails: async (customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/property-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (nameStartsWith !== undefined) {
                localVarQueryParameter['nameStartsWith'] = nameStartsWith;
            }

            if (propertyGroupId !== undefined) {
                localVarQueryParameter['propertyGroupId'] = propertyGroupId;
            }

            if (regionGroupId !== undefined) {
                localVarQueryParameter['regionGroupId'] = regionGroupId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (createdOnOrAfter !== undefined) {
                localVarQueryParameter['createdOnOrAfter'] = (createdOnOrAfter as any instanceof Date) ?
                    (createdOnOrAfter as any).toISOString() :
                    createdOnOrAfter;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }

            if (propertyIds) {
                localVarQueryParameter['propertyIds'] = propertyIds;
            }

            if (statusIds) {
                localVarQueryParameter['statusIds'] = statusIds;
            }

            if (tagsIds) {
                localVarQueryParameter['tagsIds'] = tagsIds;
            }

            if (ownerIds) {
                localVarQueryParameter['ownerIds'] = ownerIds;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Master Calendar Property List by Customer Id
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyList: async (customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/property-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (nameStartsWith !== undefined) {
                localVarQueryParameter['nameStartsWith'] = nameStartsWith;
            }

            if (propertyGroupId !== undefined) {
                localVarQueryParameter['propertyGroupId'] = propertyGroupId;
            }

            if (regionGroupId !== undefined) {
                localVarQueryParameter['regionGroupId'] = regionGroupId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (createdOnOrAfter !== undefined) {
                localVarQueryParameter['createdOnOrAfter'] = (createdOnOrAfter as any instanceof Date) ?
                    (createdOnOrAfter as any).toISOString() :
                    createdOnOrAfter;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }

            if (propertyIds) {
                localVarQueryParameter['propertyIds'] = propertyIds;
            }

            if (statusIds) {
                localVarQueryParameter['statusIds'] = statusIds;
            }

            if (tagsIds) {
                localVarQueryParameter['tagsIds'] = tagsIds;
            }

            if (ownerIds) {
                localVarQueryParameter['ownerIds'] = ownerIds;
            }

            if (regionGroupIds) {
                localVarQueryParameter['regionGroupIds'] = regionGroupIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Settings
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (customerId?: number, servicerId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Master Calendar Tasks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaff: async (customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (assignedStaff) {
                localVarQueryParameter['assignedStaff'] = assignedStaff;
            }

            if (staffTypes) {
                localVarQueryParameter['staffTypes'] = staffTypes;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Master Calendar Tasks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master-calendar/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (servicerId !== undefined) {
                localVarQueryParameter['servicerId'] = servicerId;
            }

            if (assignedStaff) {
                localVarQueryParameter['assignedStaff'] = assignedStaff;
            }

            if (staffTypes) {
                localVarQueryParameter['staffTypes'] = staffTypes;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteCommandDto} updateMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (id: number, ifMatch: number, updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNote', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateNote', 'ifMatch', ifMatch)
            // verify required parameter 'updateMasterCalendarNoteCommandDto' is not null or undefined
            assertParamExists('updateNote', 'updateMasterCalendarNoteCommandDto', updateMasterCalendarNoteCommandDto)
            const localVarPath = `/v1/master-calendar/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMasterCalendarNoteCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Master Calendar Block Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyBlockCommandDto} updatePropertyBlockCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyBlock: async (id: number, ifMatch: number, updatePropertyBlockCommandDto: UpdatePropertyBlockCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyBlock', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyBlock', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyBlockCommandDto' is not null or undefined
            assertParamExists('updatePropertyBlock', 'updatePropertyBlockCommandDto', updatePropertyBlockCommandDto)
            const localVarPath = `/v1/master-calendar/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyBlockCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterCalendarApi - functional programming interface
 * @export
 */
export const MasterCalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterCalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateBooking(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateBooking(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.activateBooking']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTask(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateTask(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.activateTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Create Command
         * @param {CreateMasterCalendarSettingsCommandDto} createMasterCalendarSettingsCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSettings(createMasterCalendarSettingsCommandDto: CreateMasterCalendarSettingsCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSettings(createMasterCalendarSettingsCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.createSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deactivate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateBooking(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateBooking(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.deactivateBooking']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateTask(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateTask(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.deactivateTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.deleteNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Block Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyBlock(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyBlock(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.deletePropertyBlock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Task Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateTaskDateTimeCommandDto} updateTaskDateTimeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dragAndDropTask(id: number, ifMatch: number, updateTaskDateTimeCommandDto: UpdateTaskDateTimeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dragAndDropTask(id, ifMatch, updateTaskDateTimeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.dragAndDropTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Bookings
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookings(customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarBookingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookings(customerId, servicerId, assignedStaff, staffTypes, start, end, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getBookings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Count of Issues
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueCount(skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarIssueCountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueCount(skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getIssueCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarNoteResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotes(customerId, servicerId, assignedStaff, staffTypes, start, end, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Permissions
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(customerId?: number, servicerId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterCalendarPermissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(customerId, servicerId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Master Calendar Blocks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyBlock(customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyBlock(customerId, servicerId, assignedStaff, staffTypes, start, end, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getPropertyBlock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Master Calendar Property Details
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyDetails(customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarPropertyDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyDetails(customerId, servicerId, nameStartsWith, propertyGroupId, regionGroupId, regionId, regionIds, ownerId, createdOnOrAfter, isActive, isDeleted, propertyIds, statusIds, tagsIds, ownerIds, regionGroupIds, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getPropertyDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Master Calendar Property List by Customer Id
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {string} [nameStartsWith] 
         * @param {number} [propertyGroupId] 
         * @param {number} [regionGroupId] 
         * @param {number} [regionId] 
         * @param {Array<number>} [regionIds] 
         * @param {number} [ownerId] 
         * @param {string} [createdOnOrAfter] 
         * @param {boolean} [isActive] 
         * @param {boolean} [isDeleted] 
         * @param {Array<number>} [propertyIds] 
         * @param {Array<number>} [statusIds] 
         * @param {Array<number>} [tagsIds] 
         * @param {Array<number>} [ownerIds] 
         * @param {Array<number>} [regionGroupIds] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyList(customerId?: number, servicerId?: number, nameStartsWith?: string, propertyGroupId?: number, regionGroupId?: number, regionId?: number, regionIds?: Array<number>, ownerId?: number, createdOnOrAfter?: string, isActive?: boolean, isDeleted?: boolean, propertyIds?: Array<number>, statusIds?: Array<number>, tagsIds?: Array<number>, ownerIds?: Array<number>, regionGroupIds?: Array<number>, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyList(customerId, servicerId, nameStartsWith, propertyGroupId, regionGroupId, regionId, regionIds, ownerId, createdOnOrAfter, isActive, isDeleted, propertyIds, statusIds, tagsIds, ownerIds, regionGroupIds, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getPropertyList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Settings
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(customerId?: number, servicerId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterCalendarSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(customerId, servicerId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Master Calendar Tasks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaff(customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarStaffDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaff(customerId, servicerId, assignedStaff, staffTypes, start, end, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getStaff']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Master Calendar Tasks
         * @param {number} [customerId] 
         * @param {number} [servicerId] 
         * @param {Array<number>} [assignedStaff] 
         * @param {Array<number>} [staffTypes] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [propertyId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(customerId?: number, servicerId?: number, assignedStaff?: Array<number>, staffTypes?: Array<number>, start?: string, end?: string, propertyId?: number, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultMasterCalendarTaskResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(customerId, servicerId, assignedStaff, staffTypes, start, end, propertyId, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.getTasks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateMasterCalendarNoteCommandDto} updateMasterCalendarNoteCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(id: number, ifMatch: number, updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(id, ifMatch, updateMasterCalendarNoteCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.updateNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Master Calendar Block Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyBlockCommandDto} updatePropertyBlockCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyBlock(id: number, ifMatch: number, updatePropertyBlockCommandDto: UpdatePropertyBlockCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyBlock(id, ifMatch, updatePropertyBlockCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MasterCalendarApi.updatePropertyBlock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MasterCalendarApi - factory interface
 * @export
 */
export const MasterCalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterCalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {MasterCalendarApiActivateBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateBooking(requestParameters: MasterCalendarApiActivateBookingRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.activateBooking(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {MasterCalendarApiActivateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTask(requestParameters: MasterCalendarApiActivateTaskRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.activateTask(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Create Command
         * @param {MasterCalendarApiCreateSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings(requestParameters: MasterCalendarApiCreateSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createSettings(requestParameters.createMasterCalendarSettingsCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Master Calendar booking event
         * @param {MasterCalendarApiDeactivateBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateBooking(requestParameters: MasterCalendarApiDeactivateBookingRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.deactivateBooking(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate Master Calendar booking event
         * @param {MasterCalendarApiDeactivateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTask(requestParameters: MasterCalendarApiDeactivateTaskRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.deactivateTask(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Note Command
         * @param {MasterCalendarApiDeleteNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(requestParameters: MasterCalendarApiDeleteNoteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Master Calendar Block Command
         * @param {MasterCalendarApiDeletePropertyBlockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyBlock(requestParameters: MasterCalendarApiDeletePropertyBlockRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePropertyBlock(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Task Command
         * @param {MasterCalendarApiDragAndDropTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dragAndDropTask(requestParameters: MasterCalendarApiDragAndDropTaskRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.dragAndDropTask(requestParameters.id, requestParameters.ifMatch, requestParameters.updateTaskDateTimeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Bookings
         * @param {MasterCalendarApiGetBookingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookings(requestParameters: MasterCalendarApiGetBookingsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarBookingDto> {
            return localVarFp.getBookings(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Count of Issues
         * @param {MasterCalendarApiGetIssueCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueCount(requestParameters: MasterCalendarApiGetIssueCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarIssueCountDto> {
            return localVarFp.getIssueCount(requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Master Calendar Notes
         * @param {MasterCalendarApiGetNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(requestParameters: MasterCalendarApiGetNotesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarNoteResultDto> {
            return localVarFp.getNotes(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Permissions
         * @param {MasterCalendarApiGetPermissionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(requestParameters: MasterCalendarApiGetPermissionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MasterCalendarPermissionDto> {
            return localVarFp.getPermissions(requestParameters.customerId, requestParameters.servicerId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Master Calendar Blocks
         * @param {MasterCalendarApiGetPropertyBlockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyBlock(requestParameters: MasterCalendarApiGetPropertyBlockRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarBlockDto> {
            return localVarFp.getPropertyBlock(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Master Calendar Property Details
         * @param {MasterCalendarApiGetPropertyDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetails(requestParameters: MasterCalendarApiGetPropertyDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarPropertyDetailDto> {
            return localVarFp.getPropertyDetails(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Master Calendar Property List by Customer Id
         * @param {MasterCalendarApiGetPropertyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyList(requestParameters: MasterCalendarApiGetPropertyListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarPropertyDto> {
            return localVarFp.getPropertyList(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Settings
         * @param {MasterCalendarApiGetSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(requestParameters: MasterCalendarApiGetSettingsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MasterCalendarSettingsDto> {
            return localVarFp.getSettings(requestParameters.customerId, requestParameters.servicerId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Master Calendar Tasks
         * @param {MasterCalendarApiGetStaffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaff(requestParameters: MasterCalendarApiGetStaffRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarStaffDto> {
            return localVarFp.getStaff(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Master Calendar Tasks
         * @param {MasterCalendarApiGetTasksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(requestParameters: MasterCalendarApiGetTasksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultMasterCalendarTaskResultDto> {
            return localVarFp.getTasks(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Note Command
         * @param {MasterCalendarApiUpdateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(requestParameters: MasterCalendarApiUpdateNoteRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Master Calendar Block Command
         * @param {MasterCalendarApiUpdatePropertyBlockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyBlock(requestParameters: MasterCalendarApiUpdatePropertyBlockRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyBlock(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyBlockCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateBooking operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiActivateBookingRequest
 */
export interface MasterCalendarApiActivateBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiActivateBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiActivateBooking
     */
    readonly ifMatch: number
}

/**
 * Request parameters for activateTask operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiActivateTaskRequest
 */
export interface MasterCalendarApiActivateTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiActivateTask
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiActivateTask
     */
    readonly ifMatch: number
}

/**
 * Request parameters for createSettings operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiCreateSettingsRequest
 */
export interface MasterCalendarApiCreateSettingsRequest {
    /**
     * 
     * @type {CreateMasterCalendarSettingsCommandDto}
     * @memberof MasterCalendarApiCreateSettings
     */
    readonly createMasterCalendarSettingsCommandDto: CreateMasterCalendarSettingsCommandDto
}

/**
 * Request parameters for deactivateBooking operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiDeactivateBookingRequest
 */
export interface MasterCalendarApiDeactivateBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeactivateBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeactivateBooking
     */
    readonly ifMatch: number
}

/**
 * Request parameters for deactivateTask operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiDeactivateTaskRequest
 */
export interface MasterCalendarApiDeactivateTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeactivateTask
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeactivateTask
     */
    readonly ifMatch: number
}

/**
 * Request parameters for deleteNote operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiDeleteNoteRequest
 */
export interface MasterCalendarApiDeleteNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeleteNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeleteNote
     */
    readonly ifMatch: number
}

/**
 * Request parameters for deletePropertyBlock operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiDeletePropertyBlockRequest
 */
export interface MasterCalendarApiDeletePropertyBlockRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeletePropertyBlock
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDeletePropertyBlock
     */
    readonly ifMatch: number
}

/**
 * Request parameters for dragAndDropTask operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiDragAndDropTaskRequest
 */
export interface MasterCalendarApiDragAndDropTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDragAndDropTask
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiDragAndDropTask
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateTaskDateTimeCommandDto}
     * @memberof MasterCalendarApiDragAndDropTask
     */
    readonly updateTaskDateTimeCommandDto: UpdateTaskDateTimeCommandDto
}

/**
 * Request parameters for getBookings operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetBookingsRequest
 */
export interface MasterCalendarApiGetBookingsRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly servicerId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly assignedStaff?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly staffTypes?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetBookings
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getIssueCount operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetIssueCountRequest
 */
export interface MasterCalendarApiGetIssueCountRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetIssueCount
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetIssueCount
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetIssueCount
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getNotes operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetNotesRequest
 */
export interface MasterCalendarApiGetNotesRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly servicerId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly assignedStaff?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly staffTypes?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetNotes
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPermissions operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetPermissionsRequest
 */
export interface MasterCalendarApiGetPermissionsRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPermissions
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPermissions
     */
    readonly servicerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPermissions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPermissions
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetPermissions
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyBlock operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetPropertyBlockRequest
 */
export interface MasterCalendarApiGetPropertyBlockRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly servicerId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly assignedStaff?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly staffTypes?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetPropertyBlock
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyDetails operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetPropertyDetailsRequest
 */
export interface MasterCalendarApiGetPropertyDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly servicerId?: number

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly nameStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly propertyGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly regionGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly regionId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly ownerId?: number

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly createdOnOrAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly propertyIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly statusIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly tagsIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly ownerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetPropertyDetails
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyList operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetPropertyListRequest
 */
export interface MasterCalendarApiGetPropertyListRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly servicerId?: number

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly nameStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly propertyGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly regionGroupId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly regionId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly ownerId?: number

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly createdOnOrAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly propertyIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly statusIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly tagsIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly ownerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly regionGroupIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetPropertyList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getSettings operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetSettingsRequest
 */
export interface MasterCalendarApiGetSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetSettings
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetSettings
     */
    readonly servicerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetSettings
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetSettings
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetSettings
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getStaff operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetStaffRequest
 */
export interface MasterCalendarApiGetStaffRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly servicerId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly assignedStaff?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly staffTypes?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetStaff
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getTasks operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiGetTasksRequest
 */
export interface MasterCalendarApiGetTasksRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly servicerId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly assignedStaff?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly staffTypes?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly propertyId?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof MasterCalendarApiGetTasks
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for updateNote operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiUpdateNoteRequest
 */
export interface MasterCalendarApiUpdateNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiUpdateNote
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiUpdateNote
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateMasterCalendarNoteCommandDto}
     * @memberof MasterCalendarApiUpdateNote
     */
    readonly updateMasterCalendarNoteCommandDto: UpdateMasterCalendarNoteCommandDto
}

/**
 * Request parameters for updatePropertyBlock operation in MasterCalendarApi.
 * @export
 * @interface MasterCalendarApiUpdatePropertyBlockRequest
 */
export interface MasterCalendarApiUpdatePropertyBlockRequest {
    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiUpdatePropertyBlock
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MasterCalendarApiUpdatePropertyBlock
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyBlockCommandDto}
     * @memberof MasterCalendarApiUpdatePropertyBlock
     */
    readonly updatePropertyBlockCommandDto: UpdatePropertyBlockCommandDto
}

/**
 * MasterCalendarApi - object-oriented interface
 * @export
 * @class MasterCalendarApi
 * @extends {BaseAPI}
 */
export class MasterCalendarApi extends BaseAPI {
    /**
     * 
     * @summary Activate Master Calendar booking event
     * @param {MasterCalendarApiActivateBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public activateBooking(requestParameters: MasterCalendarApiActivateBookingRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).activateBooking(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate Master Calendar booking event
     * @param {MasterCalendarApiActivateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public activateTask(requestParameters: MasterCalendarApiActivateTaskRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).activateTask(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Create Command
     * @param {MasterCalendarApiCreateSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public createSettings(requestParameters: MasterCalendarApiCreateSettingsRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).createSettings(requestParameters.createMasterCalendarSettingsCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Master Calendar booking event
     * @param {MasterCalendarApiDeactivateBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public deactivateBooking(requestParameters: MasterCalendarApiDeactivateBookingRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).deactivateBooking(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate Master Calendar booking event
     * @param {MasterCalendarApiDeactivateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public deactivateTask(requestParameters: MasterCalendarApiDeactivateTaskRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).deactivateTask(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Master Calendar Note Command
     * @param {MasterCalendarApiDeleteNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public deleteNote(requestParameters: MasterCalendarApiDeleteNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).deleteNote(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Master Calendar Block Command
     * @param {MasterCalendarApiDeletePropertyBlockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public deletePropertyBlock(requestParameters: MasterCalendarApiDeletePropertyBlockRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).deletePropertyBlock(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Task Command
     * @param {MasterCalendarApiDragAndDropTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public dragAndDropTask(requestParameters: MasterCalendarApiDragAndDropTaskRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).dragAndDropTask(requestParameters.id, requestParameters.ifMatch, requestParameters.updateTaskDateTimeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Bookings
     * @param {MasterCalendarApiGetBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getBookings(requestParameters: MasterCalendarApiGetBookingsRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getBookings(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Count of Issues
     * @param {MasterCalendarApiGetIssueCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getIssueCount(requestParameters: MasterCalendarApiGetIssueCountRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getIssueCount(requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Master Calendar Notes
     * @param {MasterCalendarApiGetNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getNotes(requestParameters: MasterCalendarApiGetNotesRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getNotes(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Permissions
     * @param {MasterCalendarApiGetPermissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getPermissions(requestParameters: MasterCalendarApiGetPermissionsRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getPermissions(requestParameters.customerId, requestParameters.servicerId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Master Calendar Blocks
     * @param {MasterCalendarApiGetPropertyBlockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getPropertyBlock(requestParameters: MasterCalendarApiGetPropertyBlockRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getPropertyBlock(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Master Calendar Property Details
     * @param {MasterCalendarApiGetPropertyDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getPropertyDetails(requestParameters: MasterCalendarApiGetPropertyDetailsRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getPropertyDetails(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Master Calendar Property List by Customer Id
     * @param {MasterCalendarApiGetPropertyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getPropertyList(requestParameters: MasterCalendarApiGetPropertyListRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getPropertyList(requestParameters.customerId, requestParameters.servicerId, requestParameters.nameStartsWith, requestParameters.propertyGroupId, requestParameters.regionGroupId, requestParameters.regionId, requestParameters.regionIds, requestParameters.ownerId, requestParameters.createdOnOrAfter, requestParameters.isActive, requestParameters.isDeleted, requestParameters.propertyIds, requestParameters.statusIds, requestParameters.tagsIds, requestParameters.ownerIds, requestParameters.regionGroupIds, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Settings
     * @param {MasterCalendarApiGetSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getSettings(requestParameters: MasterCalendarApiGetSettingsRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getSettings(requestParameters.customerId, requestParameters.servicerId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Master Calendar Tasks
     * @param {MasterCalendarApiGetStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getStaff(requestParameters: MasterCalendarApiGetStaffRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getStaff(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Master Calendar Tasks
     * @param {MasterCalendarApiGetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public getTasks(requestParameters: MasterCalendarApiGetTasksRequest = {}, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).getTasks(requestParameters.customerId, requestParameters.servicerId, requestParameters.assignedStaff, requestParameters.staffTypes, requestParameters.start, requestParameters.end, requestParameters.propertyId, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Note Command
     * @param {MasterCalendarApiUpdateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public updateNote(requestParameters: MasterCalendarApiUpdateNoteRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).updateNote(requestParameters.id, requestParameters.ifMatch, requestParameters.updateMasterCalendarNoteCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Master Calendar Block Command
     * @param {MasterCalendarApiUpdatePropertyBlockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterCalendarApi
     */
    public updatePropertyBlock(requestParameters: MasterCalendarApiUpdatePropertyBlockRequest, options?: AxiosRequestConfig) {
        return MasterCalendarApiFp(this.configuration).updatePropertyBlock(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyBlockCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

