import { DateRange } from '@mui/lab/DateRangePicker';
import { createSlice } from '@reduxjs/toolkit';

export enum FILTER_PAGE {
  EMPTY = 'EMPTY',
  DEPARTURE = 'DEPARTURE',
  REGIONS = 'REGIONS',
}

export interface FilterState {
  currentFilterPage: FILTER_PAGE;
  departureFilters: DepartureFilters;
}
export interface ChipRef {
  id: number;
  label: string;
  gridKey: any;
}

export interface DepartureFilters {
  dateRange: DateRange<string>;
  regionGroupRefs: Array<ChipRef>;
  regionRefs: Array<ChipRef>;
  propertyRefs: Array<ChipRef>;
  taskDuringStay: boolean;
  keyword: string;
}

const initialState: FilterState = {
  currentFilterPage: FILTER_PAGE.EMPTY,
  departureFilters: {
    dateRange: [new Date().toISOString(), new Date().toISOString()],
    regionGroupRefs: [],
    regionRefs: [],
    propertyRefs: [],
    taskDuringStay: false,
    keyword: '',
  },
};

export const addThunkCases = () => {
  // TODO: Add thunk cases here
};

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setCurrentFilterPage: (state, action) => {
      state.currentFilterPage = action.payload;
    },
    setDateRange: (state, action) => {
      state.departureFilters.dateRange = action.payload
        ? action.payload.map((x: Date) => {
            return x.toISOString();
          })
        : undefined;
    },
    setRegionGroupRefs: (state, action) => {
      state.departureFilters.regionGroupRefs = action.payload;
    },
    setRegionRefs: (state, action) => {
      state.departureFilters.regionRefs = action.payload;
    },
    setPropertyRefs: (state, action) => {
      state.departureFilters.propertyRefs = action.payload;
    },
    setTaskDuringStay: (state, action) => {
      state.departureFilters.taskDuringStay = action.payload;
    },
    setKeyword: (state, action) => {
      state.departureFilters.keyword = action.payload;
    },
  },
});

const { reducer } = filterSlice;
export const {
  setCurrentFilterPage,
  setDateRange,
  setRegionGroupRefs,
  setRegionRefs,
  setPropertyRefs,
  setTaskDuringStay,
  setKeyword,
} = filterSlice.actions;
export default reducer;
