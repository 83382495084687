// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  PropertyStatusApi,
  PropertyStatusApiCreatePropertyStatusRequest,
  PropertyStatusApiDeletePropertyStatusRequest,
  PropertyStatusApiFindPropertyStatusesRequest,
  PropertyStatusApiGetPropertyStatusRequest,
  PropertyStatusApiMovePropertyStatusRequest,
  PropertyStatusApiUpdatePropertyStatusLabelRequest,
} from '../teams-openapi';
import { PropertyStatusApiUpdatePropertyStatusRequest } from './../teams-openapi/apis/property-status-api';
import { BaseService } from './BaseService';

export class PropertyStatusService {
  private _baseInstance: BaseService;
  private static _instance: PropertyStatusService;
  private api: PropertyStatusApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new PropertyStatusApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: PropertyStatusApiFindPropertyStatusesRequest = {
      customerId: this._baseInstance.CustomerId,
      labelStartsWith: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findPropertyStatuses(req);
  }

  public getAllRefs() {
    const req: PropertyStatusApiFindPropertyStatusesRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findPropertyStatuses(req);
  }

  public getById(id: number, version: number) {
    const req: PropertyStatusApiGetPropertyStatusRequest = {
      id: id,
    };
    return this.api.getPropertyStatus(req);
  }

  public create(label: string, setOnCheckIn: boolean, setOnCheckOut: boolean) {
    const req: PropertyStatusApiCreatePropertyStatusRequest = {
      createPropertyStatusCommandDto: {
        customerId: this._baseInstance.CustomerId,
        label: label,
        setOnCheckIn: setOnCheckIn,
        setOnCheckOut: setOnCheckOut,
      },
    };
    return this.api.createPropertyStatus(req);
  }

  public delete(id, version) {
    const req: PropertyStatusApiDeletePropertyStatusRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deletePropertyStatus(req);
  }

  public updateLabel(id, label, version) {
    const req: PropertyStatusApiUpdatePropertyStatusLabelRequest = {
      id: id,
      ifMatch: version,
      updatePropertyStatusLabelCommandDto: { label: label },
    };
    return this.api.updatePropertyStatusLabel(req);
  }

  public update(id, label, setCheckOnIn, setCheckOnOut, version) {
    const req: PropertyStatusApiUpdatePropertyStatusRequest = {
      id: id,
      ifMatch: version,
      updatePropertyStatusCommandDto: {
        label: label,
        setOnCheckIn: setCheckOnIn,
        setOnCheckOut: setCheckOnOut,
      },
    };
    return this.api.updatePropertyStatus(req);
  }

  public updateSortOrder(id, sortIndex, version) {
    const req: PropertyStatusApiMovePropertyStatusRequest = {
      id: id,
      ifMatch: version,
      movePropertyStatusCommandDto: { moveToSortOrder: sortIndex },
    };
    return this.api.movePropertyStatus(req);
  }
}
