// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  DepartmentApi,
  DepartmentApiCreateDepartmentRequest,
  DepartmentApiFindDepartmentsRequest,
  DepartmentApiGetDepartmentRequest,
  DepartmentApiUpdateDepartmentLabelRequest,
} from './../teams-openapi/apis/department-api';
import { BaseService } from './BaseService';

export class DepartmentService {
  private _baseInstance: BaseService;
  private static _instance: DepartmentService;
  private api: DepartmentApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new DepartmentApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: DepartmentApiFindDepartmentsRequest = {
      _queryParameters: {
        customerId: this._baseInstance.CustomerId,
        labelStartsWith: undefined,
        skip: undefined,
        take: undefined,
        sort: undefined,
      },
    };
    return this.api.findDepartments(req);
  }

  public getById(id: number, version: number) {
    const req: DepartmentApiGetDepartmentRequest = {
      id: id,
    };
    return this.api.getDepartment(req);
  }

  public create(label: string) {
    const req: DepartmentApiCreateDepartmentRequest = {
      createDepartmentCommandDto: {
        customerId: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createDepartment(req);
  }

  public delete(payload) {
    const { id, version } = payload;
    return this.api.deleteDepartment({ id, ifMatch: version });
  }

  public update(department) {
    const { id, label, version } = department;
    const req: DepartmentApiUpdateDepartmentLabelRequest = {
      id: id,
      ifMatch: version,
      updateDepartmentLabelCommandDto: { label: label },
    };
    return this.api.updateDepartmentLabel(req);
  }
}
