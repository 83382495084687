import { Box, Grid, Skeleton, SxProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const BASE_HEIGHT = 30;
const CIRCULAR_WIDTH = 30;
const ROUNDED_WIDTH = 30;
const SMALL_LOGO_SIZE = 40;
// 99 for avoid extra elements in the right side of the page
const PERCENT_FOR_WRAPPER = 99;

export const SkeletonLeftSidebar = () => {
  const windowHeight = window.innerHeight;
  const numSkeletonElements = Math.floor((windowHeight - 250) / 60);

  const skeletonElements = Array.from({ length: numSkeletonElements }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Box display='flex' alignItems='center' key={index} padding='0px 10px'>
      <Skeleton
        sx={headingElementSx}
        variant='rounded'
        width={SMALL_LOGO_SIZE}
        height={SMALL_LOGO_SIZE}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Skeleton sx={headingElementSx} variant='text' width='100%' height={15} />
        <Skeleton sx={headingElementSx} variant='text' width='100%' height={15} />
      </Box>
    </Box>
  ));

  return (
    <Box width='100%'>
      <Box>{skeletonElements}</Box>
    </Box>
  );
};

export const SkeletonMasterCalendarRightSidebar = () => {
  const columnWidth = 80;
  const [wrapperWidth, setWrapperWidth] = useState('auto');
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        const parentWidth = wrapperRef.current.clientWidth;
        const newWrapperWidth = (parentWidth * PERCENT_FOR_WRAPPER) / 100;
        setWrapperWidth(`${newWrapperWidth}px`);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columnCount = Math.floor(parseFloat(wrapperWidth) / columnWidth);

  const columns = Array.from({ length: columnCount }, (_, index) => (
    <Grid item key={index}>
      <Box
        style={{
          width: columnWidth,
          height: '100%',
          borderRight: '1px solid #e0e0e0',
          borderLeft: '1px solid #e0e0e0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0px 5px',
        }}
      >
        <Skeleton
          sx={{
            margin: '5px 0px',
          }}
          variant='text'
          width='100%'
          height={20}
        />
        <Box
          sx={{
            border: '1px dashed #e0e0e0',
            width: '2px',
            height: '100%',
            textAlign: 'center',
            margin: '0 auto',
          }}
        ></Box>
      </Box>
    </Grid>
  ));

  return (
    <Grid container style={{ width: '100%', height: '100%' }} ref={wrapperRef}>
      <Grid item container style={{ height: '100%', padding: '0px 10px' }}>
        {columns}
      </Grid>
    </Grid>
  );
};

export const SkeletonVerticalElementsList = () => {
  const windowHeight = window.innerHeight;
  const numSkeletonElements = Math.floor((windowHeight - 350) / 60);

  return Array.from({ length: numSkeletonElements }, (_, index) => (
    // we use index as key because we don't have any other unique value and it's not affecting real data
    <Box key={index}>
      <Skeleton sx={headingElementSx} variant='rounded' width='100%' height={50} />
    </Box>
  ));
};

const headingElementSx: SxProps = {
  margin: '10px',
};
