// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyStatusService } from '../../services/PropertyStatusService';
import { translateError } from '../../utils/ErrorManagement';

export interface PropertyStatusState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getPropertyStatus = createAsyncThunk(
  'propertyStatus/get',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyStatusService.Instance.getAll();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const getPropertyStatusReferences = createAsyncThunk(
  'propertyStatus/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await PropertyStatusService.Instance.getAllRefs();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createPropertyStatus = createAsyncThunk(
  'propertyStatus/create',
  async (propertyStatus: any, { rejectWithValue }) => {
    try {
      const { label, setOnCheckIn, setOnCheckOut } = propertyStatus;
      const res = await PropertyStatusService.Instance.create(label, setOnCheckIn, setOnCheckOut);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);
export const updatePropertyStatus = createAsyncThunk(
  'propertyStatus/update',
  async (propertyStatus: any, { rejectWithValue }) => {
    try {
      const { id, label, setOnCheckIn, setOnCheckOut, version } = propertyStatus;
      const res = await PropertyStatusService.Instance.update(
        id,
        label,
        setOnCheckIn,
        setOnCheckOut,
        version,
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyStatusLabel = createAsyncThunk(
  'propertyStatus/updateLabel',
  async (propertyStatus: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = propertyStatus;
      const res = await PropertyStatusService.Instance.updateLabel(id, label, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updatePropertyStatusSortOrder = createAsyncThunk(
  'propertyStatus/updateSortOrder',
  async (propertyStatus: any, { rejectWithValue }) => {
    try {
      const { id, sortOrder, version } = propertyStatus;
      const res = await PropertyStatusService.Instance.updateSortOrder(id, sortOrder, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deletePropertyStatus = createAsyncThunk(
  'propertyStatus/delete',
  async (toDelete: any, { rejectWithValue }) => {
    try {
      const { id, version } = toDelete;
      const res = await PropertyStatusService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyStatusState>) => {
  // --------------- GET PROPERTY STATUS ---------------
  builder.addCase(getPropertyStatus.pending, (state: PropertyStatusState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertyStatus.fulfilled, (state: PropertyStatusState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.sortOrder - b.sortOrder);
    state.all = data;
  });
  builder.addCase(getPropertyStatus.rejected, (state: PropertyStatusState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  builder.addCase(getPropertyStatusReferences.pending, (state: PropertyStatusState, action) => {
    state.fetchRefsStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertyStatusReferences.fulfilled, (state: PropertyStatusState, action) => {
    state.fetchRefsStatus = API_STATUS.SUCCESS;
    const data = action.payload.data
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(ref => ({ id: ref.id, label: ref.label }));
    state.refs = data;
  });
  builder.addCase(getPropertyStatusReferences.rejected, (state: PropertyStatusState, action) => {
    state.fetchRefsStatus = API_STATUS.FAILURE;
    state.refs = [];
  });

  // --------------- CREATE NEW ---------------
  builder.addCase(createPropertyStatus.pending, (state: PropertyStatusState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createPropertyStatus.fulfilled, (state: PropertyStatusState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(createPropertyStatus.rejected, (state: PropertyStatusState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE  ---------------
  builder.addCase(updatePropertyStatus.pending, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyStatus.fulfilled, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updatePropertyStatus.rejected, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updatePropertyStatusLabel.pending, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyStatusLabel.fulfilled, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updatePropertyStatusLabel.rejected, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- MOVE (UPDATE SORT INDEX) ---------------
  builder.addCase(updatePropertyStatusSortOrder.pending, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updatePropertyStatusSortOrder.fulfilled, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { sortOrder } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, sortOrder: sortOrder, version: action.payload.version };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, sortOrder: sortOrder, version: newVersion };
  });
  builder.addCase(updatePropertyStatusSortOrder.rejected, (state: PropertyStatusState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- DELETE ---------------
  builder.addCase(deletePropertyStatus.pending, (state: PropertyStatusState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deletePropertyStatus.fulfilled, (state: PropertyStatusState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.all = state.all.filter(item => item.id !== id).sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(deletePropertyStatus.rejected, (state: PropertyStatusState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const propertyStatusSlice = createSlice({
  name: 'propertyStatus',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setPropertyStatusToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = propertyStatusSlice;
export const { resetCreateStatus, setPropertyStatusToEdit } = propertyStatusSlice.actions;
export default reducer;
