// @ts-nocheck - TODO: fix typescript errors
import error from '../../errorMessages.json';

/* eslint-disable @typescript-eslint/ban-types */
type apiState = {
  user?: object;
  testimonials?: object;
  members?: object;
  industryLinks?: object;
  industryLink_categories?: object;
  siteUpdates?: object;
  deleteError?: object;
};

export default function apiReducer(state: apiState = {}, { type, payload }) {
  if (type.endsWith('MEMBER') && payload != undefined) {
    payload = payload.data;
  }
  let stateName = null;
  let stateIdCol = null;
  if (type.endsWith('MEMBER')) {
    stateName = 'members';
    stateIdCol = 'memberId';
  } else if (type.endsWith('TESTIMONIAL')) {
    stateName = 'testimonials';
    stateIdCol = 'testimonialId';
  } else if (type.endsWith('INDUSTRY_LINK')) {
    stateName = 'industryLinks';
    stateIdCol = 'industryLinkId';
  } else if (type.endsWith('INDUSTRY_LINK_CATEGORY')) {
    stateName = 'industryLink_categories';
    stateIdCol = 'categoryId';
  } else if (type.endsWith('SITE_UPDATE')) {
    stateName = 'siteUpdates';
    stateIdCol = 'updateId';
  }
  if (type.startsWith('SUCCESS_READ_')) {
    if (payload.skip > 0) {
      payload.rows = state[stateName]['rows'].concat(payload.rows);
    }
    state[stateName] = payload;
    return {
      ...state,
    };
  } else if (type.startsWith('SUCCESS_CREATE_NEW')) {
    state[stateName] = {
      ...state[stateName],
      rows: state[stateName]['rows'].concat(payload),
      totalRecords: state[stateName]['totalRecords'] + 1,
    };
    return {
      ...state,
    };
  } else if (type.startsWith('SUCCESS_UPDATE_EDIT_')) {
    if (type.endsWith('MEMBER')) {
      payload = payload['member'];
    }
    const stateVal = { ...state[stateName] };
    const stateRows = [...stateVal['rows']];
    const updateRowValue = stateRows.map(stateRow => {
      if (stateRow[stateIdCol] === payload[stateIdCol]) {
        return payload;
      }
      return stateRow;
    });
    state[stateName] = {
      ...stateRows,
      rows: [...updateRowValue],
    };
    return {
      ...state,
    };
  } else if (type.startsWith('SUCCESS_DELETE_')) {
    const stateVal = { ...state[stateName] };
    const stateRows = [...stateVal['rows']];
    let deletedRowIdx = -1;
    for (let deletedRow = 0; deletedRow < payload.deleteCount; deletedRow++) {
      deletedRowIdx = stateRows.findIndex(
        x => x[stateIdCol] == payload.deletedRows[deletedRow][stateIdCol],
      );
      if (deletedRowIdx > -1) {
        stateRows.splice(deletedRowIdx, 1);
      }
    }
    state[stateName] = {
      ...stateVal,
      rows: stateRows,
    };
    return {
      ...state,
    };
  } else if (type === 'FAIL_DELETE_INDUSTRY_LINK_CATEGORY') {
    const deleteError = error['category.hasAssociations'];
    state.deleteError = {
      deleteError: deleteError,
    };
  }
  return state;
}
