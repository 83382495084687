import { DateRange } from '@mui/lab/DateRangePicker';
import { createSlice } from '@reduxjs/toolkit';
import PropertyFilter from '../../pages/Admin/Properties/Utils/PropertyFilter';

export enum FILTER_PAGE {
  EMPTY = 'EMPTY',
  CHECK_IN_OUT = 'CHECK_IN_OUT',
  REGIONS = 'REGIONS',
  PROPERTIES = 'PROPERTIES',
}

export interface FilterState {
  currentFilterPage: FILTER_PAGE;
  checkInOutFilters: CheckInOutFilters;
  propertyFilters: PropertyFilter;
}
export interface ChipRef {
  id: number;
  label: string;
  gridKey: any;
}

export interface CheckInOutFilters {
  dateRange: DateRange<string> | undefined;
  regionGroupRefs: Array<ChipRef>;
  regionRefs: Array<ChipRef>;
  propertyRefs: Array<ChipRef>;
  propertyTagsRefs: Array<ChipRef>;
  propertyStatusesRefs: Array<ChipRef>;
  backToBack: boolean;
}

const initialState: FilterState = {
  currentFilterPage: FILTER_PAGE.EMPTY,
  checkInOutFilters: {
    dateRange: [new Date().toISOString(), new Date().toISOString()],
    regionGroupRefs: [],
    regionRefs: [],
    propertyRefs: [],
    propertyTagsRefs: [],
    propertyStatusesRefs: [],
    backToBack: false,
  },
  propertyFilters: {
    regionGroupRefs: [],
    regionRefs: [],
    propertyTagRefs: [],
    ownerRefs: [],
    includeActive: true,
    includeInactive: false,
    includeArchived: false,
  },
};

export const addThunkCases = () => {
  // TODO: Add thunk cases here
};

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setCurrentFilterPage: (state, action) => {
      state.currentFilterPage = action.payload;
    },
    setDateRange: (state, action) => {
      state.checkInOutFilters.dateRange = action.payload
        ? action.payload.map((x: Date) => {
            return x.toISOString();
          })
        : undefined;
    },
    setRegionGroupRefs: (state, action) => {
      state.checkInOutFilters.regionGroupRefs = action.payload;
    },
    setRegionRefs: (state, action) => {
      state.checkInOutFilters.regionRefs = action.payload;
    },
    setPropertyRefs: (state, action) => {
      state.checkInOutFilters.propertyRefs = action.payload;
    },
    setPropertyTagsRefs: (state, action) => {
      state.checkInOutFilters.propertyTagsRefs = action.payload;
    },
    setPropertyStatusessRefs: (state, action) => {
      state.checkInOutFilters.propertyStatusesRefs = action.payload;
    },
    setBackToBack: (state, action) => {
      state.checkInOutFilters.backToBack = action.payload;
    },
    setPropertyFilter: (state, action) => {
      state.propertyFilters = action.payload;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = filterSlice;
export const {
  setCurrentFilterPage,
  setDateRange,
  setRegionGroupRefs,
  setRegionRefs,
  setPropertyRefs,
  setBackToBack,
  setPropertyFilter,
  setPropertyTagsRefs,
  setPropertyStatusessRefs,
} = filterSlice.actions;
export default reducer;
