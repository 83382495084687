import { addMonths, format } from 'date-fns';
import { DepartureFilters } from 'redux/slices/DepartureFilterSlice';
import { DepartureApi, DepartureApiDepartureRequest } from 'teams-openapi/apis/departure-api';
import { TeamsConst } from '../../constants/Base';
import { FORMAT_YYYY_MM__DD } from '../../utils/DateUtils';
import { BaseService } from './../BaseService';

export class DepartureReportService {
  private _baseInstance: BaseService;
  private static _instance: DepartureReportService;
  private api: DepartureApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new DepartureApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public runDepartureReport(filters: DepartureFilters) {
    const req = this.getDepartureRequest(filters);
    return this.api.departure(req);
  }

  public async runDepartureReportCsv(filters: DepartureFilters) {
    const req = this.getDepartureRequest(filters);
    const promise = this.api.departureExportCsv(req);
    return promise;
  }

  public async runDepartureReportPdf(filters: DepartureFilters) {
    const req = this.getDepartureRequest(filters);
    const promise = this.api.departureExportPdf(req, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/pdf' },
    });
    return promise;
  }

  private getDepartureRequest(filters: DepartureFilters) {
    const fromDateAsString =
      filters.dateRange && filters.dateRange[0] !== null
        ? format(new Date(filters.dateRange[0]), FORMAT_YYYY_MM__DD)
        : format(new Date(), FORMAT_YYYY_MM__DD);

    const toDateAsString =
      filters.dateRange && filters.dateRange[1] !== null
        ? format(new Date(filters.dateRange[1]), FORMAT_YYYY_MM__DD)
        : format(addMonths(new Date(), 1), FORMAT_YYYY_MM__DD);
    const regionGroupIds = filters.regionGroupRefs.map(rgr => rgr.id);
    const regionIds = filters.regionRefs.map(rg => rg.id);
    const unitIds = filters.propertyRefs.map(prop => prop.id);
    const taskDuringStay = filters.taskDuringStay;
    const keyword = filters.keyword;

    const req: DepartureApiDepartureRequest = {
      customerId: this._baseInstance.CustomerId,
      fromDate: fromDateAsString,
      toDate: toDateAsString,
      regionGroupIds: regionGroupIds,
      regionIds: regionIds,
      unitIds: unitIds,
      taskDuringStay: taskDuringStay,
      keyword: keyword,
    };
    return req;
  }
}
