// @ts-nocheck - TODO: fix typescript errors
import i18n from 'i18next';

type languageState = {
  language: string;
};

const initialLanguageState: languageState = {
  language: 'en',
};

export default function languageReducer(state = initialLanguageState, action) {
  const { type, data } = action;

  if (type.startsWith('SET_LANGUAGE_')) {
    i18n.changeLanguage(data);
    return {
      ...state,
      language: data,
    };
  }

  return state;
}
