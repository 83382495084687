// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { StaffTagService } from '../../services/StaffTagService';
import { translateError } from '../../utils/ErrorManagement';

export interface StaffTagState {
  all: any[];
  refs: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  fetchRefsStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  refs: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  fetchRefsStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getStaffTags = createAsyncThunk(
  'staffTags/getRefs',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await StaffTagService.Instance.getAllRefs();
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const createStaffTag = createAsyncThunk(
  'staffTags/create',
  async (staffTag: any, { rejectWithValue }) => {
    try {
      const { label } = staffTag;
      const res = await StaffTagService.Instance.create(label);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const updateStaffTagLabel = createAsyncThunk(
  'staffTags/updateLabel',
  async (staffTag: any, { rejectWithValue }) => {
    try {
      const { id, label, version } = staffTag;
      const res = await StaffTagService.Instance.updateLabel(id, label, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const deleteStaffTag = createAsyncThunk(
  'staffTags/delete',
  async (toDelete: any, { rejectWithValue }) => {
    try {
      const { id, version } = toDelete;
      const res = await StaffTagService.Instance.delete(id, version);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<StaffTagState>) => {
  //--------------- GET ALL STAFF TAGS ---------------
  builder.addCase(getStaffTags.pending, (state: StaffTagState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getStaffTags.fulfilled, (state: StaffTagState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.label?.localeCompare(b.label));
    // .map(ref => ({id: ref.id, label: ref.label}));
    state.all = data;
  });
  builder.addCase(getStaffTags.rejected, (state: StaffTagState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });

  // // --------------- GET REGION GROUPS BY REFS---------------
  //
  // builder.addCase(getStaffTags.pending, (state: StaffTagState, action) => {
  //     console.log("test1");
  //     state.fetchRefsStatus = API_STATUS.PENDING;
  // });
  // builder.addCase(getStaffTags.fulfilled, (state: StaffTagState, action) => {
  //     console.log("test2");
  //
  //     state.fetchRefsStatus = API_STATUS.SUCCESS;
  //     const data = action.payload.data
  //         .sort((a, b) => a.label?.localeCompare(b.label))
  //         .map(ref => ({id: ref.id, label: ref.label}));
  //     console.log(data);
  //     state.refs = data;
  // });
  // builder.addCase(getStaffTags.rejected, (state: StaffTagState, action) => {
  //     console.log("test3");
  //
  //     state.fetchRefsStatus = API_STATUS.FAILURE;
  //     state.refs = [];
  // });

  // --------------- CREATE NEW ---------------
  builder.addCase(createStaffTag.pending, (state: StaffTagState, action) => {
    state.createStatus = API_STATUS.PENDING;
  });
  builder.addCase(createStaffTag.fulfilled, (state: StaffTagState, action) => {
    state.createStatus = API_STATUS.SUCCESS;
    state.all = [...state.all, action.payload].sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(createStaffTag.rejected, (state: StaffTagState, action) => {
    state.createStatus = API_STATUS.FAILURE;
  });

  // --------------- UPDATE LABEL ---------------
  builder.addCase(updateStaffTagLabel.pending, (state: StaffTagState, action) => {
    state.updateStatus = API_STATUS.PENDING;
  });
  builder.addCase(updateStaffTagLabel.fulfilled, (state: StaffTagState, action) => {
    state.updateStatus = API_STATUS.SUCCESS;
    const { label } = action.meta.arg;
    const newVersion = action.payload.version;
    state.all = state.all
      .map(item => {
        const newItem = { ...item, label: label, version: newVersion };
        return item.id === action.payload.id ? newItem : item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    state.currentItem = { ...state.currentItem, label: label, version: newVersion };
  });
  builder.addCase(updateStaffTagLabel.rejected, (state: StaffTagState, action) => {
    state.updateStatus = API_STATUS.FAILURE;
  });

  // --------------- DELETE ---------------
  builder.addCase(deleteStaffTag.pending, (state: StaffTagState, action) => {
    state.deleteStatus = API_STATUS.PENDING;
  });
  builder.addCase(deleteStaffTag.fulfilled, (state: StaffTagState, action) => {
    state.deleteStatus = API_STATUS.SUCCESS;
    const { id } = action.meta.arg;
    state.all = state.all.filter(item => item.id !== id).sort((a, b) => a.sortOrder - b.sortOrder);
  });
  builder.addCase(deleteStaffTag.rejected, (state: StaffTagState, action) => {
    state.deleteStatus = API_STATUS.FAILURE;
  });
};

const staffTagSlice = createSlice({
  name: 'staffTag',
  initialState,
  reducers: {
    resetCreateStatus: (state, action) => {
      state.createStatus = API_STATUS.IDLE;
    },
    setStaffTagToEdit: (state, action) => {
      state.currentItem = action.payload.row;
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = staffTagSlice;
export const { resetCreateStatus, setStaffTagToEdit } = staffTagSlice.actions;
export default reducer;
