import { ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { createContext, ReactNode, useState } from 'react';
import getTheme from '../../assets/themes/ThemeController';

export const CustomThemeContext = createContext({
  currentTheme: 'normal',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTheme: (name: string) => {
    // do nothing
  },
});

const CustomThemeProvider = ({ children }: { children: ReactNode }) => {
  const currentTheme = localStorage.getItem('appTheme') || 'normal';
  const [localThemeName, setLocalThemeName] = useState(currentTheme);
  const theme = getTheme(localThemeName);

  const setThemeName = (name: string) => {
    localStorage.setItem('appTheme', name);
    setLocalThemeName(name);
  };

  const contextValue = {
    currentTheme: localThemeName,
    setTheme: setThemeName,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;

CustomThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
