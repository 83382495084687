/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreatePropertyItemTypeCommandDto } from '../models';
// @ts-ignore
import { MovePropertyItemTypeCommandDto } from '../models';
// @ts-ignore
import { PropertyItemTypeDto } from '../models';
// @ts-ignore
import { QueryResultPropertyItemTypeDto } from '../models';
// @ts-ignore
import { UpdatePropertyItemTypeLabelCommandDto } from '../models';
/**
 * PropertyItemTypeApi - axios parameter creator
 * @export
 */
export const PropertyItemTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Property Item Type Command
         * @param {CreatePropertyItemTypeCommandDto} createPropertyItemTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyItemType: async (createPropertyItemTypeCommandDto: CreatePropertyItemTypeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyItemTypeCommandDto' is not null or undefined
            assertParamExists('createPropertyItemType', 'createPropertyItemTypeCommandDto', createPropertyItemTypeCommandDto)
            const localVarPath = `/v1/propertyitemtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyItemTypeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Property Item Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyItemType: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePropertyItemType', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deletePropertyItemType', 'ifMatch', ifMatch)
            const localVarPath = `/v1/propertyitemtype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Property Item Type
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyItemType: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/propertyitemtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Item Type by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyItemType: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyItemType', 'id', id)
            const localVarPath = `/v1/propertyitemtype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Move Label Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyItemTypeCommandDto} movePropertyItemTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyItemType: async (id: number, ifMatch: number, movePropertyItemTypeCommandDto: MovePropertyItemTypeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('movePropertyItemType', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('movePropertyItemType', 'ifMatch', ifMatch)
            // verify required parameter 'movePropertyItemTypeCommandDto' is not null or undefined
            assertParamExists('movePropertyItemType', 'movePropertyItemTypeCommandDto', movePropertyItemTypeCommandDto)
            const localVarPath = `/v1/propertyitemtype/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(movePropertyItemTypeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Item Type Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyItemTypeLabelCommandDto} updatePropertyItemTypeLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyItemTypeLabel: async (id: number, ifMatch: number, updatePropertyItemTypeLabelCommandDto: UpdatePropertyItemTypeLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyItemTypeLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyItemTypeLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyItemTypeLabelCommandDto' is not null or undefined
            assertParamExists('updatePropertyItemTypeLabel', 'updatePropertyItemTypeLabelCommandDto', updatePropertyItemTypeLabelCommandDto)
            const localVarPath = `/v1/propertyitemtype/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyItemTypeLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyItemTypeApi - functional programming interface
 * @export
 */
export const PropertyItemTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyItemTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Item Type Command
         * @param {CreatePropertyItemTypeCommandDto} createPropertyItemTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyItemType(createPropertyItemTypeCommandDto: CreatePropertyItemTypeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyItemType(createPropertyItemTypeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.createPropertyItemType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Property Item Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyItemType(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyItemType(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.deletePropertyItemType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Property Item Type
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPropertyItemType(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultPropertyItemTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPropertyItemType(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.findPropertyItemType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Property Item Type by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyItemType(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyItemTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyItemType(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.getPropertyItemType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Move Label Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyItemTypeCommandDto} movePropertyItemTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movePropertyItemType(id: number, ifMatch: number, movePropertyItemTypeCommandDto: MovePropertyItemTypeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movePropertyItemType(id, ifMatch, movePropertyItemTypeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.movePropertyItemType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Item Type Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyItemTypeLabelCommandDto} updatePropertyItemTypeLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyItemTypeLabel(id: number, ifMatch: number, updatePropertyItemTypeLabelCommandDto: UpdatePropertyItemTypeLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyItemTypeLabel(id, ifMatch, updatePropertyItemTypeLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyItemTypeApi.updatePropertyItemTypeLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyItemTypeApi - factory interface
 * @export
 */
export const PropertyItemTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyItemTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Item Type Command
         * @param {PropertyItemTypeApiCreatePropertyItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyItemType(requestParameters: PropertyItemTypeApiCreatePropertyItemTypeRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createPropertyItemType(requestParameters.createPropertyItemTypeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Property Item Type Command
         * @param {PropertyItemTypeApiDeletePropertyItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyItemType(requestParameters: PropertyItemTypeApiDeletePropertyItemTypeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePropertyItemType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Property Item Type
         * @param {PropertyItemTypeApiFindPropertyItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyItemType(requestParameters: PropertyItemTypeApiFindPropertyItemTypeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultPropertyItemTypeDto> {
            return localVarFp.findPropertyItemType(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Item Type by ID
         * @param {PropertyItemTypeApiGetPropertyItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyItemType(requestParameters: PropertyItemTypeApiGetPropertyItemTypeRequest, options?: AxiosRequestConfig): AxiosPromise<PropertyItemTypeDto> {
            return localVarFp.getPropertyItemType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Move Label Sort Order Command
         * @param {PropertyItemTypeApiMovePropertyItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyItemType(requestParameters: PropertyItemTypeApiMovePropertyItemTypeRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.movePropertyItemType(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyItemTypeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Item Type Label Command
         * @param {PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyItemTypeLabel(requestParameters: PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyItemTypeLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyItemTypeLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPropertyItemType operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiCreatePropertyItemTypeRequest
 */
export interface PropertyItemTypeApiCreatePropertyItemTypeRequest {
    /**
     * 
     * @type {CreatePropertyItemTypeCommandDto}
     * @memberof PropertyItemTypeApiCreatePropertyItemType
     */
    readonly createPropertyItemTypeCommandDto: CreatePropertyItemTypeCommandDto
}

/**
 * Request parameters for deletePropertyItemType operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiDeletePropertyItemTypeRequest
 */
export interface PropertyItemTypeApiDeletePropertyItemTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiDeletePropertyItemType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiDeletePropertyItemType
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findPropertyItemType operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiFindPropertyItemTypeRequest
 */
export interface PropertyItemTypeApiFindPropertyItemTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiFindPropertyItemType
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyItemTypeApiFindPropertyItemType
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiFindPropertyItemType
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiFindPropertyItemType
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyItemTypeApiFindPropertyItemType
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyItemType operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiGetPropertyItemTypeRequest
 */
export interface PropertyItemTypeApiGetPropertyItemTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiGetPropertyItemType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiGetPropertyItemType
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for movePropertyItemType operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiMovePropertyItemTypeRequest
 */
export interface PropertyItemTypeApiMovePropertyItemTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiMovePropertyItemType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiMovePropertyItemType
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MovePropertyItemTypeCommandDto}
     * @memberof PropertyItemTypeApiMovePropertyItemType
     */
    readonly movePropertyItemTypeCommandDto: MovePropertyItemTypeCommandDto
}

/**
 * Request parameters for updatePropertyItemTypeLabel operation in PropertyItemTypeApi.
 * @export
 * @interface PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest
 */
export interface PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiUpdatePropertyItemTypeLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyItemTypeApiUpdatePropertyItemTypeLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyItemTypeLabelCommandDto}
     * @memberof PropertyItemTypeApiUpdatePropertyItemTypeLabel
     */
    readonly updatePropertyItemTypeLabelCommandDto: UpdatePropertyItemTypeLabelCommandDto
}

/**
 * PropertyItemTypeApi - object-oriented interface
 * @export
 * @class PropertyItemTypeApi
 * @extends {BaseAPI}
 */
export class PropertyItemTypeApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Property Item Type Command
     * @param {PropertyItemTypeApiCreatePropertyItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public createPropertyItemType(requestParameters: PropertyItemTypeApiCreatePropertyItemTypeRequest, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).createPropertyItemType(requestParameters.createPropertyItemTypeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Property Item Type Command
     * @param {PropertyItemTypeApiDeletePropertyItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public deletePropertyItemType(requestParameters: PropertyItemTypeApiDeletePropertyItemTypeRequest, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).deletePropertyItemType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Property Item Type
     * @param {PropertyItemTypeApiFindPropertyItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public findPropertyItemType(requestParameters: PropertyItemTypeApiFindPropertyItemTypeRequest = {}, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).findPropertyItemType(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Item Type by ID
     * @param {PropertyItemTypeApiGetPropertyItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public getPropertyItemType(requestParameters: PropertyItemTypeApiGetPropertyItemTypeRequest, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).getPropertyItemType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Move Label Sort Order Command
     * @param {PropertyItemTypeApiMovePropertyItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public movePropertyItemType(requestParameters: PropertyItemTypeApiMovePropertyItemTypeRequest, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).movePropertyItemType(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyItemTypeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Item Type Label Command
     * @param {PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyItemTypeApi
     */
    public updatePropertyItemTypeLabel(requestParameters: PropertyItemTypeApiUpdatePropertyItemTypeLabelRequest, options?: AxiosRequestConfig) {
        return PropertyItemTypeApiFp(this.configuration).updatePropertyItemTypeLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyItemTypeLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

