import { createFullStoryProvider, setupAnalytics } from '@operto/analytics';
import { useEffect } from 'react';
import { TeamsConst } from '../constants/Base';
import { useAppFeatures } from './app-features';
import { logger } from './logger';

// this hook encapsulate all logic required to setup FullStory
const useFullStory = () => {
  const { fullstory } = useAppFeatures();

  useEffect(() => {
    if (!fullstory) {
      removeProvider('fullstory');
      return;
    }

    try {
      const provider = createFullStoryProvider({ orgId: TeamsConst.fullStoryOrgId });
      addProvider(provider);
    } catch (error) {
      logger.error(error);
    }
  }, [fullstory]);
};

export const { trackEvent, addProvider, removeProvider } = setupAnalytics({
  appName: 'TEAMS_ADMIN',
});

export const useAnalytics = () => {
  useFullStory();
};
