/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreatePropertyUtilityTypeCommandDto } from '../models';
// @ts-ignore
import { MovePropertyUtilityTypeCommandDto } from '../models';
// @ts-ignore
import { PropertyUtilityTypeDto } from '../models';
// @ts-ignore
import { QueryResultPropertyUtilityTypeDto } from '../models';
// @ts-ignore
import { UpdatePropertyUtilityTypeCommandDto } from '../models';
// @ts-ignore
import { UpdatePropertyUtilityTypeLabelCommandDto } from '../models';
/**
 * PropertyUtilityTypeApi - axios parameter creator
 * @export
 */
export const PropertyUtilityTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Property Utility Type Command
         * @param {CreatePropertyUtilityTypeCommandDto} createPropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyUtilityType: async (createPropertyUtilityTypeCommandDto: CreatePropertyUtilityTypeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyUtilityTypeCommandDto' is not null or undefined
            assertParamExists('createPropertyUtilityType', 'createPropertyUtilityTypeCommandDto', createPropertyUtilityTypeCommandDto)
            const localVarPath = `/v1/propertyutilitytype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyUtilityTypeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Property Utility Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyUtilityType: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePropertyUtilityType', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deletePropertyUtilityType', 'ifMatch', ifMatch)
            const localVarPath = `/v1/propertyutilitytype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Property Utility Type
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyUtilityType: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/propertyutilitytype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Utility Type by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyUtilityType: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyUtilityType', 'id', id)
            const localVarPath = `/v1/propertyutilitytype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Move Property Utility Type Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyUtilityTypeCommandDto} movePropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyUtilityType: async (id: number, ifMatch: number, movePropertyUtilityTypeCommandDto: MovePropertyUtilityTypeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('movePropertyUtilityType', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('movePropertyUtilityType', 'ifMatch', ifMatch)
            // verify required parameter 'movePropertyUtilityTypeCommandDto' is not null or undefined
            assertParamExists('movePropertyUtilityType', 'movePropertyUtilityTypeCommandDto', movePropertyUtilityTypeCommandDto)
            const localVarPath = `/v1/propertyutilitytype/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(movePropertyUtilityTypeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyUtilityTypeCommandDto} updatePropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyUtilityType: async (id: number, ifMatch: number, updatePropertyUtilityTypeCommandDto: UpdatePropertyUtilityTypeCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyUtilityType', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyUtilityType', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyUtilityTypeCommandDto' is not null or undefined
            assertParamExists('updatePropertyUtilityType', 'updatePropertyUtilityTypeCommandDto', updatePropertyUtilityTypeCommandDto)
            const localVarPath = `/v1/propertyutilitytype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyUtilityTypeCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyUtilityTypeLabelCommandDto} updatePropertyUtilityTypeLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyUtilityTypeLabel: async (id: number, ifMatch: number, updatePropertyUtilityTypeLabelCommandDto: UpdatePropertyUtilityTypeLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyUtilityTypeLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyUtilityTypeLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyUtilityTypeLabelCommandDto' is not null or undefined
            assertParamExists('updatePropertyUtilityTypeLabel', 'updatePropertyUtilityTypeLabelCommandDto', updatePropertyUtilityTypeLabelCommandDto)
            const localVarPath = `/v1/propertyutilitytype/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyUtilityTypeLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyUtilityTypeApi - functional programming interface
 * @export
 */
export const PropertyUtilityTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyUtilityTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Utility Type Command
         * @param {CreatePropertyUtilityTypeCommandDto} createPropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyUtilityType(createPropertyUtilityTypeCommandDto: CreatePropertyUtilityTypeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyUtilityType(createPropertyUtilityTypeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.createPropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Property Utility Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyUtilityType(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyUtilityType(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.deletePropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Property Utility Type
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPropertyUtilityType(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultPropertyUtilityTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPropertyUtilityType(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.findPropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Property Utility Type by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyUtilityType(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyUtilityTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyUtilityType(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.getPropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Move Property Utility Type Sort Order Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyUtilityTypeCommandDto} movePropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movePropertyUtilityType(id: number, ifMatch: number, movePropertyUtilityTypeCommandDto: MovePropertyUtilityTypeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movePropertyUtilityType(id, ifMatch, movePropertyUtilityTypeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.movePropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyUtilityTypeCommandDto} updatePropertyUtilityTypeCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyUtilityType(id: number, ifMatch: number, updatePropertyUtilityTypeCommandDto: UpdatePropertyUtilityTypeCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyUtilityType(id, ifMatch, updatePropertyUtilityTypeCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.updatePropertyUtilityType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyUtilityTypeLabelCommandDto} updatePropertyUtilityTypeLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyUtilityTypeLabel(id: number, ifMatch: number, updatePropertyUtilityTypeLabelCommandDto: UpdatePropertyUtilityTypeLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyUtilityTypeLabel(id, ifMatch, updatePropertyUtilityTypeLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyUtilityTypeApi.updatePropertyUtilityTypeLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyUtilityTypeApi - factory interface
 * @export
 */
export const PropertyUtilityTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyUtilityTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Utility Type Command
         * @param {PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyUtilityType(requestParameters: PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createPropertyUtilityType(requestParameters.createPropertyUtilityTypeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Property Utility Type Command
         * @param {PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyUtilityType(requestParameters: PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Property Utility Type
         * @param {PropertyUtilityTypeApiFindPropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyUtilityType(requestParameters: PropertyUtilityTypeApiFindPropertyUtilityTypeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultPropertyUtilityTypeDto> {
            return localVarFp.findPropertyUtilityType(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Utility Type by ID
         * @param {PropertyUtilityTypeApiGetPropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyUtilityType(requestParameters: PropertyUtilityTypeApiGetPropertyUtilityTypeRequest, options?: AxiosRequestConfig): AxiosPromise<PropertyUtilityTypeDto> {
            return localVarFp.getPropertyUtilityType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Move Property Utility Type Sort Order Command
         * @param {PropertyUtilityTypeApiMovePropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyUtilityType(requestParameters: PropertyUtilityTypeApiMovePropertyUtilityTypeRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.movePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyUtilityTypeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Command
         * @param {PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyUtilityType(requestParameters: PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyUtilityTypeCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Utility Type Label Command
         * @param {PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyUtilityTypeLabel(requestParameters: PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyUtilityTypeLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyUtilityTypeLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest {
    /**
     * 
     * @type {CreatePropertyUtilityTypeCommandDto}
     * @memberof PropertyUtilityTypeApiCreatePropertyUtilityType
     */
    readonly createPropertyUtilityTypeCommandDto: CreatePropertyUtilityTypeCommandDto
}

/**
 * Request parameters for deletePropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiDeletePropertyUtilityType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiDeletePropertyUtilityType
     */
    readonly ifMatch: number
}

/**
 * Request parameters for findPropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiFindPropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiFindPropertyUtilityTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiFindPropertyUtilityType
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyUtilityTypeApiFindPropertyUtilityType
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiFindPropertyUtilityType
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiFindPropertyUtilityType
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyUtilityTypeApiFindPropertyUtilityType
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiGetPropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiGetPropertyUtilityTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiGetPropertyUtilityType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiGetPropertyUtilityType
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for movePropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiMovePropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiMovePropertyUtilityTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiMovePropertyUtilityType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiMovePropertyUtilityType
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MovePropertyUtilityTypeCommandDto}
     * @memberof PropertyUtilityTypeApiMovePropertyUtilityType
     */
    readonly movePropertyUtilityTypeCommandDto: MovePropertyUtilityTypeCommandDto
}

/**
 * Request parameters for updatePropertyUtilityType operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest
 */
export interface PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityType
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityType
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyUtilityTypeCommandDto}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityType
     */
    readonly updatePropertyUtilityTypeCommandDto: UpdatePropertyUtilityTypeCommandDto
}

/**
 * Request parameters for updatePropertyUtilityTypeLabel operation in PropertyUtilityTypeApi.
 * @export
 * @interface PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest
 */
export interface PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyUtilityTypeLabelCommandDto}
     * @memberof PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabel
     */
    readonly updatePropertyUtilityTypeLabelCommandDto: UpdatePropertyUtilityTypeLabelCommandDto
}

/**
 * PropertyUtilityTypeApi - object-oriented interface
 * @export
 * @class PropertyUtilityTypeApi
 * @extends {BaseAPI}
 */
export class PropertyUtilityTypeApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Property Utility Type Command
     * @param {PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public createPropertyUtilityType(requestParameters: PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).createPropertyUtilityType(requestParameters.createPropertyUtilityTypeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Property Utility Type Command
     * @param {PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public deletePropertyUtilityType(requestParameters: PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).deletePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Property Utility Type
     * @param {PropertyUtilityTypeApiFindPropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public findPropertyUtilityType(requestParameters: PropertyUtilityTypeApiFindPropertyUtilityTypeRequest = {}, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).findPropertyUtilityType(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Utility Type by ID
     * @param {PropertyUtilityTypeApiGetPropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public getPropertyUtilityType(requestParameters: PropertyUtilityTypeApiGetPropertyUtilityTypeRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).getPropertyUtilityType(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Move Property Utility Type Sort Order Command
     * @param {PropertyUtilityTypeApiMovePropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public movePropertyUtilityType(requestParameters: PropertyUtilityTypeApiMovePropertyUtilityTypeRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).movePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyUtilityTypeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Utility Type Command
     * @param {PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public updatePropertyUtilityType(requestParameters: PropertyUtilityTypeApiUpdatePropertyUtilityTypeRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).updatePropertyUtilityType(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyUtilityTypeCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Utility Type Label Command
     * @param {PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyUtilityTypeApi
     */
    public updatePropertyUtilityTypeLabel(requestParameters: PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest, options?: AxiosRequestConfig) {
        return PropertyUtilityTypeApiFp(this.configuration).updatePropertyUtilityTypeLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyUtilityTypeLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

