import { createSlice } from '@reduxjs/toolkit';

type SliceState = {
  save: string;
};

const initialState: SliceState = {
  save: '',
};

export const saveSlice = createSlice({
  name: 'save',
  initialState,
  reducers: {
    saveFilter: (state, action) => {
      state.save = action.payload;
    },
  },
});

export const { saveFilter } = saveSlice.actions;

export const selectSave = (state: { save: SliceState }) => state.save.save;

export default saveSlice.reducer;
