// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants/Constants';
import { PropertyService } from '../../services/PropertyService';
import { PropertyApiFindPropertiesRequest } from '../../teams-openapi';
import { translateError } from '../../utils/ErrorManagement';

export interface PropertyFilteredState {
  all: any[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
  updateStatus: API_STATUS;
  createStatus: API_STATUS;
  deleteStatus: API_STATUS;
}

const initialState = {
  all: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
  updateStatus: API_STATUS.IDLE,
  createStatus: API_STATUS.IDLE,
  deleteStatus: API_STATUS.IDLE,
};

export const getPropertiesFiltered = createAsyncThunk(
  'propertyFiltered/find',
  async (filters: PropertyApiFindPropertiesRequest = {}, { rejectWithValue }) => {
    try {
      const res = await PropertyService.Instance.getAll(filters);
      return res.data;
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<PropertyFilteredState>) => {
  // --------------- GET Properties ---------------
  builder.addCase(getPropertiesFiltered.pending, (state: PropertyFilteredState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(getPropertiesFiltered.fulfilled, (state: PropertyFilteredState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    const data = action.payload.data.sort((a, b) => a.name.localeCompare(b.name));
    state.all = data;
  });
  builder.addCase(getPropertiesFiltered.rejected, (state: PropertyFilteredState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.all = [];
  });
};

const propertyFilteredSlice = createSlice({
  name: 'propertyFiltered',
  initialState,
  reducers: {},
  extraReducers: addThunkCases,
});

const { reducer } = propertyFilteredSlice;
export const { resetCreateStatus, setPropertyToEdit } = propertyFilteredSlice.actions;
export default reducer;
