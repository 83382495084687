/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccountDto } from '../models';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { UpdateAccountCommandDto } from '../models';
// @ts-ignore
import { UpdateGuiPreferenceCommandDto } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Account by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccount', 'id', id)
            const localVarPath = `/v1/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Account Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateAccountCommandDto} updateAccountCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (id: number, ifMatch: number, updateAccountCommandDto: UpdateAccountCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccount', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateAccount', 'ifMatch', ifMatch)
            // verify required parameter 'updateAccountCommandDto' is not null or undefined
            assertParamExists('updateAccount', 'updateAccountCommandDto', updateAccountCommandDto)
            const localVarPath = `/v1/account/{id}/update-account`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update GUI preference command
         * @param {UpdateGuiPreferenceCommandDto} updateGuiPreferenceCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuiPreference: async (updateGuiPreferenceCommandDto: UpdateGuiPreferenceCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGuiPreferenceCommandDto' is not null or undefined
            assertParamExists('updateGuiPreference', 'updateGuiPreferenceCommandDto', updateGuiPreferenceCommandDto)
            const localVarPath = `/v1/account/updateGuiPreference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGuiPreferenceCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Account by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.getAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Account Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdateAccountCommandDto} updateAccountCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(id: number, ifMatch: number, updateAccountCommandDto: UpdateAccountCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(id, ifMatch, updateAccountCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.updateAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update GUI preference command
         * @param {UpdateGuiPreferenceCommandDto} updateGuiPreferenceCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGuiPreference(updateGuiPreferenceCommandDto: UpdateGuiPreferenceCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuiPreferenceCommandDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuiPreference(updateGuiPreferenceCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.updateGuiPreference']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Account by ID
         * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(requestParameters: AccountApiGetAccountRequest, options?: AxiosRequestConfig): AxiosPromise<AccountDto> {
            return localVarFp.getAccount(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Account Command
         * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updateAccount(requestParameters.id, requestParameters.ifMatch, requestParameters.updateAccountCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update GUI preference command
         * @param {AccountApiUpdateGuiPreferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGuiPreference(requestParameters: AccountApiUpdateGuiPreferenceRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateGuiPreferenceCommandDto> {
            return localVarFp.updateGuiPreference(requestParameters.updateGuiPreferenceCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAccount operation in AccountApi.
 * @export
 * @interface AccountApiGetAccountRequest
 */
export interface AccountApiGetAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiGetAccount
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiGetAccount
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for updateAccount operation in AccountApi.
 * @export
 * @interface AccountApiUpdateAccountRequest
 */
export interface AccountApiUpdateAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiUpdateAccount
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiUpdateAccount
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdateAccountCommandDto}
     * @memberof AccountApiUpdateAccount
     */
    readonly updateAccountCommandDto: UpdateAccountCommandDto
}

/**
 * Request parameters for updateGuiPreference operation in AccountApi.
 * @export
 * @interface AccountApiUpdateGuiPreferenceRequest
 */
export interface AccountApiUpdateGuiPreferenceRequest {
    /**
     * 
     * @type {UpdateGuiPreferenceCommandDto}
     * @memberof AccountApiUpdateGuiPreference
     */
    readonly updateGuiPreferenceCommandDto: UpdateGuiPreferenceCommandDto
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get Account by ID
     * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(requestParameters: AccountApiGetAccountRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Account Command
     * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAccount(requestParameters.id, requestParameters.ifMatch, requestParameters.updateAccountCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update GUI preference command
     * @param {AccountApiUpdateGuiPreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateGuiPreference(requestParameters: AccountApiUpdateGuiPreferenceRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateGuiPreference(requestParameters.updateGuiPreferenceCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

