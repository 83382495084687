/* tslint:disable */
/* eslint-disable */
/**
 * Operto Teams API
 * API for accessing Operto Teams Data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommandResultDto } from '../models';
// @ts-ignore
import { CreatePropertyStatusCommandDto } from '../models';
// @ts-ignore
import { EnableSetOnCheckInCommandDto } from '../models';
// @ts-ignore
import { EnableSetOnCheckOutCommandDto } from '../models';
// @ts-ignore
import { MovePropertyStatusCommandDto } from '../models';
// @ts-ignore
import { PropertyStatusDto } from '../models';
// @ts-ignore
import { QueryResultPropertyStatusDto } from '../models';
// @ts-ignore
import { UpdatePropertyStatusCommandDto } from '../models';
// @ts-ignore
import { UpdatePropertyStatusLabelCommandDto } from '../models';
/**
 * PropertyStatusApi - axios parameter creator
 * @export
 */
export const PropertyStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a Create Property Status Command
         * @param {CreatePropertyStatusCommandDto} createPropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyStatus: async (createPropertyStatusCommandDto: CreatePropertyStatusCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyStatusCommandDto' is not null or undefined
            assertParamExists('createPropertyStatus', 'createPropertyStatusCommandDto', createPropertyStatusCommandDto)
            const localVarPath = `/v1/propertystatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyStatusCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Delete Property Status Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyStatus: async (id: number, ifMatch: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePropertyStatus', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('deletePropertyStatus', 'ifMatch', ifMatch)
            const localVarPath = `/v1/propertystatus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Enable Set On Check In Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {EnableSetOnCheckInCommandDto} enableSetOnCheckInCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSetOnCheckIn: async (id: number, ifMatch: number, enableSetOnCheckInCommandDto: EnableSetOnCheckInCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableSetOnCheckIn', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('enableSetOnCheckIn', 'ifMatch', ifMatch)
            // verify required parameter 'enableSetOnCheckInCommandDto' is not null or undefined
            assertParamExists('enableSetOnCheckIn', 'enableSetOnCheckInCommandDto', enableSetOnCheckInCommandDto)
            const localVarPath = `/v1/propertystatus/{id}/set-on-check-in`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableSetOnCheckInCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Enable Set On Check Out Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {EnableSetOnCheckOutCommandDto} enableSetOnCheckOutCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSetOnCheckOut: async (id: number, ifMatch: number, enableSetOnCheckOutCommandDto: EnableSetOnCheckOutCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableSetOnCheckOut', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('enableSetOnCheckOut', 'ifMatch', ifMatch)
            // verify required parameter 'enableSetOnCheckOutCommandDto' is not null or undefined
            assertParamExists('enableSetOnCheckOut', 'enableSetOnCheckOutCommandDto', enableSetOnCheckOutCommandDto)
            const localVarPath = `/v1/propertystatus/{id}/set-on-check-out`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableSetOnCheckOutCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Property Statuses
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyStatuses: async (customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/propertystatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (labelStartsWith !== undefined) {
                localVarQueryParameter['labelStartsWith'] = labelStartsWith;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Status by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyStatus: async (id: number, ifMatch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyStatus', 'id', id)
            const localVarPath = `/v1/propertystatus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Move Property Status Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyStatusCommandDto} movePropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyStatus: async (id: number, ifMatch: number, movePropertyStatusCommandDto: MovePropertyStatusCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('movePropertyStatus', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('movePropertyStatus', 'ifMatch', ifMatch)
            // verify required parameter 'movePropertyStatusCommandDto' is not null or undefined
            assertParamExists('movePropertyStatus', 'movePropertyStatusCommandDto', movePropertyStatusCommandDto)
            const localVarPath = `/v1/propertystatus/{id}/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(movePropertyStatusCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Status Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyStatusCommandDto} updatePropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyStatus: async (id: number, ifMatch: number, updatePropertyStatusCommandDto: UpdatePropertyStatusCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyStatus', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyStatus', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyStatusCommandDto' is not null or undefined
            assertParamExists('updatePropertyStatus', 'updatePropertyStatusCommandDto', updatePropertyStatusCommandDto)
            const localVarPath = `/v1/propertystatus/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyStatusCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an Update Property Status Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyStatusLabelCommandDto} updatePropertyStatusLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyStatusLabel: async (id: number, ifMatch: number, updatePropertyStatusLabelCommandDto: UpdatePropertyStatusLabelCommandDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePropertyStatusLabel', 'id', id)
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updatePropertyStatusLabel', 'ifMatch', ifMatch)
            // verify required parameter 'updatePropertyStatusLabelCommandDto' is not null or undefined
            assertParamExists('updatePropertyStatusLabel', 'updatePropertyStatusLabelCommandDto', updatePropertyStatusLabelCommandDto)
            const localVarPath = `/v1/propertystatus/{id}/update-label`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['If-Match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyStatusLabelCommandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyStatusApi - functional programming interface
 * @export
 */
export const PropertyStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Status Command
         * @param {CreatePropertyStatusCommandDto} createPropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyStatus(createPropertyStatusCommandDto: CreatePropertyStatusCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyStatus(createPropertyStatusCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.createPropertyStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Delete Property Status Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyStatus(id: number, ifMatch: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyStatus(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.deletePropertyStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Enable Set On Check In Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {EnableSetOnCheckInCommandDto} enableSetOnCheckInCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableSetOnCheckIn(id: number, ifMatch: number, enableSetOnCheckInCommandDto: EnableSetOnCheckInCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableSetOnCheckIn(id, ifMatch, enableSetOnCheckInCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.enableSetOnCheckIn']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Enable Set On Check Out Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {EnableSetOnCheckOutCommandDto} enableSetOnCheckOutCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableSetOnCheckOut(id: number, ifMatch: number, enableSetOnCheckOutCommandDto: EnableSetOnCheckOutCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableSetOnCheckOut(id, ifMatch, enableSetOnCheckOutCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.enableSetOnCheckOut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Find Property Statuses
         * @param {number} [customerId] 
         * @param {string} [labelStartsWith] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPropertyStatuses(customerId?: number, labelStartsWith?: string, skip?: number, take?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResultPropertyStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPropertyStatuses(customerId, labelStartsWith, skip, take, sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.findPropertyStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Property Status by ID
         * @param {number} id 
         * @param {number} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyStatus(id: number, ifMatch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyStatus(id, ifMatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.getPropertyStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Move Property Status Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {MovePropertyStatusCommandDto} movePropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async movePropertyStatus(id: number, ifMatch: number, movePropertyStatusCommandDto: MovePropertyStatusCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.movePropertyStatus(id, ifMatch, movePropertyStatusCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.movePropertyStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Status Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyStatusCommandDto} updatePropertyStatusCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyStatus(id: number, ifMatch: number, updatePropertyStatusCommandDto: UpdatePropertyStatusCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyStatus(id, ifMatch, updatePropertyStatusCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.updatePropertyStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send an Update Property Status Label Command
         * @param {number} id 
         * @param {number} ifMatch 
         * @param {UpdatePropertyStatusLabelCommandDto} updatePropertyStatusLabelCommandDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyStatusLabel(id: number, ifMatch: number, updatePropertyStatusLabelCommandDto: UpdatePropertyStatusLabelCommandDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyStatusLabel(id, ifMatch, updatePropertyStatusLabelCommandDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyStatusApi.updatePropertyStatusLabel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyStatusApi - factory interface
 * @export
 */
export const PropertyStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a Create Property Status Command
         * @param {PropertyStatusApiCreatePropertyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyStatus(requestParameters: PropertyStatusApiCreatePropertyStatusRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.createPropertyStatus(requestParameters.createPropertyStatusCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Delete Property Status Command
         * @param {PropertyStatusApiDeletePropertyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyStatus(requestParameters: PropertyStatusApiDeletePropertyStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePropertyStatus(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Enable Set On Check In Command
         * @param {PropertyStatusApiEnableSetOnCheckInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSetOnCheckIn(requestParameters: PropertyStatusApiEnableSetOnCheckInRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.enableSetOnCheckIn(requestParameters.id, requestParameters.ifMatch, requestParameters.enableSetOnCheckInCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Enable Set On Check Out Command
         * @param {PropertyStatusApiEnableSetOnCheckOutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSetOnCheckOut(requestParameters: PropertyStatusApiEnableSetOnCheckOutRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.enableSetOnCheckOut(requestParameters.id, requestParameters.ifMatch, requestParameters.enableSetOnCheckOutCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Property Statuses
         * @param {PropertyStatusApiFindPropertyStatusesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPropertyStatuses(requestParameters: PropertyStatusApiFindPropertyStatusesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryResultPropertyStatusDto> {
            return localVarFp.findPropertyStatuses(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Status by ID
         * @param {PropertyStatusApiGetPropertyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyStatus(requestParameters: PropertyStatusApiGetPropertyStatusRequest, options?: AxiosRequestConfig): AxiosPromise<PropertyStatusDto> {
            return localVarFp.getPropertyStatus(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Move Property Status Label Command
         * @param {PropertyStatusApiMovePropertyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        movePropertyStatus(requestParameters: PropertyStatusApiMovePropertyStatusRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.movePropertyStatus(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyStatusCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Status Command
         * @param {PropertyStatusApiUpdatePropertyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyStatus(requestParameters: PropertyStatusApiUpdatePropertyStatusRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyStatus(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyStatusCommandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an Update Property Status Label Command
         * @param {PropertyStatusApiUpdatePropertyStatusLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyStatusLabel(requestParameters: PropertyStatusApiUpdatePropertyStatusLabelRequest, options?: AxiosRequestConfig): AxiosPromise<CommandResultDto> {
            return localVarFp.updatePropertyStatusLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyStatusLabelCommandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPropertyStatus operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiCreatePropertyStatusRequest
 */
export interface PropertyStatusApiCreatePropertyStatusRequest {
    /**
     * 
     * @type {CreatePropertyStatusCommandDto}
     * @memberof PropertyStatusApiCreatePropertyStatus
     */
    readonly createPropertyStatusCommandDto: CreatePropertyStatusCommandDto
}

/**
 * Request parameters for deletePropertyStatus operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiDeletePropertyStatusRequest
 */
export interface PropertyStatusApiDeletePropertyStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiDeletePropertyStatus
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiDeletePropertyStatus
     */
    readonly ifMatch: number
}

/**
 * Request parameters for enableSetOnCheckIn operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiEnableSetOnCheckInRequest
 */
export interface PropertyStatusApiEnableSetOnCheckInRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiEnableSetOnCheckIn
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiEnableSetOnCheckIn
     */
    readonly ifMatch: number

    /**
     * 
     * @type {EnableSetOnCheckInCommandDto}
     * @memberof PropertyStatusApiEnableSetOnCheckIn
     */
    readonly enableSetOnCheckInCommandDto: EnableSetOnCheckInCommandDto
}

/**
 * Request parameters for enableSetOnCheckOut operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiEnableSetOnCheckOutRequest
 */
export interface PropertyStatusApiEnableSetOnCheckOutRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiEnableSetOnCheckOut
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiEnableSetOnCheckOut
     */
    readonly ifMatch: number

    /**
     * 
     * @type {EnableSetOnCheckOutCommandDto}
     * @memberof PropertyStatusApiEnableSetOnCheckOut
     */
    readonly enableSetOnCheckOutCommandDto: EnableSetOnCheckOutCommandDto
}

/**
 * Request parameters for findPropertyStatuses operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiFindPropertyStatusesRequest
 */
export interface PropertyStatusApiFindPropertyStatusesRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiFindPropertyStatuses
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyStatusApiFindPropertyStatuses
     */
    readonly labelStartsWith?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiFindPropertyStatuses
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiFindPropertyStatuses
     */
    readonly take?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyStatusApiFindPropertyStatuses
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getPropertyStatus operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiGetPropertyStatusRequest
 */
export interface PropertyStatusApiGetPropertyStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiGetPropertyStatus
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiGetPropertyStatus
     */
    readonly ifMatch?: number
}

/**
 * Request parameters for movePropertyStatus operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiMovePropertyStatusRequest
 */
export interface PropertyStatusApiMovePropertyStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiMovePropertyStatus
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiMovePropertyStatus
     */
    readonly ifMatch: number

    /**
     * 
     * @type {MovePropertyStatusCommandDto}
     * @memberof PropertyStatusApiMovePropertyStatus
     */
    readonly movePropertyStatusCommandDto: MovePropertyStatusCommandDto
}

/**
 * Request parameters for updatePropertyStatus operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiUpdatePropertyStatusRequest
 */
export interface PropertyStatusApiUpdatePropertyStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiUpdatePropertyStatus
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiUpdatePropertyStatus
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyStatusCommandDto}
     * @memberof PropertyStatusApiUpdatePropertyStatus
     */
    readonly updatePropertyStatusCommandDto: UpdatePropertyStatusCommandDto
}

/**
 * Request parameters for updatePropertyStatusLabel operation in PropertyStatusApi.
 * @export
 * @interface PropertyStatusApiUpdatePropertyStatusLabelRequest
 */
export interface PropertyStatusApiUpdatePropertyStatusLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiUpdatePropertyStatusLabel
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PropertyStatusApiUpdatePropertyStatusLabel
     */
    readonly ifMatch: number

    /**
     * 
     * @type {UpdatePropertyStatusLabelCommandDto}
     * @memberof PropertyStatusApiUpdatePropertyStatusLabel
     */
    readonly updatePropertyStatusLabelCommandDto: UpdatePropertyStatusLabelCommandDto
}

/**
 * PropertyStatusApi - object-oriented interface
 * @export
 * @class PropertyStatusApi
 * @extends {BaseAPI}
 */
export class PropertyStatusApi extends BaseAPI {
    /**
     * 
     * @summary Send a Create Property Status Command
     * @param {PropertyStatusApiCreatePropertyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public createPropertyStatus(requestParameters: PropertyStatusApiCreatePropertyStatusRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).createPropertyStatus(requestParameters.createPropertyStatusCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Delete Property Status Command
     * @param {PropertyStatusApiDeletePropertyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public deletePropertyStatus(requestParameters: PropertyStatusApiDeletePropertyStatusRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).deletePropertyStatus(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Enable Set On Check In Command
     * @param {PropertyStatusApiEnableSetOnCheckInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public enableSetOnCheckIn(requestParameters: PropertyStatusApiEnableSetOnCheckInRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).enableSetOnCheckIn(requestParameters.id, requestParameters.ifMatch, requestParameters.enableSetOnCheckInCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Enable Set On Check Out Command
     * @param {PropertyStatusApiEnableSetOnCheckOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public enableSetOnCheckOut(requestParameters: PropertyStatusApiEnableSetOnCheckOutRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).enableSetOnCheckOut(requestParameters.id, requestParameters.ifMatch, requestParameters.enableSetOnCheckOutCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Property Statuses
     * @param {PropertyStatusApiFindPropertyStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public findPropertyStatuses(requestParameters: PropertyStatusApiFindPropertyStatusesRequest = {}, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).findPropertyStatuses(requestParameters.customerId, requestParameters.labelStartsWith, requestParameters.skip, requestParameters.take, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Status by ID
     * @param {PropertyStatusApiGetPropertyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public getPropertyStatus(requestParameters: PropertyStatusApiGetPropertyStatusRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).getPropertyStatus(requestParameters.id, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Move Property Status Label Command
     * @param {PropertyStatusApiMovePropertyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public movePropertyStatus(requestParameters: PropertyStatusApiMovePropertyStatusRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).movePropertyStatus(requestParameters.id, requestParameters.ifMatch, requestParameters.movePropertyStatusCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Status Command
     * @param {PropertyStatusApiUpdatePropertyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public updatePropertyStatus(requestParameters: PropertyStatusApiUpdatePropertyStatusRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).updatePropertyStatus(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyStatusCommandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an Update Property Status Label Command
     * @param {PropertyStatusApiUpdatePropertyStatusLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyStatusApi
     */
    public updatePropertyStatusLabel(requestParameters: PropertyStatusApiUpdatePropertyStatusLabelRequest, options?: AxiosRequestConfig) {
        return PropertyStatusApiFp(this.configuration).updatePropertyStatusLabel(requestParameters.id, requestParameters.ifMatch, requestParameters.updatePropertyStatusLabelCommandDto, options).then((request) => request(this.axios, this.basePath));
    }
}

