/* eslint-disable no-console */
// @ts-nocheck - TODO: fix typescript errors
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DepartureReportService } from 'services/reports/DepartureReportService';
import { API_STATUS } from '../../constants/Constants';
import { translateError } from '../../utils/ErrorManagement';
import { DepartureDto } from '@/teams-openapi';

export interface DepartureReportState {
  result: DepartureDto[];
  currentItem: any;
  updateError: undefined;
  fetchAllStatus: API_STATUS;
}

const initialState: DepartureReportState = {
  result: [],
  currentItem: null,
  updateError: undefined,
  fetchAllStatus: API_STATUS.IDLE,
};

export const runReport = createAsyncThunk(
  'reports/departure',
  async (filters: DepartureFilters, { rejectWithValue }) => {
    try {
      const departureData = await DepartureReportService.Instance.runDepartureReport(filters);
      return { resultData: departureData.data };
    } catch (err) {
      return rejectWithValue(translateError(err));
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<DepartureReportState>) => {
  // --------------- GET REGIONS ---------------
  builder.addCase(runReport.pending, (state: DepartureReportState, action) => {
    state.fetchAllStatus = API_STATUS.PENDING;
  });
  builder.addCase(runReport.fulfilled, (state: DepartureReportState, action) => {
    state.fetchAllStatus = API_STATUS.SUCCESS;
    state.result = action.payload.resultData.data;
  });
  builder.addCase(runReport.rejected, (state: DepartureReportState, action) => {
    state.fetchAllStatus = API_STATUS.FAILURE;
    state.result = [];
  });
};

const departureReportSlice = createSlice({
  name: 'departureReportSlice',
  initialState,
  reducers: {
    setDone: () => {
      // TODO: implement me
    },
  },
  extraReducers: addThunkCases,
});

const { reducer } = departureReportSlice;
export const { setDone } = departureReportSlice.actions;
export default reducer;
