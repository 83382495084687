import { DateRange } from '@mui/lab/DateRangePicker';
import { createSlice } from '@reduxjs/toolkit';

export enum FILTER_PAGE {
  EMPTY = 'EMPTY',
  ARRIVAL = 'ARRIVAL',
  REGIONS = 'REGIONS',
}

export interface FilterState {
  currentFilterPage: FILTER_PAGE;
  arrivalFilters: ArrivalFilters;
}
export interface ChipRef {
  id: number;
  label: string;
  gridKey: any;
}

export interface ArrivalFilters {
  dateRange: DateRange<string> | undefined;
  regionGroupRefs: Array<ChipRef>;
  regionRefs: Array<ChipRef>;
  propertyRefs: Array<ChipRef>;
  taskDuringStay: boolean;
  keyword: string;
}

const initialState: FilterState = {
  currentFilterPage: FILTER_PAGE.EMPTY,
  arrivalFilters: {
    dateRange: [new Date().toISOString(), new Date().toISOString()],
    regionGroupRefs: [],
    regionRefs: [],
    propertyRefs: [],
    taskDuringStay: false,
    keyword: '',
  },
};

// export const addThunkCases = () => {
//   // TODO: Add thunk cases here
// };

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setCurrentFilterPage: (state, action) => {
      state.currentFilterPage = action.payload;
    },
    setDateRange: (state, action) => {
      state.arrivalFilters.dateRange = action.payload
        ? action.payload.map((x: Date) => {
            return x.toISOString();
          })
        : undefined;
    },
    setRegionGroupRefs: (state, action) => {
      state.arrivalFilters.regionGroupRefs = action.payload;
    },
    setRegionRefs: (state, action) => {
      state.arrivalFilters.regionRefs = action.payload;
    },
    setPropertyRefs: (state, action) => {
      state.arrivalFilters.propertyRefs = action.payload;
    },
    setTaskDuringStay: (state, action) => {
      state.arrivalFilters.taskDuringStay = action.payload;
    },
    setKeyword: (state, action) => {
      state.arrivalFilters.keyword = action.payload;
    },
  },
  // extraReducers: addThunkCases,
});

const { reducer } = filterSlice;
export const {
  setCurrentFilterPage,
  setDateRange,
  setRegionGroupRefs,
  setRegionRefs,
  setPropertyRefs,
  setTaskDuringStay,
  setKeyword,
} = filterSlice.actions;
export default reducer;
