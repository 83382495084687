// @ts-nocheck - TODO: fix typescript errors
import { TeamsConst } from '../constants/Base';
import {
  PropertyUtilityTypeApi,
  PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest,
  PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest,
  PropertyUtilityTypeApiFindPropertyUtilityTypeRequest,
  PropertyUtilityTypeApiGetPropertyUtilityTypeRequest,
  PropertyUtilityTypeApiMovePropertyUtilityTypeRequest,
  PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest,
} from './../teams-openapi/apis/property-utility-type-api';
import { BaseService } from './BaseService';

export class PropertyUtilityTypeService {
  private _baseInstance: BaseService;
  private static _instance: PropertyUtilityTypeService;
  private api: PropertyUtilityTypeApi;

  private constructor() {
    this._baseInstance = BaseService.Instance;
    this.api = new PropertyUtilityTypeApi(
      this._baseInstance.Configuration,
      TeamsConst.apiBaseUrl,
      this._baseInstance.AxiosInstance,
    );
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getAll() {
    const req: PropertyUtilityTypeApiFindPropertyUtilityTypeRequest = {
      customerId: this._baseInstance.CustomerId,
      labelStartsWith: undefined,
      skip: undefined,
      take: undefined,
      sort: undefined,
    };
    return this.api.findPropertyUtilityType(req);
  }

  public getAllRefs() {
    const req: PropertyUtilityTypeApiFindPropertyUtilityTypeRequest = {
      customerId: this._baseInstance.CustomerId,
    };
    return this.api.findPropertyUtilityType(req);
  }

  public getById(id: number, version: number) {
    const req: PropertyUtilityTypeApiGetPropertyUtilityTypeRequest = {
      id: id,
    };
    return this.api.getPropertyUtilityType(req);
  }

  public create(label: string) {
    const req: PropertyUtilityTypeApiCreatePropertyUtilityTypeRequest = {
      createPropertyUtilityTypeCommandDto: {
        customer: this._baseInstance.CustomerId,
        label: label,
      },
    };
    return this.api.createPropertyUtilityType(req);
  }

  public delete(id, version) {
    const req: PropertyUtilityTypeApiDeletePropertyUtilityTypeRequest = {
      id: id,
      ifMatch: version,
    };
    return this.api.deletePropertyUtilityType(req);
  }

  public updateLabel(id, label, version) {
    const req: PropertyUtilityTypeApiUpdatePropertyUtilityTypeLabelRequest = {
      id: id,
      ifMatch: version,
      updatePropertyUtilityTypeLabelCommandDto: { label: label },
    };
    return this.api.updatePropertyUtilityTypeLabel(req);
  }

  public updateSortOrder(id, sortIndex, version) {
    const req: PropertyUtilityTypeApiMovePropertyUtilityTypeRequest = {
      id: id,
      ifMatch: version,
      movePropertyUtilityTypeCommandDto: { moveToSortOrder: sortIndex },
    };
    return this.api.movePropertyUtilityType(req);
  }
}
