import { createSlice } from '@reduxjs/toolkit';

type SliceState = {
  search?: string;
};

const initialState: SliceState = {
  search: undefined,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchText: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { searchText } = searchSlice.actions;

export const textSearch = (state: { search: SliceState }) => state.search.search;

export default searchSlice.reducer;
